import React from "react";
import Button from "@amzn/meridian/button";

interface AustinButtonProps {
  caseSource: String;
}

const austinButton = ({ caseSource }: AustinButtonProps) => {
  return (
    <Button
      disabled={true}
      type={"secondary"}
      data-cy={`new-jmr__background__caseSource-${caseSource}-btn`}
    >
      {caseSource}
    </Button>
  );
};

export default austinButton;
