/**
 * convert the string that the Meridian DatePicker component returns into
 * a javascript Date object
 * @param dateStr the date string in format YYYY-MM-DD
 */
export const datePickerStringToDateObj = (dateStr: string) => {
  const spiltString = dateStr?.split("-");
  // the months start from '0', so we have to subtract 1
  if (spiltString) {
    return new Date(
      +spiltString[0],
      +spiltString[1] - 1,
      +spiltString[2].split("T")[0]
    );
  }
};
