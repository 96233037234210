import { TRANSFER_PENDING, ALTERNATIVE_PLACEMENT_RECOMMENDED, NEW_RESTRICTIONS_RECEIVED } from "./AccommodationStatusNames";

export const ACCOMMODATION_STATUS = {
  NEW_RESTRICTIONS_RECEIVED: {
    id: 16,
    status: NEW_RESTRICTIONS_RECEIVED,
  },
  ALTERNATIVE_PLACEMENT_RECOMMENDED: {
    id: 17,
    status: ALTERNATIVE_PLACEMENT_RECOMMENDED,
  },
  TRANSFER_PENDING: {
    id: 20,
    status: TRANSFER_PENDING
  }
};
