import React from "react";
import Row from "@amzn/meridian/row";
import Tag from "@amzn/meridian/tag";
import { useDispatch } from "react-redux";
import { useAppSelector } from "src/store/store";
import { removeTagAndUpdateFilters } from "src/store/placementOverviewPageSlice";
import { FilterTag } from "src/services/placementOverview/types/filterTag";

const appliedFilterTags = () => {
  const dispatch = useDispatch();
  const dismissTag = (filterTag: FilterTag) => {
    dispatch(removeTagAndUpdateFilters(filterTag));
  };

  const { filterTags } = useAppSelector((state) => state.placementOverviewPage);

  return (
    <Row wrap='down'>
      {filterTags.map((filterTag: FilterTag) => (
        <Tag
          onClose={() => {
            dismissTag(filterTag);
          }}
          type='theme'
          key={`${filterTag.prefix}${filterTag.value}`}
        >
          {`${filterTag.prefix}${filterTag.value}`}
        </Tag>
      ))}
    </Row>
  );
};

export default appliedFilterTags;
