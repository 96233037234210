import Auth from '@aws-amplify/auth';

export async function Init(): Promise<void> {
  const config = {
    stage: "prod",
    aws_cognito_region: "us-east-1",
    aws_cognito_identity_pool_id: "us-east-1:9244f425-61c9-4b35-a387-178ce27a700d",
    aws_user_pools_id: "us-east-1_dIRvgsrfz",
    aws_user_pools_web_client_id: "61nrdvjd27416k3njufj1q7osq",
    Auth: {
      identityPoolId: "us-east-1:9244f425-61c9-4b35-a387-178ce27a700d",
      region: "us-east-1",
      userPoolId: "us-east-1_dIRvgsrfz",
      userPoolWebClientId: "61nrdvjd27416k3njufj1q7osq",
    },
    oauth: {
      domain: `rtw-tool-prod-na.auth.us-east-1.amazoncognito.com`,
      redirectSignIn: "https://prod.na.rtw.whs.amazon.dev",
      redirectSignOut: "https://prod.na.rtw.whs.amazon.dev",
      scope: ['openid', 'profile'],
      responseType: 'code',
    },
  };
  Auth.configure(config);
}