import React from "react";
import Text from "@amzn/meridian/text";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Box from "@amzn/meridian/box";
import DatePicker from "@amzn/meridian/date-picker";
import { useAppSelector } from "src/store/store";
import { BackgroundFormData, JmrFlowTypes } from "src/store/newJmrPageSlice";
import Checkbox from "@amzn/meridian/checkbox";
import Input from "@amzn/meridian/input";

interface restrictionInformationProps {
  handleChange: (key: keyof BackgroundFormData, value: any) => void;
}
const restrictionInformation = ({
  handleChange,
}: restrictionInformationProps) => {
  const { backgroundFormData, backgroundFormErrors, jmrFlowType } =
    useAppSelector((state) => state.newJmrPage);

  const { featureFlags } = useAppSelector((state) => state.user);

  const dateFieldsWithFeatureFlagOn = [
    {
      label: "Restriction Start Date:",
      fieldName: "restrictionStartDate",
      condition: true,
    },
    {
      label: "Restriction End Date:",
      fieldName: "restrictionEndDate",
      condition: !backgroundFormData.indefiniteRestrictions,
    },
  ];

  const dateFieldsWithFeatureFlagOff = [
    {
      label: "Restriction Start Date:",
      fieldName: "restrictionStartDate",
      condition: true,
    },
    {
      label: "Restriction End Date:",
      fieldName: "restrictionEndDate",
      condition: true,
    },
  ];

  enum CheckedField {
    PROVIDER_PLACED_OFF_WORK = "providerPlacedOffWork",
    INDEFINITE_RESTRICTIONS = "indefiniteRestrictions",
    CANNOT_WORK_NORMAL_SHIFT_HOURS = "cannotWorkNormalShiftHrs",
    CANNOT_WORK_MORE_THAN_40_HOURS = "cannotWorkMoreThan40Hrs",
  }

  interface CheckboxField {
    checkedField: CheckedField;
    checkboxLabel: string;
    fieldName: string;
    label?: string;
  }

  const checkboxFieldsWithFeatureFlagOn: CheckboxField[] = [
    {
      checkedField: CheckedField.PROVIDER_PLACED_OFF_WORK,
      fieldName: "providerPlacedOffWork",
      checkboxLabel: "Provider Placed Off Work",
    },
    {
      checkedField: CheckedField.INDEFINITE_RESTRICTIONS,
      fieldName: "indefiniteRestrictions",
      checkboxLabel: "Permanent/Indefinite restrictions",
    },
    {
      checkedField: CheckedField.CANNOT_WORK_NORMAL_SHIFT_HOURS,
      fieldName: "limitedHoursPerDay",
      label: "Limited Hours per Day",
      checkboxLabel:
        featureFlags.DP_INTEGRATION_P0 ? 
          "Associate cannot work normal shift hours" : 
          "Associate cannot work normal shift hours, or provider placed off work",
    },
    {
      checkedField: CheckedField.CANNOT_WORK_MORE_THAN_40_HOURS,
      fieldName: "limitedHoursPerWeek",
      label: "Limited Hours per Week",
      checkboxLabel: "Associate cannot work 40+ hours a week",
    },
  ];

  const getCheckboxFields = (fields: CheckedField[]): CheckboxField[] => {
    return checkboxFieldsWithFeatureFlagOn.filter((field) =>
      fields.includes(field.checkedField)
    );
  };

  const dateFields = featureFlags.DP_INTEGRATION_P0
    ? dateFieldsWithFeatureFlagOn
    : dateFieldsWithFeatureFlagOff;

  let checkboxFields: CheckboxField[];
  if (featureFlags.DP_INTEGRATION_P0) {
    const creationRestrictionAndTransferFlows = [
      JmrFlowTypes.CreateNewAccommodation,
      JmrFlowTypes.NewRestrictions,
      JmrFlowTypes.CompleteTransfer,
    ];

    if (creationRestrictionAndTransferFlows.includes(jmrFlowType)) {
      checkboxFields = checkboxFieldsWithFeatureFlagOn;
    } else {
      checkboxFields = getCheckboxFields([
        CheckedField.CANNOT_WORK_MORE_THAN_40_HOURS,
        CheckedField.CANNOT_WORK_NORMAL_SHIFT_HOURS,
        CheckedField.INDEFINITE_RESTRICTIONS,
      ]);
    }
  } else {
    checkboxFields = getCheckboxFields([
      CheckedField.CANNOT_WORK_MORE_THAN_40_HOURS,
      CheckedField.CANNOT_WORK_NORMAL_SHIFT_HOURS,
    ]);
  }

  const providerPlacedOffWorkCheckboxField =
    checkboxFieldsWithFeatureFlagOn.filter(
      (checkedField) => checkedField.checkedField === CheckedField.PROVIDER_PLACED_OFF_WORK
    )[0];

  const otherCheckboxFields = checkboxFieldsWithFeatureFlagOn.filter(
    (checkedField) => checkedField.checkedField !== CheckedField.PROVIDER_PLACED_OFF_WORK
  );

  const getDisabled = (checkedField: keyof BackgroundFormData): boolean => {
    if (!featureFlags.DP_INTEGRATION_P0) return false;

    if (checkedField === CheckedField.PROVIDER_PLACED_OFF_WORK) {
      // disable providerPlacedOffWork checkbox if some other checkbox is checked
      return otherCheckboxFields.some(
        ({ checkedField }) =>
          backgroundFormData[
            checkedField as keyof BackgroundFormData
          ]
      );
    }

    // disable otherCheckboxFields if providerPlacedOffWorkCheckboxField is checked
    return !!backgroundFormData[
      providerPlacedOffWorkCheckboxField.checkedField as keyof BackgroundFormData
    ];
  };

  // create date field column
  const generateDateField = (
    label: string,
    fieldName: keyof BackgroundFormData
  ) => {
    return (
      <Column spacing="200">
        <Text tag="label" type="b200" color="primary">
          {label}
        </Text>
        <DatePicker
          id={`new-jmr__background__${fieldName}`}
          data-cy={`new-jmr__background__${fieldName}`}
          placeholder="MM/DD/YYYY"
          value={backgroundFormData[fieldName] as string}
          onChange={(updatedValue) => handleChange(fieldName, updatedValue)}
          error={backgroundFormErrors[fieldName]?.error}
          errorMessage={backgroundFormErrors[fieldName]?.errorMessage}
        />
      </Column>
    );
  };

  const generateCheckboxRow = (
    checkedField: keyof BackgroundFormData,
    fieldName: keyof BackgroundFormData,
    checkboxLabel: string,
    label?: string
  ) => {
    return (
      <Row spacingInset={"300 none"} alignmentVertical={"top"}>
        <Column spacing={"300"}>
          <Checkbox
            checked={backgroundFormData[checkedField]}
            onChange={(newValue) => handleChange(checkedField, newValue)}
            data-cy={`new-jmr__background__${checkedField}`}
            disabled={getDisabled(checkedField)}
          >
            {checkboxLabel}
          </Checkbox>
          {backgroundFormData[checkedField] && label && (
            <>
              <Text tag="label" type="b200" color="primary">
                {label}
              </Text>
              <Row widths={["300px"]}>
                <Input
                  type="text"
                  placeholder="Restricted shift hours"
                  value={backgroundFormData[fieldName] as string}
                  onChange={(newValue) => {
                    handleChange(fieldName, newValue);
                  }}
                  error={backgroundFormErrors[fieldName]?.error}
                  errorMessage={backgroundFormErrors[fieldName]?.errorMessage}
                  data-cy={`new-jmr__background__${fieldName}`}
                  disabled={getDisabled(checkedField)}
                />
              </Row>
            </>
          )}
        </Column>
      </Row>
    );
  };

  return (
    <Box>
      <Column spacing={"200"} spacingInset={"400 none none none"}>
        <Text type="h300">Restriction Information</Text>
      </Column>
      <Row
        widths={"fill"}
        spacingInset={"400 none 300 none"}
        alignmentVertical={"top"}
      >
        {dateFields.map(
          ({ label, fieldName, condition }) =>
            condition &&
            generateDateField(label, fieldName as keyof BackgroundFormData)
        )}
      </Row>

      {checkboxFields.map(({ checkedField, fieldName, label, checkboxLabel }) =>
        generateCheckboxRow(
          checkedField as keyof BackgroundFormData,
          fieldName as keyof BackgroundFormData,
          checkboxLabel,
          label
        )
      )}
    </Box>
  );
};

export default restrictionInformation;
