import Divider from "@amzn/meridian/divider";
import RadioButton from "@amzn/meridian/radio-button";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import React, { ReactNode } from "react";
import { useDispatch } from "react-redux";
import { DownloadTWPAForm } from "src/store/placementDetailsPageSlice/types";
import { useAppSelector } from "src/store/store";

interface RadioOption {
  value: any;
  label: ReactNode;
}
interface radioFieldsProps {
  textInfo: ReactNode;
  formDataKey: keyof DownloadTWPAForm;
  radioOptions: RadioOption[];
  handleChange: (key: keyof DownloadTWPAForm, value: any) => void;
}
const radioFields = ({
  textInfo,
  radioOptions,
  formDataKey,
  handleChange,
}: radioFieldsProps) => {
  const dispatch = useDispatch();
  const { twpaFormData, twpaFormErrors } = useAppSelector(
    (state) => state.placementDetailsPage
  );
  const getErrorText = (formDataKey: keyof DownloadTWPAForm) => {
    if (twpaFormErrors[formDataKey]?.error) {
      return twpaFormErrors[formDataKey]?.errorMessage;
    }
  };
  return (
    <div>
      <Text>{textInfo}</Text>
      <Text color={"error"}>{getErrorText(formDataKey)}</Text>
      {radioOptions.map((radioOption, index) => (
        <Row
          key={`${index}-${radioOption.value}`}
          widths={["fill"]}
          spacingInset={"200"}
        >
          <RadioButton
            checked={twpaFormData[formDataKey] === radioOption.value}
            onChange={(value) => handleChange(formDataKey, value)}
            value={radioOption.value}
          >
            {radioOption.label}
          </RadioButton>
        </Row>
      ))}

      <Divider spacingBefore={"400"} spacingAfter={"400"} />
    </div>
  );
};

export default radioFields;
