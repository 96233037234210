import { FilterOptions } from "./types/filterOptions";
import { TableDataRow } from "./types/tableDataRow";
import { PlacementData } from "./types/placementData";
import { AllocatedJobShift } from "./types/allocatedJobShift";
import PlacementFilterService from "./placementFilterService";
import moment from "moment";
class PlacementTableDataService {
  extractAndFilterPending(
    placementsTableData: TableDataRow[],
    appliedFilters: FilterOptions
  ): TableDataRow[] {
    let pendingPlacements =
      this.extractPendingFromTableData(placementsTableData);
    pendingPlacements = PlacementFilterService.filterPlacements(
      pendingPlacements,
      appliedFilters
    );
    return pendingPlacements;
  }

  private extractPendingFromTableData(
    placementsTableData: TableDataRow[]
  ): TableDataRow[] {
    return placementsTableData.filter(
      (placement) => placement.category === "Pending"
    );
  }

  extractAndFilterActive(
    placementsTableData: TableDataRow[],
    appliedFilters: FilterOptions
  ): TableDataRow[] {
    let activePlacements = this.extractActiveFromTableData(placementsTableData);
    activePlacements = PlacementFilterService.filterPlacements(
      activePlacements,
      appliedFilters
    );
    return activePlacements;
  }

  private extractActiveFromTableData(
    placementsTableData: TableDataRow[]
  ): TableDataRow[] {
    return placementsTableData.filter(
      (placement) => placement.category === "Active"
    );
  }

  extractAndFilterExpired(
    placementsTableData: TableDataRow[],
    appliedFilters: FilterOptions
  ): TableDataRow[] {
    let expiredPlacements =
      this.extractExpiredFromTableData(placementsTableData);
    expiredPlacements = PlacementFilterService.filterPlacements(
      expiredPlacements,
      appliedFilters
    );
    return expiredPlacements;
  }

  private extractExpiredFromTableData(
    placementsTableData: TableDataRow[]
  ): TableDataRow[] {
    return placementsTableData.filter(
      (placement) => placement.category === "Expired"
    );
  }

  extractAndFilterClosed(
    placementsTableData: TableDataRow[],
    appliedFilters: FilterOptions
  ): TableDataRow[] {
    let closedPlacements = this.extractClosedFromTableData(placementsTableData);
    closedPlacements = PlacementFilterService.filterPlacements(
      closedPlacements,
      appliedFilters
    );
    return closedPlacements;
  }

  private extractClosedFromTableData(
    placementsTableData: TableDataRow[]
  ): TableDataRow[] {
    return placementsTableData.filter(
      (placement) => placement.category === "Closed"
    );
  }

  extractTransferred(placementsTableData: TableDataRow[]): TableDataRow[] {
    return placementsTableData.filter((placement) => placement.category === "Transferred In");
  }

  placementsDataToTableRow(placementData: PlacementData): TableDataRow {
    return {
      id: placementData.id,
      associateFullName: placementData.associateFullName,
      alias: placementData.login,
      associateNameOrAlias:
        placementData.associateFullName ?? placementData.login,
      jobs: placementData.allocatedJobShifts.map(
        (allocatedJobShift: AllocatedJobShift) => allocatedJobShift.jobName
      ),
      jobTypes: placementData.allocatedJobShifts.map(
        (allocatedJobShift: AllocatedJobShift) => allocatedJobShift.jobType
      ),
      shiftCodes: placementData.allocatedJobShifts.map(
        (allocatedJobShift: AllocatedJobShift) => allocatedJobShift.shiftCode
      ),
      site: placementData.site.name ?? "",
      previousSite: placementData.previousSite?.name ?? "",
      placementStartDate: moment(placementData.startAtDate).format("L"),
      placementEndDate: moment(placementData.endAtDate).format("L"),
      closedAtDate:
        placementData.closedAt !== null
          ? moment.utc(placementData.closedAt).format("L")
          : null,
      transferredAtDate:
        placementData.transferredAt !== null
          ? moment.utc(placementData.transferredAt).format("L")
          : null,
      daysInCategory: placementData.accommodationStatusCategory.daysInCategory,
      placementType: placementData.accommodationType,
      status: placementData.accommodationStatusCategory.status,
      category: placementData.accommodationStatusCategory.category,
      allocatedJobShifts: placementData.allocatedJobShifts,
      totalAccommodatedDaysByAssociate:
        placementData.totalAccommodatedDaysByAssociate,
      caseId: placementData.caseId,
      closenessToExpiration: placementData.accommodationStatusCategory.closenessToExpiration,
      jobSuffix: placementData.jobSuffix,
      jobsDisplayed: placementData.jobsDisplayed
    };
  }
}

export default new PlacementTableDataService();
