import Button from "@amzn/meridian/button";
import Divider from "@amzn/meridian/divider";
import Modal from "@amzn/meridian/modal";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import useForm from "src/hooks/useForm";
import {
  getBccEmailList,
  setIsEmailModalOpen,
  setSendEmailFormData,
  setSendEmailFormErrors,
  updateAccommodationEmail,
} from "src/store/placementDetailsPageSlice/placementDetailsPageSlice";
import {
  SelectedEmailTypeEnum,
  SendEmailFormData,
} from "src/store/placementDetailsPageSlice/types";
import { useAppSelector } from "src/store/store";
import BottomFields from "./bottomFields/bottomFields";
import CommonFields from "./commonFields/commonFields";
import DateFields from "./dateFields/dateFields";

const sendEmailModal = () => {
  const { isEmailModalOpen, sendEmailFormData, sendEmailFormErrors, placementId } =
    useAppSelector((state) => state.placementDetailsPage);
  const dispatch = useDispatch();

  const validateAdditonalField = (
    formDataKey: keyof SendEmailFormData,
    emailType: SelectedEmailTypeEnum
  ) => {
    return sendEmailFormData.emailType === emailType
      ? sendEmailFormData[formDataKey] !== undefined
      : true;
  };

  const { validateForm, setFormValues, errors } = useForm<SendEmailFormData>({
    validations: {
      emailType: {
        required: { value: true, message: "Email type is required" },
      },
      emailRecipients: {
        required: { value: true, message: "Email recipients is required" },
      },
      placementDetails: {
        custom: {
          isValid: () =>
            sendEmailFormData.emailType !==
            SelectedEmailTypeEnum.ApprovedOnsitePlacement
              ? sendEmailFormData.placementDetails !== undefined
              : true,
          message: "Placement details is required",
        },
      },
      lastDayWorked: {
        custom: {
          isValid: () =>
            validateAdditonalField(
              "lastDayWorked",
              SelectedEmailTypeEnum.DLSReferral
            ),
          message: "Last day worked is required",
        },
      },
      firstDayAbsent: {
        custom: {
          isValid: () =>
            validateAdditonalField(
              "firstDayAbsent",
              SelectedEmailTypeEnum.DLSReferral
            ),
          message: "First day absent is required",
        },
      },
      firstDayReturn: {
        custom: {
          isValid: () =>
            validateAdditonalField(
              "firstDayReturn",
              SelectedEmailTypeEnum.ReturnedtoFullDuty
            ),
          message: "First day return is required",
        },
      },
      areaManagerActionsNeeded: {
        custom: {
          isValid: () =>
            validateAdditonalField(
              "areaManagerActionsNeeded",
              SelectedEmailTypeEnum.ApprovedOnsitePlacement
            ),
          message: "Area manager actions is required",
        },
      },
      actionsForHr: {
        custom: {
          isValid: () =>
            validateAdditonalField(
              "actionsForHr",
              SelectedEmailTypeEnum.ApprovedOnsitePlacement
            ),
          message: "Actions for HR is required",
        },
      },
      agreeWithHealthCareRecommendations: {
        custom: {
          isValid: () =>
            validateAdditonalField(
              "agreeWithHealthCareRecommendations",
              SelectedEmailTypeEnum.AssociateRejectedPlacement
            ),
          message: "Please select one",
        },
      },
      referralAndClaimsStatus: {
        custom: {
          isValid: () =>
            validateAdditonalField(
              "referralAndClaimsStatus",
              SelectedEmailTypeEnum.DLSReferral
            ),
          message: "Please select one",
        },
      },
    },
  });

  const handleChange = (key: keyof SendEmailFormData, value: any) => {
    dispatch(setSendEmailFormData({ ...sendEmailFormData, [key]: value }));
    dispatch(
      setSendEmailFormErrors({ ...sendEmailFormErrors, [key]: undefined })
    );
  };

  useEffect(() => {
    dispatch(setSendEmailFormData({}));
  }, [isEmailModalOpen]);

  useEffect(() => {
    if (sendEmailFormData) {
      setFormValues(sendEmailFormData);
    }
  }, [sendEmailFormData]);

  useEffect(() => {
    dispatch(setSendEmailFormErrors(errors));
  }, [errors]);

  useEffect(() => {
    if (placementId && sendEmailFormData.emailType) {
      dispatch(getBccEmailList({placementId: placementId, emailType: sendEmailFormData.emailType}));
    }
  }, [sendEmailFormData.emailType]);

  return (
    <Modal
      title="Email"
      open={isEmailModalOpen}
      onClose={() => dispatch(setIsEmailModalOpen(false))}
      width={"416px"}
    >
      <Row>
        <Text>Send a status update email.</Text>
      </Row>

      <CommonFields handleChange={handleChange} />
      {sendEmailFormData.emailType && (
        <>
          <DateFields handleChange={handleChange} />
          <Divider spacingAfter={"400"} spacingBefore={"400"} />
          <BottomFields handleChange={handleChange} />
        </>
      )}

      <Row widths={["fill", ""]} spacingInset="400 none none none">
        <div />
        <Row>
          <Button onClick={() => dispatch(setIsEmailModalOpen(false))}>
            Cancel
          </Button>
          <Button
            onClick={() =>
              validateForm() ? dispatch(updateAccommodationEmail()) : null
            }
            data-cy="send-email-confirm__btn"
          >
            Send Email
          </Button>
        </Row>
      </Row>
    </Modal>
  );
};

export default sendEmailModal;
