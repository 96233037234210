import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { EmailSettings } from "src/services/emailDistribution/types/emailSettings";
import { EmailSettingsPayload } from "src/services/emailDistribution/types/emailSettingsPayload";
import EmailDistributionService from "src/services/emailDistribution/emailDistributionService";

export type EmailDistributionPageState = {
  pageDisabled: boolean;
  emailSettingsFormData: EmailSettings;
  isLoading: boolean;
  emailLength: number;
};

export const initialEmailSettingsFormData = {
  deniedEmailRecipients: "",
  placementEmailRecipients: "",
  loaEmailRecipients: "",
  rtfdEmailRecipients: "",
  supervisorsForDeniedEmail: false,
  supervisorsForPlacementEmail: false,
  supervisorsForLoaEmail: false,
  supervisorsForRtfdEmail: false,
};

const initialState: EmailDistributionPageState = {
  pageDisabled: true,
  emailSettingsFormData: initialEmailSettingsFormData,
  isLoading: true,
  emailLength: 1000,
};


interface getEmailSettingsForSiteParamType {
  siteId: number;
}

/**
 * get email settings for specified site
 * @returns email settings
 */
export const getEmailSettingsForSite = createAsyncThunk(
  "emailDistros/getEmailSettingsForSite",
  async ({ siteId }: getEmailSettingsForSiteParamType) => {
    const { data } = await EmailDistributionService.getForSite(siteId);
    return data;
  }
);

/**
 * save email settings for site
 * @returns email settings
 */
export const saveEmailSettings = createAsyncThunk(
  "emailDistros/saveEmailSettings",
  async (emailSettingsPayload: EmailSettingsPayload) => {
    const { data } = await EmailDistributionService.save(emailSettingsPayload);
    return data;
  }
);

const areAllEmailsValidLength = (emailSettingsFormData: EmailSettings, emailLength: number) => {
  return emailSettingsFormData.deniedEmailRecipients.length <= emailLength &&
    emailSettingsFormData.loaEmailRecipients.length <= emailLength &&
    emailSettingsFormData.placementEmailRecipients.length <= emailLength &&
    emailSettingsFormData.rtfdEmailRecipients.length <= emailLength;
}

/** Email Distribution Page Slice */
const { reducer, actions } = createSlice({
  name: "emailDistributionPageSlice",
  initialState,
  reducers: {
    setEmailSettingsFormData: (state, action) => {
      if(areAllEmailsValidLength(state.emailSettingsFormData, state.emailLength)) {
        state.emailSettingsFormData = action.payload;
      }
    },
    setPageDisabled: (state, action) => {
      state.pageDisabled = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getEmailSettingsForSite.fulfilled, (state, { payload }) => {
      state.emailSettingsFormData.deniedEmailRecipients = payload.deniedEmailRecipients ?? "";
      state.emailSettingsFormData.loaEmailRecipients = payload.loaEmailRecipients ?? "";
      state.emailSettingsFormData.placementEmailRecipients = payload.placementEmailRecipients ?? "";
      state.emailSettingsFormData.rtfdEmailRecipients = payload.rtfdEmailRecipients ?? "";
      state.emailSettingsFormData.supervisorsForDeniedEmail = payload.supervisorsForDeniedEmail ?? false;
      state.emailSettingsFormData.supervisorsForLoaEmail = payload.supervisorsForLoaEmail ?? false;
      state.emailSettingsFormData.supervisorsForPlacementEmail = payload.supervisorsForPlacementEmail ?? false;
      state.emailSettingsFormData.supervisorsForRtfdEmail = payload.supervisorsForRtfdEmail ?? false;
      state.isLoading = false;
    });
    builder.addCase(saveEmailSettings.fulfilled, (state, { payload }) => {
      state.emailSettingsFormData = payload;
    });
  }
});

export const { setEmailSettingsFormData, setPageDisabled, setIsLoading } = actions;

export default reducer;