export const getBodyLocationLabel = (value: String) => {
  let bodyLocationLabel = "";
  switch (value) {
    case "L":
      bodyLocationLabel = "Left";
      break;
    case "R":
      bodyLocationLabel = "Right";
      break;
    case "B":
      bodyLocationLabel = "Both";
      break;
    default:
      break;
  }
  return bodyLocationLabel;
};
