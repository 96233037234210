import React from "react";
import Tab, { TabGroup } from "@amzn/meridian/tab";
import {useAppSelector} from "src/store/store";
import {useDispatch} from "react-redux";
import {setSelectedTab} from "src/store/adminConfigPageSlice";
import {useLocation, useNavigate} from "react-router-dom";

const TAB_TO_PATH: Record<string, string> = {
    "site-config": "/siteConfiguration",
    "task-config": "/taskConfiguration",
    "form-config": "/restrictionFormConfiguration",
}

const tabGroup = () => {
  const { selectedTab } = useAppSelector(state => state.adminConfigPage);
  const { selectedRegion } = useAppSelector(state => state.regionTasksConfigPage);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <TabGroup value={selectedTab} onChange={(newTab) => {
        dispatch(setSelectedTab(newTab));
        navigate(TAB_TO_PATH[newTab]);
    }}>
      <Tab value="site-config">Site Configuration</Tab>
      <Tab value="task-config">Task Configuration</Tab>
      <Tab value="form-config">Work/Requirement Form Configuration</Tab>
      {selectedRegion.id > 0 && <Tab value="form-tasks-config">{selectedRegion.name} Edit</Tab>}
    </TabGroup>
  );
};

export default tabGroup;
