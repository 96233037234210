import React, { useState } from "react";
import Text from "@amzn/meridian/text";
import Select, { SelectOption } from "@amzn/meridian/select";
import Row from "@amzn/meridian/row";
import Column from "@amzn/meridian/column";
import { useAppSelector } from "src/store/store";
import { setMultipleSitesDropdownValues } from "src/store/defaultSettingsPageSlice";
import { useDispatch } from "react-redux";
import { createSearchRegExp } from "src/utils/searchUtils";

const multipleSiteDropdown = () => {
  const dispatch = useDispatch();
  const { allowedSites } = useAppSelector((state) => state.sites);
  const { pageDisabled, multipleSitesDropdownValues } = useAppSelector(
    (state) => state.defaultSettingsPage
  );

  const [searchQuery, setSearchQuery] = useState();
  const searchRegExp = createSearchRegExp(searchQuery);

  const matchedSiteOptions = allowedSites
    .map((site) => ({ label: site.site, value: site.id }))
    .filter((option) => !searchQuery || searchRegExp.test(option.label));

  return (
    <Row widths={["grid-4", "grid-2"]}>
      <Text>Multiple Select Sites For Regional Owners</Text>
      <Select
        size="small"
        value={multipleSitesDropdownValues}
        onChange={(newValue) => {
          dispatch(setMultipleSitesDropdownValues(newValue));
        }}
        searchQuery={searchQuery}
        onSearch={setSearchQuery}
        disabled={pageDisabled}
        placeholder="Enter value..."
      >
        {matchedSiteOptions.map((option) => (
          <SelectOption
            label={option.label}
            value={option.value}
            key={option.value}
          />
        ))}
        {!matchedSiteOptions.length ? (
          <Column alignmentVertical="center" spacing="300" spacingInset="500">
            <Text alignment="center">No results</Text>
          </Column>
        ) : null}
      </Select>
    </Row>
  );
};

export default multipleSiteDropdown;
