import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  jobPayloadToTableRow,
  JobTask,
  SiteType,
  Type,
} from "./createNewJobSlice";
import JobsService, { JobListItem, JobPayload } from "src/services/jobsService";
import { Region } from "./taskConfigPageSlice";
import { Site } from "./siteSlice";
import { DEFAULT_TABLE_PAGE_SIZE } from "src/constants/Pagination";

export interface TableDataRow {
  id: number | undefined;
  name: string;
  region: string;
  jobType: string;
  siteType: string | undefined;
  applicableSites: string[] | undefined;
  active: boolean;
  description: string;
  jobApplicability: string;
  tasks: Partial<JobTask>[];
}

export type JobConfigPageState = {
  isLoading: boolean;
  tableData: TableDataRow[];
  currentPage: number;
  totalPages: number;
  searchField: string;
  displayedApplicableSites?: Site[];
  createdJobSuccessfully: boolean;
  perPage: number;
};

const initialState: JobConfigPageState = {
  isLoading: true,
  tableData: [],
  currentPage: 1,
  perPage: DEFAULT_TABLE_PAGE_SIZE,
  totalPages: 0,
  searchField: "",
  createdJobSuccessfully: false,
};

const jobListItemToTableRow = (job: JobListItem) => {
  const row = {
    ...job,
    applicableSites: [],
    jobApplicability: "",
    tasks: [],
  };
  return row;
};

const serializeRequirementValue = (requirementValue: string) => {
  if (requirementValue === "Yes" || requirementValue === "No") {
    return requirementValue === "Yes" ? "1" : "0";
  }
  return requirementValue;
};

const tableRowToJobPayload = (
  row: TableDataRow,
  regions: Region[],
  jobTypes: Type[],
  siteTypes: SiteType[],
  specificSites: Site[]
) => {
  const jobPayload = {
    id: row.id,
    name: row.name,
    description: row.description,
    siteSpecific: row.jobApplicability === "siteSpecific",
    active: row.active || false,
    region: {
      id: regions.filter((r) => r.name === row.region)[0].id,
      name: row.region,
    },
    jobType: {
      id: jobTypes.filter((t) => t.name === row.jobType)[0].id,
      name: row.jobType,
    },
    siteType:
      row.jobApplicability === "siteType"
        ? {
            id: siteTypes.filter((s) => s.label === row.siteType)[0].id,
            label: row.siteType,
          }
        : null,
    specificSites:
      row.jobApplicability === "siteSpecific"
        ? row.applicableSites?.map((siteName) => ({
            id: specificSites.filter((s) => s.site === siteName)[0].id,
            site: siteName,
          }))
        : [],
    jobRequirements: row.tasks?.map((task) => ({
      task: {
        id: task.taskId,
        label: task.label,
      },
      bodyLocation: task.identifyBodyLocation ? task.bodyLocationType : null,
      value: serializeRequirementValue(task.requirementName as string),
    })),
  } as JobPayload;
  return jobPayload;
};

/**
 * get all jobs
 * @returns {Object}
 */
export const getJobs = createAsyncThunk("jobs/getJobs", async () => {
  const { data } = await JobsService.getJobs();
  return data;
});

export const addOrEditJob = createAsyncThunk(
  "jobs/createOrUpdateJobs",
  async (job: TableDataRow, { getState }) => {
    const state = ((await getState()) as any).createNewJobPage;
    const { data } = await JobsService.createOrUpdateJob(
      tableRowToJobPayload(
        job,
        state.regions,
        state.types,
        state.siteTypes,
        state.specificSites
      )
    );
    return jobPayloadToTableRow(data);
  }
);

export const getApplicableSites = createAsyncThunk(
  "jobs/getApplicableSites",
  async (jobId: number) => {
    const { data } = await JobsService.getApplicableSitesForJob(jobId);
    return data;
  }
);

/** Job Config Page Slice */
const { reducer, actions } = createSlice({
  name: "jobConfigPageSlice",
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setSearchField: (state, action) => {
      state.searchField = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setTotalPages: (state, action) => {
      state.totalPages = action.payload;
    },
    setDisplayedApplicableSites: (state, action) => {
      state.displayedApplicableSites = action.payload;
    },
    setCreatedJobSuccessfully: (state, action) => {
      state.createdJobSuccessfully = action.payload;
    },
    setPerPage: (state, action) => {
      state.perPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getJobs.fulfilled, (state, { payload }) => {
      state.tableData = payload.map((job: JobListItem) =>
        jobListItemToTableRow(job)
      );
      state.isLoading = false;
    });
    builder.addCase(addOrEditJob.fulfilled, (state, { payload }) => {
      if (payload) {
        state.searchField = payload.name;

        const isAlreadyInTable = state.tableData.some(
          (row) => row.id === payload.id
        );

        if (isAlreadyInTable) {
          // this will be the case when editing an existing site
          state.tableData = state.tableData.map((row) =>
            row.id === payload.id ? payload : row
          );
        } else {
          // this will be the case when adding a new site
          state.tableData = [...state.tableData, payload];
        }
        state.createdJobSuccessfully = true;
      }
    });
    builder.addCase(getApplicableSites.fulfilled, (state, { payload }) => {
      state.displayedApplicableSites = payload;
    });
  },
});

export const {
  setIsLoading,
  setSearchField,
  setCurrentPage,
  setTotalPages,
  setDisplayedApplicableSites,
  setCreatedJobSuccessfully,
  setPerPage,
} = actions;

export default reducer;
