import Button from "@amzn/meridian/button";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import ConfirmModal from "src/components/shared/confirmModal/confirmModal";
import {
  setJmrResults,
  setPageProgress,
  setSelectedTaskNames,
  setSelectedTasks,
  JmrFlowTypes,
} from "src/store/newJmrPageSlice";
import { useAppSelector } from "src/store/store";
import { datePickerStringToDateObj } from "src/utils/dateUtils";

const backgroundReview = () => {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { backgroundFormData, jmrFlowType } = useAppSelector(
    (state) => state.newJmrPage
  );

  const goToFirstPage = () => {
    dispatch(setPageProgress(0));
    dispatch(setSelectedTasks([]));
    dispatch(setSelectedTaskNames([]));
    dispatch(setJmrResults([]));
  };

  const backgroundReviewRows = [
    {
      label: "Associate Name",
      value: (
        <>
          {backgroundFormData.fullName} ({backgroundFormData.alias})
        </>
      ),
    },
    {
      label: "Employee ID",
      value: <>{backgroundFormData.employeeID}</>,
    },
    {
      label: "Job",
      value: <>{backgroundFormData.job}</>,
    },
    {
      label: "Location",
      value: <>{backgroundFormData.siteLocation}</>,
    },
    {
      label: "Shift",
      value: (
        <>
          {backgroundFormData.shift} | {backgroundFormData.shiftLength} hours
        </>
      ),
    },
    {
      label: "Case Information",
      value: (
        <>
          {backgroundFormData.caseSource} | {backgroundFormData.caseID}{" "}
        </>
      ),
    },
    {
      label: "Associate's Manager",
      value: <>{backgroundFormData.managerName}</>,
    },
    {
      label: "Restriction Duration",
      value: (
        <>
          {backgroundFormData.indefiniteRestrictions ?
          `${datePickerStringToDateObj(
            backgroundFormData.restrictionStartDate
          )?.toLocaleDateString("en-US")} - indefinite`:
          `${datePickerStringToDateObj(
            backgroundFormData.restrictionStartDate
          )?.toLocaleDateString("en-US")} - ${datePickerStringToDateObj(
            backgroundFormData.restrictionEndDate
          )?.toLocaleDateString("en-US")}`}
        </>
      ),
    },
  ];

  return (
    <Column>
      <Row widths={["113px", "fill", "61px"]} alignmentVertical="center">
        <Text type="h300">Background</Text>
        <div />
        <Button
          type="secondary"
          disabled={
            jmrFlowType === JmrFlowTypes.AlternativePlacementRecommended
          }
          onClick={() => setIsModalOpen(true)}
        >
          Edit
        </Button>
      </Row>
      <Column spacing={"200"}>
        {backgroundReviewRows.map((row, index) => (
          <Column key={index} spacing={"none"}>
            <Text type="b300">{row.label}</Text>
            <Text
              type="b300"
              color="secondary"
              data-cy={`new-jmr__review__${index}`}
            >
              {row.value}
            </Text>
          </Column>
        ))}
      </Column>

      <ConfirmModal
        modalTitle="Leave page"
        modalDescription="Leaving this page will restart your Job Match Results process."
        open={isModalOpen}
        setOpen={() => setIsModalOpen(false)}
        onConfirm={() => goToFirstPage()}
        width="419px"
      />
    </Column>
  );
};

export default backgroundReview;
