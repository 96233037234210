import { AlertType } from "@amzn/meridian/alert/alert";
import { createSlice } from "@reduxjs/toolkit";

let dummyId = 0;

export interface Toast {
  id: string;
  type: AlertType;
  message: string;
}

export type ToastsState = {
  toasts: Toast[];
};

const initialState: ToastsState = {
  toasts: [],
};

/** Toasts Slice */
const { reducer, actions } = createSlice({
  name: "toastsSlice",
  initialState,
  reducers: {
    createToast: (state, action) => {
      state.toasts = [
        ...state.toasts,
        { ...action.payload, id: dummyId.toString() },
      ];
      dummyId++;
    },
    onCloseToast: (state, action) => {
      state.toasts = state.toasts.filter((t) => t.id !== action.payload);
    },
  },
});

export const { createToast, onCloseToast } = actions;

export default reducer;
