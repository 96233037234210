import React from "react";
import { ButtonOption } from "@amzn/meridian/button-group";

const baliOrDaliOption = ({
  disabled,
  baliOrDali,
}: {
  disabled: boolean;
  baliOrDali: string;
}) => {
  return (
    <ButtonOption
      disabled={disabled}
      value={baliOrDali}
      data-cy={`new-jmr__background__caseSource-${baliOrDali}-btnOption`}
    >
      {baliOrDali}
    </ButtonOption>
  );
};

export default baliOrDaliOption;
