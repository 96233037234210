import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { arrayMove } from "src/utils/helpers";
import TasksService from "src/services/tasksService";
import RegionTaskService from "src/services/regionTasksService";
import { DEFAULT_TABLE_PAGE_SIZE } from "src/constants/Pagination";

export interface Country {
  id: number;
  name: string;
}

export interface RegionForm {
  id: number;
  name: string;
  country: Country;
  restrictionFormName: string;
  restrictionFormActive: boolean;
  restrictionFormDescription: string;
}

export interface Task {
  regionId: number;
  taskId: number;
  rank: number;
  label: string;
  description: string;
  identifyBodyLocation: boolean;
  measureUnit: string;
}

export interface FormDataRow {
  id: number;
  name: string;
  country: string;
  formName: string;
  isActive: boolean;
  description: string;
}

export type RegionTaskPageState = {
  tableData: FormDataRow[];
  currentPage: number;
  totalPages: number;
  perPage: number;
  searchField: string;
  regions: RegionForm[];
  selectedRegion: FormDataRow;
  selectedRegionTasks: Task[];
};

const initialState: RegionTaskPageState = {
  tableData: [],
  currentPage: 1,
  totalPages: 0,
  perPage: DEFAULT_TABLE_PAGE_SIZE,
  searchField: "",
  regions: [],
  selectedRegion: {
    id: -1,
    name: "",
    country: "",
    formName: "",
    isActive: false,
    description: "",
  },
  selectedRegionTasks: [],
};

/**
 * convert region object to a table row
 */
export const regionToFormDataRow = (region: RegionForm): FormDataRow => {
  const row: FormDataRow = {
    id: region.id,
    name: region.name,
    country: region.country?.name,
    formName:
      region.restrictionFormName == undefined
        ? `${region.name} Form`
        : region.restrictionFormName,
    isActive:
      region.restrictionFormActive == null
        ? false
        : region.restrictionFormActive,
    description: region.restrictionFormDescription,
  } as FormDataRow;
  return row;
};

/**
 * get regions
 * @returns {Object}
 */
export const getRegions = createAsyncThunk(
  "regionTasks/getRegions",
  async () => {
    const { data } = await TasksService.getRegions();
    return data;
  }
);

/**
 * get region by Id
 * @returns {Object}
 */
export const getRegionById = createAsyncThunk(
  "regionTasks/getRegionById",
  async (id: number) => {
    const { data } = await TasksService.getRegionById(id);
    return data;
  }
);

/**
 * get tasks of a region by region Id
 * @returns {Object}
 */
export const getRegionTasksById = createAsyncThunk(
  "regionTasks/getRegionTasksById",
  async (id: number) => {
    const { data } = await RegionTaskService.getTasksByRegionId(id);
    return data;
  }
);

/**
 * get tasks of a region by region Id
 * @returns {Object}
 */
export const updateRegionTasks = createAsyncThunk(
  "regionTasks/updateRegionTasks",
  async (tasks: Task[]) => {
    const { data } = await RegionTaskService.updateRegionTasks(tasks);
    return data;
  }
);

/**
 * get tasks of a region by region Id
 * @returns {Object}
 */
export const updateRestrictionForm = createAsyncThunk(
  "regionTasks/updateRestrictionForm",
  async (formdata: FormDataRow) => {
    const { data } = await RegionTaskService.updateRestrictionForm(formdata);
    return data;
  }
);

// if task don't have a rank, move to the top, other wist base on rank ascending
const sortTasks = (t1: Task, t2: Task) => {
  if (t1.rank == null) {
    return -1;
  } else if (t2.rank == null) {
    return 1;
  } else {
    return t1.rank - t2.rank;
  }
};

// corner case when there is no tasks has rank and move select task to bottom
const findHighestRankIndex = (arr: Task[]) => {
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].rank === 1) {
      return i - 1;
    }
  }
  return arr.length - 1;
};

// update task rank from start index and rank all the way to the end
const updateRank = (arr: Task[], toIndex: number, rank: number) => {
  // update item rank
  arr[toIndex].rank = rank;
  // iterate the rest of the ranks from the beginning
  let startIndex = arr.findIndex((val) => val.rank !== null);
  let startRank = 1;
  arr.forEach((item, index) => {
    if (index >= startIndex) {
      item.rank = startRank++;
    }
  });
};

const changeTaskOrder = (arr: Task[], fromIndex: number, toIndex: number) => {
  // if moved row don't have a rank, auto move to the first rank
  let updatedRank = 1;
  let updatedIndex = findHighestRankIndex(arr);
  if (arr[toIndex].rank !== null) {
    updatedRank = arr[toIndex].rank;
    updatedIndex = toIndex;
  }
  arrayMove(arr, fromIndex, updatedIndex);
  updateRank(arr, updatedIndex, updatedRank);
  return arr;
};

/** Task Config Page Slice */
const { reducer, actions } = createSlice({
  name: "regionTasksConfigPageSlice",
  initialState,
  reducers: {
    setSearchField: (state, action) => {
      state.searchField = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setPerPage: (state, action) => {
      state.perPage = action.payload;
    },
    setTotalPages: (state, action) => {
      state.totalPages = action.payload;
    },
    setSelectedRegion: (state, action) => {
      state.selectedRegion = action.payload;
    },
    changeTasksOrder: (state, action) => {
      state.selectedRegionTasks = changeTaskOrder(
        state.selectedRegionTasks,
        action.payload.old,
        action.payload.new
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRegions.fulfilled, (state, { payload }) => {
      state.regions = payload;
      state.tableData = payload.map((region: any) =>
        regionToFormDataRow(region)
      );
    });
    builder.addCase(getRegionTasksById.fulfilled, (state, { payload }) => {
      let regionTasks = [...payload];
      state.selectedRegionTasks = regionTasks.sort(sortTasks);
    });
  },
});

export const {
  setSearchField,
  setCurrentPage,
  setPerPage,
  setTotalPages,
  setSelectedRegion,
  changeTasksOrder,
} = actions;

export default reducer;
