import React, { useEffect } from "react";
import Button from "@amzn/meridian/button";
import Loader from "@amzn/meridian/loader";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import { useDispatch } from "react-redux";
import {
  ApprovalFormData,
  createAccommodation,
  getEmailSettingsForSite,
  setApprovalFormData,
  setApprovalFormErrors,
  setIsCancelModalOpen,
  setPageProgress,
} from "src/store/newJmrPageSlice";
import ApprovalEmailForm from "./approvalEmailForm/approvalEmailForm";
import Box from "@amzn/meridian/box";
import useForm from "src/hooks/useForm";
import { useAppSelector } from "src/store/store";
import { isEmailValid } from "src/utils/helpers";
import { ApiCallStatus } from "src/store/type";

const sendForApprovalPage = () => {
  const dispatch = useDispatch();
  const {
    approvalFormData,
    approvalFormErrors,
    accommodationSavingStatus,
  } = useAppSelector((state) => state.newJmrPage);
  const { validateForm, setFormValues, errors } = useForm<ApprovalFormData>({
    validations: {
      emailRecipients: {
        custom: {
          isValid: (value) => isEmailValid(value),
          message: "Must be valid email",
        },
      },
    },
  });

  const handleChange = (key: keyof ApprovalFormData, value: any) => {
    dispatch(setApprovalFormData({ ...approvalFormData, [key]: value }));
    dispatch(
      setApprovalFormErrors({ ...approvalFormErrors, [key]: undefined })
    );
  };

  useEffect(() => {
    if (approvalFormData) {
      setFormValues(approvalFormData);
    }
  }, [approvalFormData]);

  useEffect(() => {
    dispatch(setApprovalFormErrors(errors));
  }, [errors]);

  useEffect(() => {
    dispatch(getEmailSettingsForSite());
  }, []);

  return (
    <Box width={"80%"}>
      <Text type="d100" className="new-jmr__title" data-cy="new-jmr__title">
        Approval Notification
      </Text>

      <ApprovalEmailForm handleChange={handleChange} />

      <Row widths={["fill", "70px", "90px", "164px"]}>
        <div></div>
        <Button
          type="link"
          onClick={() => dispatch(setIsCancelModalOpen(true))}
        >
          Cancel
        </Button>
        <Button type="tertiary" onClick={() => dispatch(setPageProgress(3))}>
          Previous
        </Button>
        <Button
          data-cy="new-jmr__approval__next"
          disabled={accommodationSavingStatus === ApiCallStatus.Pending}
          onClick={() =>
            validateForm() ? dispatch(createAccommodation()) : null
          }
        >
          {accommodationSavingStatus === ApiCallStatus.Pending ? (
            <Loader size="small" />
          ) : (
            "Send for Approval"
          )}
        </Button>
      </Row>
    </Box>
  );
};

export default sendForApprovalPage;
