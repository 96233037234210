import React from "react";
import Row from "@amzn/meridian/row";
import Loader from "@amzn/meridian/loader";

const loadingSpinner = () => {
  return (
    <Row
      alignmentHorizontal="center"
      alignmentVertical="center"
      spacingInset="500"
    >
      <Loader />
    </Row>
  );
};

export default loadingSpinner;
