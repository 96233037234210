import Input from "@amzn/meridian/input";
import Row from "@amzn/meridian/row";
import Select, { SelectOption } from "@amzn/meridian/select";
import Text from "@amzn/meridian/text";
import React, { useState } from "react";
import { setJobFormData } from "src/store/createNewJobSlice";
import { useAppSelector } from "src/store/store";
import { createSearchRegExp } from "src/utils/searchUtils";
import Column from "@amzn/meridian/column";
import Divider from "@amzn/meridian/divider";
import { useDispatch } from "react-redux";
import Toggle from "@amzn/meridian/toggle";

const basicJobInfoFields = () => {
  const {
    isDisabled,
    regions,
    jobFormData,
    jobFormErrors,
    restrictionFormName,
  } = useAppSelector((state) => state.createNewJobPage);
  const [regionSearchQuery, setRegionSearchQuery] = useState();
  const regionSearchRegExp = createSearchRegExp(regionSearchQuery);
  const matchedRegionOptions = regions
    .map((region) => ({ label: region.name, value: region.name }))
    .filter(
      (option) => !regionSearchQuery || regionSearchRegExp.test(option.label)
    );
  const dispatch = useDispatch();

  return (
    <div>
      <Text type="h300">Basic Job Information</Text>
      <Row widths={"fill"} spacingInset={"500 none 300 none"}>
        <Column spacing="200">
          <Text tag="label" type="b200" color="primary">
            Job Name
          </Text>
          <Input
            type="text"
            placeholder="Enter job name..."
            value={jobFormData?.name}
            onChange={(name) => {
              dispatch(setJobFormData({ ...jobFormData, name }));
            }}
            error={jobFormErrors?.name?.error}
            errorMessage={jobFormErrors?.name?.errorMessage}
            disabled={isDisabled}
          />
        </Column>
      </Row>
      <Row widths={"fill"} spacingInset={"400 none"}>
        <Column spacing="200">
          <Text tag="label" type="b200" color="primary">
            Description
          </Text>
          <Input
            type="text"
            placeholder="Enter description..."
            value={jobFormData?.description}
            onChange={(description) => {
              dispatch(setJobFormData({ ...jobFormData, description }));
            }}
            disabled={isDisabled}
            error={jobFormErrors?.description?.error}
            errorMessage={jobFormErrors?.description?.errorMessage}
          />
        </Column>
      </Row>
      <Row widths={"fill"} spacingInset={"300 none"}>
        <Column spacing="200">
          <Text tag="label" type="b200" color="primary">
            Region
          </Text>
          <Select
            searchQuery={regionSearchQuery}
            onSearch={setRegionSearchQuery}
            placeholder="Select region..."
            value={jobFormData?.region}
            onChange={(region) => {
              dispatch(setJobFormData({ ...jobFormData, region }));
            }}
            error={jobFormErrors?.region?.error}
            errorMessage={jobFormErrors?.region?.errorMessage}
            disabled={isDisabled}
          >
            {matchedRegionOptions.map((option) => (
              <SelectOption
                label={option.label}
                value={option.value}
                key={option.value}
              />
            ))}
            {!matchedRegionOptions.length ? (
              <Column
                alignmentVertical="center"
                spacing="300"
                spacingInset="500"
              >
                <Text alignment="center">No results</Text>
              </Column>
            ) : null}
          </Select>
        </Column>
        <Column spacing="200">
          <Text tag="label" type="b200" color="primary">
            Form
          </Text>
          <Input disabled={true} value={restrictionFormName}></Input>
        </Column>
      </Row>
      <Row widths={"fill"} spacingInset={"400 none 300 none"}>
        <Column spacing="200">
          <Toggle
            size="large"
            checked={jobFormData?.active}
            onChange={(active) => {
              dispatch(setJobFormData({ ...jobFormData, active }));
            }}
            disabled={isDisabled}
          >
            Is Active?
          </Toggle>
        </Column>
      </Row>

      <Divider spacingAfter={"500"} spacingBefore={"450"} />
    </div>
  );
};

export default basicJobInfoFields;
