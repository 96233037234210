import { isLocal, isBeta, isGamma, isProd } from "src/utils/detectEnv";

const AUSTIN_BETA_BASE_URL = "na-beta.austin.a2z";
const AUSTIN_GAMMA_BASE_URL = "na-gamma.austin.a2z";
const AUSTIN_PROD_BASE_URL = "na.ehs-amazon";
const getEnvironment = () => {
  if (isLocal() || isBeta()) {
    return AUSTIN_BETA_BASE_URL;
  }
  if (isGamma()) {
    return AUSTIN_GAMMA_BASE_URL;
  }
  if (isProd()) {
    return AUSTIN_PROD_BASE_URL;
  }
  return AUSTIN_PROD_BASE_URL;
};
const baseUrl = getEnvironment();

export const constructIncidentLink = (caseId: string) => {
  return `https://${baseUrl}.com/incidents/incident/${caseId}`;
};

export const constructMedicalAttachmentsLink = (caseId: string) => {
  return `https://${baseUrl}.com/incidents/incident/${caseId}/medical_attachments`;
};
