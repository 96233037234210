import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Text from "@amzn/meridian/text";
import { useDispatch } from "react-redux";
import { useAppSelector } from "src/store/store";
import {
  getPlacementDetails,
  setSelectedStatus,
  setIsApproverInputVisible,
  setIsUpdateStatusModalOpen,
  updateAccommodationStatus,
  getAccommodationStatusForAccommodation,
  setIsUpdateStatusButtonDisabled,
  updateAccommodationStatusV2,
} from "src/store/placementDetailsPageSlice/placementDetailsPageSlice";
import { useForm } from "src/hooks/useForm";
import PlacementFormFields from "./placementFormFields/placementFormFields";
import Modal, { ModalFooter } from "@amzn/meridian/modal";
import Row from "@amzn/meridian/row";
import Button from "@amzn/meridian/button";
import { AccommodationStatus, SelectedPlacementFormData } from "src/store/placementDetailsPageSlice/types";
import { APPROVED_TWP, DENIED_TWP, INDEFINITE_RESTRICTIONS } from "src/config/AccommodationStatusNames";

const updatePlacementModalV2 = () => {
  const dispatch = useDispatch();
  const {
    accommodationStatus,
    isDecisionByLogin,
    isApproverInputVisible,
    isUpdateStatusModalOpen,
    updateStatusButtonDisabled,
    selectedStatus,
    placementDetails: { associateFullName, login, accommodationType },
  } = useAppSelector((state) => state.placementDetailsPage);
  const associateName: string = associateFullName
    ? associateFullName
    : login || "";
  const { placementId } = useParams();
  const confirmText =
    isDecisionByLogin && !isApproverInputVisible ? "Next" : "Update Status";

  const {
    validateForm,
    handleChange,
    resetForm,
    setFormValues,
    formData,
    errors,
  } = useForm<SelectedPlacementFormData>({
    validations: {
      status: {
        required: {
          value: true,
          message: "Placement Status is required",
        },
      },
    },
    initialValues: {},
  });
  const updateStatus: string = formData?.status;
  const approver: string = formData?.approver;

  useEffect(() => {
    if (selectedStatus) {
      setFormValues(selectedStatus);
    }
  }, [selectedStatus]);

  useEffect(() => {
    if(isApproverInputVisible) {
        dispatch(setIsUpdateStatusButtonDisabled(approver === undefined || approver.length === 0));
    }
  }, [isApproverInputVisible])

  /**
   * trigges API request to update placement
   * closes update placement modal
   * hides approver input
   * resets selected status data
   * resets form
   * @returns {Object}
   */
  const handleUpdatePlacement = async () => {
    const updateRequest: any = await dispatch(updateAccommodationStatusV2());

    if (updateRequest.payload.status === 200 && placementId) {
      dispatch(getPlacementDetails(+placementId));
      dispatch(getAccommodationStatusForAccommodation(+placementId));
    }
    dispatch(setIsUpdateStatusModalOpen(false));
    dispatch(setIsApproverInputVisible(false));
    dispatch(setSelectedStatus(undefined));
    resetForm();
  };

  /**
   * triggers API request with update placement modal data
   * displays approver input when isDecisionByLogin && approver input hidden
   * @returns {Object}
   */
  const onSave = () => {
    if (validateForm()) {
      const status = accommodationStatus!.filter(s => s.status === updateStatus)[0];
      if(isDecisionMakerNeeded(status)) {
        dispatch(setIsApproverInputVisible(true));
      } else {
        const updatedStatus: any = {placementId, approver, ...status};
        dispatch(setSelectedStatus(updatedStatus));
        handleUpdatePlacement();
      }
    }
  };

  const isDecisionMakerNeeded = (status: AccommodationStatus) => {
    const statusesWhichNeedDecisionMaker = new Set([APPROVED_TWP, DENIED_TWP, INDEFINITE_RESTRICTIONS]);
    const isDecisionMakerPresent = approver !== null && approver !== undefined && approver.trim().length > 0;
    return accommodationType === "DALI" && statusesWhichNeedDecisionMaker.has(status.status) && !isDecisionMakerPresent;
  }

  /**
   * allows back nav to status radio buttons on cancel from approver input
   * closes update placement modal from status radio buttons
   * @returns {Object}
   */
  const onCancel = () => {
    dispatch(setIsUpdateStatusModalOpen(false));
    dispatch(setIsApproverInputVisible(false));
    dispatch(setSelectedStatus(undefined));
    resetForm();
  };

  /**
   * dynamically displays update placement modal text
   * @returns {Object}
   */
  const handleModalDescriptionText = () => {
      return (
        <Text id="update-placement-modal-text">
          Update Placement Status for
          <b>{` ${associateName}`}</b>
        </Text>
      );
  };

  return (
    <Modal
      title={"Update Status"}
      open={isUpdateStatusModalOpen}
      onClose={onCancel}
      closeLabel="Close"
      width="416px"
      scrollContainer="modal"
    >
      {handleModalDescriptionText()}
      <PlacementFormFields
        formData={formData}
        errors={errors}
        handleChange={handleChange}
      />
      <ModalFooter>
        <Row alignmentHorizontal="right" widths="fill">
          <Button
            id="update-placement-status-cancel-btn"
            type="secondary"
            size="small"
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            id="update-placement-status-save-btn-v2"
            data-cy="placement-status__save_radio-btn"
            type="primary"
            size="small"
            disabled={updateStatusButtonDisabled}
            onClick={onSave}
          >
            {confirmText}
          </Button>
        </Row>
      </ModalFooter>
    </Modal>
  );
};

export default updatePlacementModalV2;
