import { createSlice } from "@reduxjs/toolkit";

export type ErrorBoundaryState = {
  errors: string[];
};

const initialState: ErrorBoundaryState = {
  errors: [],
};

/** Error Boundary Slice */
const { reducer, actions } = createSlice({
  name: "errorBoundarySlice",
  initialState,
  reducers: {
    addError: (state, { payload }) => {
      // In the future we can add logging here as well
      state.errors = [...state.errors, payload];
    },
    refreshPage: (state) => {
      window.location.href = "/";
    },
  },
});

export const { addError, refreshPage } = actions;

export default reducer;
