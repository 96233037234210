import Row from "@amzn/meridian/row";
import Select, { SelectOption } from "@amzn/meridian/select";
import Text from "@amzn/meridian/text";
import React, { useEffect, useRef, useState } from "react";
import { setTaskNames } from "src/store/createNewJobSlice";
import { useAppSelector } from "src/store/store";
import { createSearchRegExp } from "src/utils/searchUtils";
import Column from "@amzn/meridian/column";
import { useDispatch } from "react-redux";
import SelectedTasksList from "../selectedTasksList/selectedTasksList";
import { doTasksHaveErrors } from "../../../../utils/requirementFormUtils";

const customizeTaskFields = () => {
  const dispatch = useDispatch();
  const { tasks, taskNames, jobFormData, isDisabled } = useAppSelector(
    (state) => state.createNewJobPage
  );
  const [tasksSearchQuery, setTasksSearchQuery] = useState();
  const tasksSearchRegExp = createSearchRegExp(tasksSearchQuery);
  const matchedTaskOptions = [...tasks]
    .sort((a, b) => a.rank - b.rank)
    .map((task) => ({ label: task.label, value: task.label }))
    .filter(
      (option) => !tasksSearchQuery || tasksSearchRegExp.test(option.label)
    );

  const initialRender = useRef(true);
  useEffect(() => {
    if (jobFormData?.region) {
      if (!initialRender.current) {
        dispatch(setTaskNames([]));
      } else {
        initialRender.current = false;
      }
    }
  }, [jobFormData?.region]);

  return (
    <div>
      <Text type="h300">Add and Customize Task(s)</Text>
      <Text type="b200" className="create-new-job__content__add-task-subtitle">
        Add Tasks to this job and customize them from the list below. Click on a
        task to customize it further.
      </Text>
      {doTasksHaveErrors() && (
        <Text
          type="b200"
          color="error"
          className="create-new-job__content__add-task-subtitle"
        >
          Please fix all errors by clicking on the highlighted tasks
        </Text>
      )}
      <Row widths={"fill"} spacingInset={"300 none"}>
        <Column spacing={"300"}>
          <Text tag="label" type="b300" color="primary">
            Tasks
          </Text>
          <Select
            disabled={(jobFormData?.region ? false : true) || isDisabled}
            searchQuery={tasksSearchQuery}
            onSearch={setTasksSearchQuery}
            placeholder="Add a task..."
            value={taskNames}
            onChange={(taskNames) => {
              dispatch(setTaskNames(taskNames));
            }}
          >
            {matchedTaskOptions.map((option) => (
              <SelectOption
                label={option.label}
                value={option.value}
                key={option.value}
              />
            ))}
            {!matchedTaskOptions.length ? (
              <Column
                alignmentVertical="center"
                spacing="300"
                spacingInset="500"
              >
                <Text alignment="center">
                  No tasks found for {jobFormData?.region}
                </Text>
              </Column>
            ) : null}
          </Select>
        </Column>
      </Row>
      <SelectedTasksList />
    </div>
  );
};

export default customizeTaskFields;
