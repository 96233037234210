import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Text from "@amzn/meridian/text";
import { useDispatch } from "react-redux";
import { useAppSelector } from "src/store/store";
import {
  getPlacementDetails,
  setSelectedStatus,
  setIsApproverInputVisible,
  setIsUpdateStatusModalOpen,
  updateAccommodationStatus,
} from "src/store/placementDetailsPageSlice/placementDetailsPageSlice";
import { useForm } from "src/hooks/useForm";
import PlacementFormFields from "./placementFormFields/placementFormFields";
import Modal, { ModalFooter } from "@amzn/meridian/modal";
import Row from "@amzn/meridian/row";
import Button from "@amzn/meridian/button";
import { SelectedPlacementFormData } from "src/store/placementDetailsPageSlice/types";

const updatePlacementModal = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    accommodationStatus,
    isDecisionByLogin,
    isApproverInputVisible,
    isUpdateStatusModalOpen,
    selectedStatus,
    placementDetails: { associateFullName, login },
  } = useAppSelector((state) => state.placementDetailsPage);
  const associateName: string = associateFullName
    ? associateFullName
    : login || "";
  const { placementId } = useParams();
  const confirmText =
    isDecisionByLogin && !isApproverInputVisible ? "Next" : "Update Status";

  const {
    validateForm,
    handleChange,
    resetForm,
    setFormValues,
    formData,
    errors,
  } = useForm<SelectedPlacementFormData>({
    validations: {
      status: {
        required: {
          value: true,
          message: "Placement Status is required",
        },
      },
    },
    initialValues: {},
  });
  const updateStatus: string = formData?.status;
  const approver: string = formData?.approver;
  const isSaveDisabled: boolean =
    isUpdateStatusModalOpen && updateStatus && !isApproverInputVisible
      ? false
      : !!approver
      ? false
      : true;

  useEffect(() => {
    if (selectedStatus) {
      setFormValues(selectedStatus);
    }
  }, [selectedStatus]);

  /**
   * trigges API request to update placement
   * closes update placement modal
   * hides approver input
   * resets selected status data
   * resets form
   * @returns {Object}
   */
  const handleUpdatePlacement = async () => {
    const updateRequest: any = await dispatch(updateAccommodationStatus());

    if (updateRequest.payload.status === 200 && placementId) {
      dispatch(getPlacementDetails(+placementId));
    }
    dispatch(setIsUpdateStatusModalOpen(false));
    dispatch(setIsApproverInputVisible(false));
    dispatch(setSelectedStatus(undefined));
    resetForm();
  };

  /**
   * triggers API request with update placement modal data
   * displays approver input when isDecisionByLogin && approver input hidden
   * @returns {Object}
   */
  const onSave = () => {
    if (validateForm()) {
      const status: any = accommodationStatus?.reduce((prev: object, curr) => {
        if (curr.status === updateStatus) {
          prev = curr;
        }
        return prev;
      }, {});

      const updatedStatus: any = approver
        ? Object.assign({ approver, placementId }, status)
        : Object.assign({ placementId }, status);
      dispatch(setSelectedStatus(updatedStatus));

      if (!isApproverInputVisible && isDecisionByLogin) {
        dispatch(setIsApproverInputVisible(true));
      } else if (approver && isApproverInputVisible && isDecisionByLogin) {
        handleUpdatePlacement();
      } else if (!isDecisionByLogin) {
        handleUpdatePlacement();
      }
    }
  };

  /**
   * allows back nav to status radio buttons on cancel from approver input
   * closes update placement modal from status radio buttons
   * @returns {Object}
   */
  const onCancel = () => {
    if (!isApproverInputVisible) {
      dispatch(setIsUpdateStatusModalOpen(false));
      dispatch(setIsApproverInputVisible(false));
      dispatch(setSelectedStatus(undefined));
      resetForm();
    } else {
      dispatch(setIsUpdateStatusModalOpen(true));
      dispatch(setIsApproverInputVisible(false));
    }
  };

  /**
   * dynamically displays update placement modal text
   * @returns {Object}
   */
  const handleModalDescriptionText = () => {
    if (isDecisionByLogin && isApproverInputVisible) {
      return (
        <Text id="update-placement-add-approver-text">
          Who has approved this placement?
        </Text>
      );
    } else {
      return (
        <Text id="update-placement-modal-text">
          Update Placement Status for
          <b>{` ${associateName}`}</b>
        </Text>
      );
    }
  };

  return (
    <Modal
      title={"Update Status"}
      open={isUpdateStatusModalOpen}
      onClose={onCancel}
      closeLabel="Close"
      describedById="modal-description"
      width="416px"
      scrollContainer="modal"
    >
      {handleModalDescriptionText()}
      <PlacementFormFields
        formData={formData}
        errors={errors}
        handleChange={handleChange}
      />
      <ModalFooter>
        <Row alignmentHorizontal="right" widths="fill">
          <Button
            id="update-placement-status-cancel-btn"
            type="secondary"
            size="small"
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            id="update-placement-status-save-btn"
            data-cy="placement-status__save_radio-btn"
            type="primary"
            size="small"
            disabled={isSaveDisabled}
            onClick={onSave}
          >
            {confirmText}
          </Button>
        </Row>
      </ModalFooter>
    </Modal>
  );
};

export default updatePlacementModal;
