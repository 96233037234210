import React from "react";
import Text from "@amzn/meridian/text";
import Icon from "@amzn/meridian/icon";
import plusKnockoutTokens from "@amzn/meridian-tokens/base/icon/plus-knockout";
import { useDispatch } from "react-redux";
import { setSearchField } from "src/store/jobConfigPageSlice";
import Row from "@amzn/meridian/row";
import { useAppSelector } from "src/store/store";
import SearchField from "@amzn/meridian/search-field";
import Box from "@amzn/meridian/box";
import { useNavigate } from "react-router-dom";
import { setSelectedJobId } from "src/store/createNewJobSlice";
import Button from "@amzn/meridian/button";

const topOptions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { searchField } = useAppSelector((state) => state.jobConfigPage);

  return (
    <Row
      widths={["85%", "15%"]}
      spacingInset="400 none"
      alignmentHorizontal="justify"
    >
      <Row width={"grid-4"}>
        <SearchField
          value={searchField}
          onChange={(newSearchField) =>
            dispatch(setSearchField(newSearchField))
          }
          placeholder="Search for..."
          onSubmit={() => {}}
        />
      </Row>
      <Button
        className="job-config__add-job"
        onClick={() => {
          dispatch(setSelectedJobId(undefined));
          navigate("/createNewJob");
        }}
      >
        Add Job
      </Button>
    </Row>
  );
};

export default topOptions;
