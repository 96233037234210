import React, { useCallback, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Theme from "@amzn/meridian/theme";
import Menu, { MenuItem } from "@amzn/meridian/menu";
import blueLightTokens from "@amzn/meridian-tokens/theme/blue-light";
import { useAppSelector } from "src/store/store";
import { linkClickHandler } from "src/utils/helpers";
import Button from "@amzn/meridian/button";
import Thumbnail from "@amzn/meridian/thumbnail";
import * as LDAP from "src/config/LDAP";
import Divider from "@amzn/meridian/divider";
const selectionTokens = {
  ...blueLightTokens,
  themeForegroundPrimaryDisabled: "#232F3E",
};
import {
  BADGE_PHOTO_THUMBNAIL_URL,
  RTW_WIKI_URL,
  RTW_FAQ_LINK,
  RTW_TRAINING_LINK, SUBMIT_FEATURE_REQUEST_AND_BUG_URL,
} from "src/config/ExternalLinks";

const profileMenu = () => {
  const navigate = useNavigate();
  const { firstName, alias, ldap } = useAppSelector((state) => state.user);

  const isAdmin = ldap.includes(LDAP.ADMIN);
  const isSMES = ldap.includes(LDAP.SMES);
  const isDev = ldap.includes(LDAP.DEV);

  const [open, setOpen] = useState(false);
  const onClickButton = useCallback(() => setOpen(true), []);
  const onClose = useCallback(() => setOpen(false), []);
  const buttonRef = useRef();

  return (
    <div>
      <Button
        type="link"
        size="xlarge"
        ref={buttonRef}
        id="profile-btn"
        onClick={onClickButton}
        onMouseEnter={onClickButton}
        aria-controls="profile-menu"
        aria-haspopup="menu"
        aria-expanded="false"
      >
        <Thumbnail source={`${BADGE_PHOTO_THUMBNAIL_URL}${alias}`} />
      </Button>
      <Theme tokens={selectionTokens}>
        <Menu
          anchorNode={buttonRef.current}
          id="profile-menu"
          open={open}
          position="bottom"
          onClose={onClose}
          minWidth={300}
        >
          <MenuItem disabled={true}>Hello, {firstName}</MenuItem>
          <MenuItem disabled={true}>
            <Divider />
          </MenuItem>
          <MenuItem onClick={() => navigate("/defaultSettings")}>
            Default Settings
          </MenuItem>
          {isDev || isAdmin ? (
            <MenuItem onClick={() => navigate("/adminConfiguration")}>
              Admin Configuration
            </MenuItem>
          ) : null}
          {isDev || isAdmin || isSMES ? (
            <MenuItem onClick={() => navigate("/jobConfiguration")}>
              Job Configuration
            </MenuItem>
          ) : null}
          <MenuItem disabled={true}>
            <Divider />
          </MenuItem>
          <MenuItem
            onClick={() => {
              linkClickHandler(SUBMIT_FEATURE_REQUEST_AND_BUG_URL);
            }}
          >
            Submit Issue/Feature Request
          </MenuItem>
          <MenuItem disabled={true}>
            <Divider />
          </MenuItem>
          <MenuItem
            onClick={() => {
              linkClickHandler(RTW_WIKI_URL);
            }}
          >
            RTW Wiki
          </MenuItem>
          <MenuItem
            onClick={() => {
              linkClickHandler(RTW_FAQ_LINK);
            }}
          >
            RTW FAQ
          </MenuItem>
          <MenuItem
            onClick={() => {
              linkClickHandler(RTW_TRAINING_LINK);
            }}
          >
            RTW Training
          </MenuItem>
        </Menu>
      </Theme>
    </div>
  );
};
export default profileMenu;
