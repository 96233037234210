import Column from "@amzn/meridian/column";
import Pagination from "@amzn/meridian/pagination";
import Table, { TableCell, TableRow } from "@amzn/meridian/table";
import { TableSortDirection } from "@amzn/meridian/table/table";
import React, { useCallback, useEffect, useState } from "react";
import UncontrolledExpandableRow from "src/components/shared/uncontrolledExpandableRow/uncontrolledExpandableRow";
import { PlacementDetailsWorkRestriction } from "src/store/placementDetailsPageSlice/types";
import { useAppSelector } from "src/store/store";
import { removeSpecialCharactersFromString } from "src/utils/helpers";
import { sortAndPaginateTableData } from "src/utils/tableUtils";
import { getBodyLocationLabel } from "src/utils/getBodyLocationLabel"

const restrictions = () => {
  const { placementDetails } = useAppSelector(
    (state) => state.placementDetailsPage
  );

  const [sortColumn, setSortColumn] = useState("taskName");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [sortDirection, setSortDirection] =
    useState<TableSortDirection>("ascending");
  const onSort = useCallback(({ sortColumn, sortDirection }) => {
    setSortDirection(sortDirection);
    setSortColumn(sortColumn);
  }, []);
  const [sortedTableData, setSortedTableData] = useState(
    placementDetails.workRestrictions
  );

  useEffect(() => {
    if (placementDetails?.workRestrictions) {
      // filter, sorting, and pagination
      const [paginatedData, totalPages] = sortAndPaginateTableData(
        [...placementDetails.workRestrictions],
        sortColumn,
        sortDirection,
        currentPage,
        5
      );
      setSortedTableData(paginatedData as PlacementDetailsWorkRestriction[]);
      setTotalPages(totalPages as number);
    }
  }, [
    sortColumn,
    sortDirection,
    placementDetails.workRestrictions,
    currentPage,
  ]);

  return (
    <Column alignmentHorizontal="center" spacing={"400"}>
      <Table
        headerRows={1}
        rowComponents={[UncontrolledExpandableRow, TableRow]}
        showStripes={true}
        onSort={onSort}
        sortColumn={sortColumn}
        sortDirection={sortDirection}
        showDividers={true}
      >
        <TableRow>
          <TableCell sortColumn="taskName">Restrictions</TableCell>
          <TableCell sortColumn="bodyLocation">Body Location</TableCell>
          <TableCell sortColumn="restrictedValue">Hours</TableCell>
          <TableCell sortColumn="restrictedValue">Yes/No</TableCell>
        </TableRow>

        {sortedTableData?.map((restriction, index) => (
          <TableRow key={restriction.taskId}>
            <TableCell
              data-cy={`placement-details__restrictions__task-${removeSpecialCharactersFromString(
                restriction.taskName
              )}`}
            >
              {restriction.taskName}
            </TableCell>
            <TableCell>
              {restriction.bodyLocation && getBodyLocationLabel(restriction.bodyLocation)}
            </TableCell>
            <TableCell>
              {restriction.hoursTypeTask && restriction.restrictedValue}
            </TableCell>
            <TableCell>
              {restriction.yesNoTypeTask && restriction.restrictedValue}
            </TableCell>
          </TableRow>
        ))}
      </Table>
      <Pagination
        numberOfPages={totalPages}
        onChange={setCurrentPage}
        currentPage={currentPage}
        showSkipArrows={true}
      />
    </Column>
  );
};

export default restrictions;
