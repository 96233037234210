import React from "react";
import { useNavigate } from "react-router-dom";
import Breadcrumb, { BreadcrumbGroup } from "@amzn/meridian/breadcrumb";

interface breadcrumbLink {
  label: string;
  url: string;
}

interface breadcrumbsProps {
  breadcrumbList: breadcrumbLink[];
}

const breadcrumbs = ({ breadcrumbList }: breadcrumbsProps) => {
  const navigate = useNavigate();

  return (
    <BreadcrumbGroup>
      {breadcrumbList.map((breadcrumbLink, index) => (
        <Breadcrumb onClick={() => navigate(breadcrumbLink.url)} key={index}>
          {breadcrumbLink.label}
        </Breadcrumb>
      ))}
    </BreadcrumbGroup>
  );
};
export default breadcrumbs;
