import React from "react";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import ControlGroup from "@amzn/meridian/control-group";
import Checkbox from "@amzn/meridian/checkbox";
import ButtonGroup, { ButtonOption } from "@amzn/meridian/button-group";
import Input from "@amzn/meridian/input";
import Select, { SelectOption } from "@amzn/meridian/select";
import { TableDataRow } from "src/store/updateShiftsPageSlice";
import { ErrorRecord } from "src/hooks/useForm";
import Column from "@amzn/meridian/column";

interface shiftFormFieldsProps {
  formData: TableDataRow;
  errors: ErrorRecord<TableDataRow>;
  handleChange: (
    key: keyof TableDataRow,
    value: any,
    sanitizeFn?: (value: any) => any
  ) => void;
}
const shiftFormFields = ({
  formData,
  errors,
  handleChange,
}: shiftFormFieldsProps) => {
  const generateShiftLengths = () => {
    const shiftLengths = [];
    for (let i = 0; i < 13; i++) {
      shiftLengths.push({ id: i, name: `${i + 1} Hours` });
    }
    return shiftLengths;
  };

  return (
    <div>
      <Row widths="fill" spacingInset={"500 none 300 none"}>
        <Column spacing={"200"}>
          <Text tag="label" type="b200" color="primary">
            Shift Code
          </Text>
          <Input
            type="text"
            placeholder="Enter shift code..."
            value={formData.shiftCode}
            onChange={(shiftCode) => {
              handleChange("shiftCode", shiftCode);
            }}
            error={errors.shiftCode?.error}
            errorMessage={errors.shiftCode?.errorMessage}
          />
        </Column>
      </Row>
      <Row widths="fill" spacingInset={"300 none"}>
        <Column spacing={"200"}>
          <Text tag="label" type="b200" color="primary">
            Shift Length
          </Text>
          <Select
            placeholder="Select shift length..."
            value={formData.shiftLength}
            onChange={(shiftLength) => {
              handleChange("shiftLength", shiftLength);
            }}
            error={errors.shiftLength?.error}
            errorMessage={errors.shiftLength?.errorMessage}
          >
            {generateShiftLengths().map((option) => (
              <SelectOption
                label={option.name}
                value={option.name}
                key={option.id}
              />
            ))}
          </Select>
        </Column>
      </Row>
      <Row widths={["40%", "fill"]} spacingInset={"300 none"}>
        <Column spacing={"200"}>
          <Text tag="label" type="b300" color="primary">
            Time of Day
          </Text>
          <ButtonGroup
            value={formData.dayOrNight}
            onChange={(dayOrNight) => {
              handleChange("dayOrNight", dayOrNight);
            }}
          >
            <ButtonOption value="Day">Day</ButtonOption>
            <ButtonOption value="Night">Night</ButtonOption>
          </ButtonGroup>
        </Column>
        <Column spacing={"200"} alignmentVertical="top">
          <Text tag="label" type="b300" color="primary">
            Shift Activity
          </Text>
          <ButtonGroup
            value={formData.active}
            onChange={(active) => {
              handleChange("active", active);
            }}
          >
            <ButtonOption value="Active">Active</ButtonOption>
            <ButtonOption value="Inactive">Inactive</ButtonOption>
          </ButtonGroup>
        </Column>
      </Row>
      <Row widths={["40%", "fill"]}>
        {(errors.dayOrNight?.error || errors.active?.error) && (
          <Text
            tag="label"
            type="b200"
            color="error"
            className="update-shifts__day-error"
          >
            {errors.dayOrNight?.errorMessage || ""}
          </Text>
        )}
        {(errors.active?.error || errors.dayOrNight?.error) && (
          <Text
            tag="label"
            type="b200"
            color="error"
            className="update-shifts__active-error"
          >
            {errors.active?.errorMessage || ""}
          </Text>
        )}
      </Row>

      <Row widths={"fill"} spacingInset={"300 none"}>
        <Column spacing={"300"}>
          <Text tag="label" type="b300" color="primary">
            Days of the Week
          </Text>
          <ControlGroup
            name="daysOfWeek[]"
            onChange={(daysOfWeek) => {
              handleChange("daysOfWeek", daysOfWeek);
            }}
            value={formData.daysOfWeek}
          >
            <Checkbox value="SUNDAY">Sunday</Checkbox>
            <Checkbox value="MONDAY">Monday</Checkbox>
            <Checkbox value="TUESDAY">Tuesday</Checkbox>
            <Checkbox value="WEDNESDAY">Wednesday</Checkbox>
            <Checkbox value="THURSDAY">Thursday</Checkbox>
            <Checkbox value="FRIDAY">Friday</Checkbox>
            <Checkbox value="SATURDAY">Saturday</Checkbox>
          </ControlGroup>
        </Column>
      </Row>
    </div>
  );
};

export default shiftFormFields;
