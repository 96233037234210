import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  setIsDecisionByLogin,
  setAccommodationStatus,
  setCurrAccommodation,
  setIsUpdateStatusButtonDisabled,
} from "src/store/placementDetailsPageSlice/placementDetailsPageSlice";
import { ErrorRecord } from "src/hooks/useForm";
import { useAppSelector } from "src/store/store";
import Row from "@amzn/meridian/row";
import ControlGroup from "@amzn/meridian/control-group";
import RadioButton from "@amzn/meridian/radio-button";
import Input from "@amzn/meridian/input";
import Column from "@amzn/meridian/column";
import Text from "@amzn/meridian/text";
import {
  AccommodationStatusWithDecisionByLoginNeeded,
  SelectedPlacementFormData,
} from "src/store/placementDetailsPageSlice/types";
import { RETURN_TO_FULL_DUTY_ID, INDEFINITE_RESTRICTIONS_ID } from "src/config/AccommodationStatusId";
import { INDEFINITE_RESTRICTIONS } from "src/config/AccommodationStatusNames";

interface placementFormFieldsProp {
  formData: SelectedPlacementFormData;
  errors: ErrorRecord<SelectedPlacementFormData>;
  handleChange: (
    key: keyof SelectedPlacementFormData,
    value: any,
    sanitizeFn?: (value: any) => any
  ) => void;
}

const placementFormFields = ({
  formData,
  handleChange,
}: placementFormFieldsProp) => {
  const dispatch = useDispatch();
  const { accommodationStatus, placementDetails, isApproverInputVisible } =
    useAppSelector((state) => state.placementDetailsPage);
  const {featureFlags} = useAppSelector(state => state.user);
  const currAccommodationStatus: string =
    placementDetails.accommodationStatus?.status;

  useEffect(() => {
    dispatch(setAccommodationStatus(accommodationStatus));
  }, [accommodationStatus]);

  useEffect(() => {
    dispatch(setIsUpdateStatusButtonDisabled(true));
  }, [])

  /**
   * display radioButtons for each accommodation status
   * @returns {Object}
   */
  const handleRadioButtons = () => {
    const indefiniteRestrictionsTransitionSet = new Set([RETURN_TO_FULL_DUTY_ID, INDEFINITE_RESTRICTIONS_ID]);
    // Only show RTFD and Indefinite Restrictions if current status is Indefinite Restrictions.
    // Refer: https://issues.amazon.com/issues/P113097040
    if(!featureFlags.DP_INTEGRATION_P0 && currAccommodationStatus === INDEFINITE_RESTRICTIONS) {
      return accommodationStatus?.filter(
        accStatus => indefiniteRestrictionsTransitionSet.has(accStatus.id)
        ).map((option, index) => {
          return (
            <RadioButton
              key={option.status}
              value={option.status}
              data-cy={`placement-status__${index}_radio-btn`}
            >
              {option.status}
            </RadioButton>
          )
      });
    }

    return accommodationStatus?.map((option, index) => {
      return (
        <RadioButton
          key={option.status}
          value={option.status}
          data-cy={`placement-status__${index}_radio-btn`}
        >
          {option.status}
        </RadioButton>
      );
    });
  };

  /**
   * sets boolean isDecisionByLogin to toggle display of approver input
   * @returns {Object}
   */
  const handleDecisionByLogin = (isDecisionByLoginNeeded: boolean) => {
    dispatch(setIsDecisionByLogin(isDecisionByLoginNeeded));
  };

  /**
   * sets current accommodation data without requiring a triggered event
   * @returns {Object}
   */
  const handleSetCurrAccommodation = () => {
    const updatedCurrAccommodation: string = formData.status
      ? formData.status
      : currAccommodationStatus;
    const status: AccommodationStatusWithDecisionByLoginNeeded = accommodationStatus?.reduce(
      (prev: object, curr) => {
        if (curr.status === updatedCurrAccommodation) {
          prev = curr;
        }
        return prev;
      },
      {}
    ) as AccommodationStatusWithDecisionByLoginNeeded;
    dispatch(setIsDecisionByLogin(status.decisionByLoginNeeded));
    dispatch(setCurrAccommodation(updatedCurrAccommodation));
    if (formData.status !== updatedCurrAccommodation) {
      setTimeout(() => {
        handleChange("status", updatedCurrAccommodation);
      }, 0);
    }
    return updatedCurrAccommodation;
  };

  /**
   * displays placement status options inside modal
   * @returns {Object}
   */
  const handleUpdateStatus = () => {
    return (
      <ControlGroup
        label=""
        name="Placement Options"
        data-cy="placement-status__radio-btn-grp"
        onChange={(status) => {
          const option: AccommodationStatusWithDecisionByLoginNeeded = accommodationStatus?.reduce(
            (prev: object, curr) => {
              if (curr.status === status) {
                prev = curr;
              }
              return prev;
            },
            {}
          ) as AccommodationStatusWithDecisionByLoginNeeded;
          handleDecisionByLogin(option?.decisionByLoginNeeded);
          handleChange("status", status);
          dispatch(setIsUpdateStatusButtonDisabled(false));
        }}
        value={handleSetCurrAccommodation()}
      >
        {handleRadioButtons()}
      </ControlGroup>
    );
  };

  /**
   * displays approver input inside modal
   * @returns {Object}
   */
  const handleAddApprover = () => {
    return (
      <Column spacing="200">
        <Text tag="label" type="b200" color="primary">
          Decision Maker
        </Text>
        <Input
          id="update-placement-approver-input"
          data-cy="placement-status__approver_input"
          value={formData.approver}
          onChange={(value) => {
            // used in UpdatePlacementModalV2
            dispatch(setIsUpdateStatusButtonDisabled(value.length === 0));
            handleChange("approver", value);
          }}
          type="text"
          placeholder="Decision maker's alias..."
        />
      </Column>
    );
  };

  return (
    <div>
      <Row widths="fill" spacingInset={"400 none"}>
        {!isApproverInputVisible ? handleUpdateStatus() : handleAddApprover()}
      </Row>
    </div>
  );
};

export default placementFormFields;
