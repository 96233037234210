import React, { useEffect, useState } from "react";
import Text from "@amzn/meridian/text";
import Toggle from "@amzn/meridian/toggle";
import RadioButton from "@amzn/meridian/radio-button";
import { IdentifyBodyLocationEnum, JmrTask } from "src/store/newJmrPageSlice";

interface identifyBodyLocationToggleProp {
  task: JmrTask;
  setIdentifyBodyLocationValue: (bodyLocationEnum: IdentifyBodyLocationEnum | null, task: JmrTask) => void;
}

const identifyBodyLocationToggle = ({ task, setIdentifyBodyLocationValue }: identifyBodyLocationToggleProp) => {
  const [bodyLocationToggleState, setBodyLocationToggleState] = useState(task.bodyLocationValue !== null);

  useEffect(() => {
    if (!bodyLocationToggleState) {
      setIdentifyBodyLocationValue(null, task);
    }
  }, [bodyLocationToggleState]);

  return (
    <>
      <Text type='b300'>Identify body location? </Text>
      <Toggle checked={bodyLocationToggleState} onChange={setBodyLocationToggleState} />
      {bodyLocationToggleState && (
        <>
          <RadioButton
            checked={task.bodyLocationValue === IdentifyBodyLocationEnum.LEFT}
            onChange={() => {
              setIdentifyBodyLocationValue(IdentifyBodyLocationEnum.LEFT, task);
            }}
            name='bodyLocation'
            value={IdentifyBodyLocationEnum.LEFT}
            size='medium'
          >
            Left
          </RadioButton>
          <RadioButton
            checked={task.bodyLocationValue === IdentifyBodyLocationEnum.RIGHT}
            onChange={() => {
              setIdentifyBodyLocationValue(IdentifyBodyLocationEnum.RIGHT, task);
            }}
            name='bodyLocation'
            value={IdentifyBodyLocationEnum.RIGHT}
            size='medium'
          >
            Right
          </RadioButton>
          <RadioButton
            checked={task.bodyLocationValue === IdentifyBodyLocationEnum.BOTH}
            onChange={() => {
              setIdentifyBodyLocationValue(IdentifyBodyLocationEnum.BOTH, task);
            }}
            name='bodyLocation'
            value={IdentifyBodyLocationEnum.BOTH}
            size='medium'
          >
            Both
          </RadioButton>
        </>
      )}
    </>
  );
};

export default identifyBodyLocationToggle;
