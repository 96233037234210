import React, { useEffect, useState } from "react";
import {
  JobTask,
  setIsDisabled,
  setJobFormData,
  setSelectedTaskName,
} from "src/store/createNewJobSlice";
import Link from "@amzn/meridian/link";
import Box from "@amzn/meridian/box";
import Text from "@amzn/meridian/text";
import Row from "@amzn/meridian/row";
import { useDispatch } from "react-redux";
import { useAppSelector } from "src/store/store";
import Column from "@amzn/meridian/column";
import RequirementForm from "../requirementForm/requirementForm";

const selectedTasksList = () => {
  const dispatch = useDispatch();
  const {
    selectedTaskName,
    taskNames,
    jobFormData,
    requirementFormErrors,
    tasks,
    isDisabled,
  } = useAppSelector((state) => state.createNewJobPage);

  useEffect(() => {
    // set tasks for the form when the multiselect data changes
    if (jobFormData && jobFormData.tasks) {
      dispatch(
        setJobFormData({
          ...jobFormData,
          tasks: taskNames.map((taskName) => {
            const existing = jobFormData.tasks.filter(
              (t) => t?.label === taskName
            );
            return existing.length > 0
              ? existing[0]
              : tasks.filter((t) => t?.label === taskName)[0];
          }),
        })
      );
    }
  }, [taskNames]);

  const selectTask = (taskName: string) => {
    dispatch(setSelectedTaskName(taskName));
    dispatch(setIsDisabled(true));
  };

  return (
    <Row widths={"fill"} spacingInset={"none"}>
      <Box type="fill" overflowY="scroll" spacingInset={"400"} height="300px">
        <Column>
          {[...taskNames].reverse().map((taskName, index) => (
            <Box
              type="outline"
              overflowY="scroll"
              spacingInset={"400"}
              key={index}
            >
              <Row widths={["fill", "fill", "30px"]}>
                <Column spacing={"300"}>
                  {selectedTaskName !== taskName ? (
                    <Text type="b200">Task Name</Text>
                  ) : (
                    <div></div>
                  )}
                  <Text type={selectedTaskName === taskName ? "h100" : "b300"}>
                    {taskName}
                  </Text>
                </Column>

                <Column spacing={"300"}>
                  {selectedTaskName !== taskName &&
                    jobFormData?.tasks.filter((t) => t?.label === taskName)[0]
                      ?.requirementName && (
                      <>
                        <Text type="b200">Task Requirement</Text>
                        <Text>
                          {jobFormData?.tasks.filter(
                            (t) => t?.label === taskName
                          )[0].requirementName || ""}
                        </Text>
                      </>
                    )}
                </Column>

                {selectedTaskName !== taskName && (
                  <Link
                    type="secondary"
                    onClick={() => selectTask(taskName)}
                    disabled={isDisabled}
                  >
                    Edit
                  </Link>
                )}
              </Row>
              <Row>
                {selectedTaskName !== taskName &&
                  requirementFormErrors[taskName]?.requirementName
                    ?.errorMessage && (
                    <Text color="error" type="b200">
                      {
                        requirementFormErrors[taskName]?.requirementName
                          ?.errorMessage
                      }
                    </Text>
                  )}
              </Row>
              {selectedTaskName === taskName && <RequirementForm />}
            </Box>
          ))}
        </Column>
      </Box>
    </Row>
  );
};

export default selectedTasksList;
