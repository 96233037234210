import {
  Site,
  SiteLabel
} from "src/store/siteSlice";

/**
 * If site param is passed in then try to pick that if it is allowed. If not then
 * if preferred sites is configured, try to pick first allowed preferred site,
 * if no preferred site allowed then default to home site if allowed
 * If no home site, then default to first allowed site
 * @param preferredSiteLabels List of sites user has configured as their preferred sites.
 * @param allowedSites List of sites user is allowed to access.
 * @param homeSite String representing home site. This is got from the user's token.
 * @param siteParam String representing site specified in URL if one is specified
 * @returns defaultSite Site representing default site
 */
const getDefaultSite = (preferredSiteLabels: SiteLabel[], allowedSites: Site[], homeSite: String, siteParam: String | undefined) : Site => {
  let defaultSite = getSiteFromAllowedSites(allowedSites, siteParam);
  if(defaultSite !== undefined) {
    return defaultSite;
  }
  defaultSite = getSiteFromAllowedSites(allowedSites, homeSite) ?? allowedSites[0];
  if(preferredSiteLabels.length > 0) {
    defaultSite = getPreferredSiteFromAllowedSites(allowedSites, preferredSiteLabels) ?? defaultSite;
  }
  return defaultSite;
}

const getSiteFromAllowedSites = (allowedSites: Site[], siteName: String | undefined) : Site | undefined => {
  return allowedSites.filter(site => site.site === siteName)[0];
}

const getPreferredSiteFromAllowedSites = (allowedSites: Site[], preferredSiteLabels: SiteLabel[]): Site | undefined => {
  const preferredSiteIds = new Set(preferredSiteLabels.map(siteLabel => siteLabel.value));
  return allowedSites.filter(site => preferredSiteIds.has(site.id))[0];
}

export { getDefaultSite };
  