import React, { useEffect, useState } from "react";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import ButtonGroup, { ButtonOption } from "@amzn/meridian/button-group";
import Select, { SelectOption } from "@amzn/meridian/select";
import { setSiteCapacityFormData } from "src/store/updateSiteCapacitySlice";
import Column from "@amzn/meridian/column";
import Divider from "@amzn/meridian/divider";
import { createSearchRegExp } from "src/utils/searchUtils";
import ShiftList from "../shiftList/shiftList";
import { useDispatch } from "react-redux";
import { useAppSelector } from "src/store/store";

const siteCapacityFormFields = () => {
  const dispatch = useDispatch();
  const { siteCapacityFormData, shifts } = useAppSelector(
    (state) => state.updateSiteCapacityPage
  );
  const [shiftsDropdownSearchQuery, setShiftsDropdownSearchQuery] = useState();
  const searchRegExp = createSearchRegExp(shiftsDropdownSearchQuery);
  const matchedShiftsDropdownValues = shifts
    .map((shift) => ({ label: shift.shiftCode, value: shift.shiftCode }))
    .filter(
      (option) => !shiftsDropdownSearchQuery || searchRegExp.test(option.label)
    );

  useEffect(() => {
    const shiftNames = siteCapacityFormData?.shiftNames || [];
    const myShifts = siteCapacityFormData?.shifts || [];
    if (shiftNames.length !== myShifts.length) {
      dispatch(
        setSiteCapacityFormData({
          ...siteCapacityFormData,
          shifts: shiftNames.map((shiftCode) => {
            const existing = myShifts.filter((s) => s?.shiftCode === shiftCode);
            return existing.length > 0
              ? existing[0]
              : shifts.filter((s) => s.shiftCode === shiftCode)[0];
          }),
        })
      );
    }
  }, [siteCapacityFormData?.shiftNames]);

  return (
    <div>
      <Row widths={["40%", "fill"]} spacingInset={"300 none 300 none"}>
        <Column
          spacing={"400"}
          alignmentVertical="top"
          spacingInset={"300 none none none"}
        >
          <Text tag="label" type="h100" color="secondary">
            Job Activity
          </Text>
          <ButtonGroup
            value={siteCapacityFormData?.active}
            onChange={(active) => {
              dispatch(
                setSiteCapacityFormData({ ...siteCapacityFormData, active })
              );
            }}
          >
            <ButtonOption value="Active">Active</ButtonOption>
            <ButtonOption value="Inactive">Inactive</ButtonOption>
          </ButtonGroup>
        </Column>
      </Row>
      <Divider spacingAfter={"400"} spacingBefore={"400"} />
      <Row widths={["fill"]} spacingInset={"300 none 300 none"}>
        <Column spacing={"400"} alignmentVertical="top">
          <Text tag="label" type="h100" color="secondary">
            Available Shifts
          </Text>
          <Select
            searchQuery={shiftsDropdownSearchQuery}
            onSearch={setShiftsDropdownSearchQuery}
            placeholder="Add shifts available for the job"
            value={siteCapacityFormData?.shiftNames}
            onChange={(shiftNames) => {
              dispatch(
                setSiteCapacityFormData({ ...siteCapacityFormData, shiftNames })
              );
            }}
          >
            {matchedShiftsDropdownValues.map((option) => (
              <SelectOption
                label={option.label}
                value={option.value}
                key={option.value}
              />
            ))}
            {!matchedShiftsDropdownValues.length ? (
              <Column
                alignmentVertical="center"
                spacing="300"
                spacingInset="500"
              >
                <Text alignment="center">No results</Text>
              </Column>
            ) : null}
          </Select>
          <ShiftList />
        </Column>
      </Row>
    </div>
  );
};

export default siteCapacityFormFields;
