import React, { useState } from "react";
import Text from "@amzn/meridian/text";
import Select, { SelectOption } from "@amzn/meridian/select";
import Column from "@amzn/meridian/column";
import { useAppSelector } from "src/store/store";
import { useDispatch } from "react-redux";
import { createSearchRegExp } from "src/utils/searchUtils";
import { setSelectedFilterOptions } from "src/store/placementOverviewPageSlice";

const multipleShiftDropdown = () => {
  const dispatch = useDispatch();

  const { availableFilterOptions, selectedFilterOptions } = useAppSelector((state) => state.placementOverviewPage);
  const allShifts = availableFilterOptions.availableShiftCodes;

  const [searchQuery, setSearchQuery] = useState();
  const searchRegExp = createSearchRegExp(searchQuery);

  const matchedShiftCodeOptions = allShifts.filter((shiftCode: string) => !searchQuery || searchRegExp.test(shiftCode));

  return (
    <Column spacing='xxsmall'>
      <Text>Shift</Text>
      <Select
        size='small'
        value={selectedFilterOptions.shiftCodes}
        onChange={(newValue) => {
          dispatch(setSelectedFilterOptions({ ...selectedFilterOptions, shiftCodes: newValue }));
        }}
        searchQuery={searchQuery}
        onSearch={setSearchQuery}
        placeholder='Enter value...'
      >
        {matchedShiftCodeOptions.map((shiftCode: string) => (
          <SelectOption label={shiftCode} value={shiftCode} key={shiftCode} />
        ))}
        {!matchedShiftCodeOptions.length ? (
          <Column alignmentVertical='center' spacing='300' spacingInset='500'>
            <Text alignment='center'>No results</Text>
          </Column>
        ) : null}
      </Select>
    </Column>
  );
};

export default multipleShiftDropdown;
