import React from "react";
import Button from "@amzn/meridian/button";
import Row from "@amzn/meridian/row";
import { useDispatch } from "react-redux";
import { setPageDisabled } from "src/store/defaultSettingsPageSlice";
import { useAppSelector } from "src/store/store";
import { getUserSites } from "src/store/siteSlice";

interface breadcrumbsProps {
  onResetButtonClick: () => void;
  onSubmit: () => void;
  onCancel: () => void;
}

const pageControls = ({
  onResetButtonClick,
  onSubmit,
  onCancel,
}: breadcrumbsProps) => {
  const dispatch = useDispatch();
  const { pageDisabled } = useAppSelector((state) => state.defaultSettingsPage);

  return (
    <Row className="default-settings__content__button-row">
      {pageDisabled && (
        <Button
          data-cy="default-settings__edit"
          onClick={() => dispatch(setPageDisabled(false))}
        >
          Edit
        </Button>
      )}
      {!pageDisabled && (
        <>
          <Button
            data-cy="default-settings__submit"
            onClick={() => onSubmit()}
            disabled={pageDisabled}
          >
            Submit
          </Button>
          <Button disabled={pageDisabled} onClick={() => onCancel()}>
            Cancel
          </Button>
          <Button disabled={pageDisabled} onClick={() => onResetButtonClick()}>
            Reset
          </Button>
        </>
      )}
    </Row>
  );
};
export default pageControls;
