import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "src/store/store";
import Sheet from "@amzn/meridian/sheet";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Button from "@amzn/meridian/button";
import Icon from "@amzn/meridian/icon";
import closeTokens from "@amzn/meridian-tokens/base/icon/close";
import Heading from "@amzn/meridian/heading";
import {
  setIsAdvancedFilterSheetOpen,
  resetFilters,
  applyFilters,
  setSelectedFilterOptions,
} from "src/store/placementOverviewPageSlice";
import MultipleSiteDropdown from "../multipleSiteDropdown/multipleSiteDropdown";
import MultiplePlacementTypeDropdown from "../multiplePlacementTypeDropdown/multiplePlacementTypeDropdown";
import MultipleShiftDropdown from "../multipleShiftDropdown/multipleShiftDropdown";
import MultipleJobDropwdown from "../multipleJobDropdown/multipleJobDropdown";
import MultipleJobTypeDropwdown from "../multipleJobTypeDropdown/multipleJobTypeDropdown";
import MultipleDaysInCategoryDropdown from "../multipleDaysInCategoryDropdown/multipleDaysInCategoryDropdown";
import StartAndEndDateInput from "../startAndEndDateInput/startAndEndDateInput";

const advancedFilterSheet = () => {
  const { isAdvancedFilterSheetOpen, appliedFilterOptions } = useAppSelector(
    (state) => state.placementOverviewPage
  );
  const dispatch = useDispatch();
  const close = useCallback(() => {
    dispatch(setIsAdvancedFilterSheetOpen(false));
  }, []);

  const reset = useCallback(() => dispatch(resetFilters()), []);

  const apply = useCallback(() => {
    dispatch(applyFilters());
    dispatch(setIsAdvancedFilterSheetOpen(false));
  }, []);

  return (
    <Sheet
      type="overlay"
      side="right"
      open={isAdvancedFilterSheetOpen}
      onClose={close}
    >
      <Column spacing="500" height={250}>
        <Row widths={["grid-10", "grid-2"]}>
          <Heading level={4}>Filter placements</Heading>
          <Button onClick={close} type="icon">
            <Icon tokens={closeTokens}>Close sheet</Icon>
          </Button>
        </Row>
        <Column spacing="medium">
          <MultipleJobDropwdown />
          <MultipleShiftDropdown />
          <MultipleDaysInCategoryDropdown />
          <MultiplePlacementTypeDropdown />
          <MultipleSiteDropdown />
          <MultipleJobTypeDropwdown />
          <StartAndEndDateInput />
          <Row alignmentHorizontal="center">
            <Button type="secondary" onClick={reset}>
              Reset
            </Button>
            <Button onClick={apply}>Apply</Button>
          </Row>
        </Column>
      </Column>
    </Sheet>
  );
};

export default advancedFilterSheet;
