import { FilterOptions } from "src/services/placementOverview/types/filterOptions";
import { TableDataRow } from "src/services/placementOverview/types/tableDataRow";
import moment from "moment";
class PlacementFilterService {
  filterPlacements(placements: TableDataRow[], appliedFilters: FilterOptions): TableDataRow[] {
    const { jobs, shiftCodes, daysInCategory, placementTypes, jobTypes, placementStartDate, placementEndDate, sites } =
      appliedFilters;

    placements = this.filterPlacementsByJobs(placements, jobs);
    placements = this.filterPlacementsByShifts(placements, shiftCodes);
    placements = this.filterPlacementsByDaysInCategory(placements, daysInCategory);
    placements = this.filterPlacementsByPlacementTypes(placements, placementTypes);
    placements = this.filterPlacementsByJobTypes(placements, jobTypes);
    placements = this.filterPlacementsByStartDate(placements, placementStartDate);
    placements = this.filterPlacementsByEndDate(placements, placementEndDate);
    placements = this.filterPlacementsBySite(placements, sites);
    return placements;
  }

  private filterPlacementsByJobs(placements: TableDataRow[], jobsInFilter: string[]): TableDataRow[] {
    if (jobsInFilter.length === 0) {
      return placements;
    }
    const jobSet = new Set(jobsInFilter);
    return placements.filter((placement) => placement.jobsDisplayed && placement.jobs.some((job) => job !== null && jobSet.has(job)));
  }

  private filterPlacementsByShifts(placements: TableDataRow[], shiftsInFilter: string[]): TableDataRow[] {
    if (shiftsInFilter.length === 0) {
      return placements;
    }
    const shiftSet = new Set(shiftsInFilter);
    return placements.filter((placement) =>
      placement.jobsDisplayed && placement.shiftCodes.some((shift) => shift !== null && shiftSet.has(shift))
    );
  }

  private filterPlacementsByDaysInCategory(placements: TableDataRow[], daysInFilter: number[]): TableDataRow[] {
    if (daysInFilter.length === 0) {
      return placements;
    }
    const daysSet = new Set(daysInFilter);
    return placements.filter((placement) => daysSet.has(placement.daysInCategory));
  }

  private filterPlacementsByPlacementTypes(
    placements: TableDataRow[],
    placementTypesInFilter: string[]
  ): TableDataRow[] {
    if (placementTypesInFilter.length === 0) {
      return placements;
    }
    const placementTypesSet = new Set(placementTypesInFilter);
    return placements.filter(
      (placement) => placement.placementType !== null && placementTypesSet.has(placement.placementType)
    );
  }

  private filterPlacementsByJobTypes(placements: TableDataRow[], jobTypesInFilter: string[]): TableDataRow[] {
    if (jobTypesInFilter.length === 0) {
      return placements;
    }
    const jobTypesSet = new Set(jobTypesInFilter);
    return placements.filter((placement) =>
      placement.jobsDisplayed && placement.jobTypes.some((jobType) => jobType !== null && jobTypesSet.has(jobType))
    );
  }

  private filterPlacementsByStartDate(placements: TableDataRow[], startDateInFilter: string): TableDataRow[] {
    if (startDateInFilter === "") {
      return placements;
    }
    // on or after start date
    return placements.filter((placement) => moment(placement.placementStartDate).isSameOrAfter(moment(startDateInFilter)));
  }

  private filterPlacementsByEndDate(placements: TableDataRow[], endDateInFilter: string): TableDataRow[] {
    if (endDateInFilter === "") {
      return placements;
    }
    // on or before end date
    return placements.filter((placement) =>  moment(placement.placementEndDate).isSameOrBefore(moment(endDateInFilter)));
  }

  private filterPlacementsBySite(placements: TableDataRow[], sitesInFilter: string[]): TableDataRow[] {
    if (sitesInFilter.length === 0) {
      return placements;
    }
    const siteSet = new Set(sitesInFilter);
    return placements.filter((placement) => siteSet.has(placement.site));
  }
}

export default new PlacementFilterService();
