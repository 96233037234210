import Button from "@amzn/meridian/button";
import Modal from "@amzn/meridian/modal";
import Row from "@amzn/meridian/row";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import useForm from "src/hooks/useForm";
import {
  downloadTWPA,
  setIsTwpaModalOpen,
  setTwpaFormData,
  setTwpaFormErrors,
} from "src/store/placementDetailsPageSlice/placementDetailsPageSlice";
import {
  DownloadTWPAForm,
  DownloadTWPAPayloadDecisionEnum,
  TemporaryWorkPlacementEnum,
} from "src/store/placementDetailsPageSlice/types";
import { useAppSelector } from "src/store/store";
import GeneralInfo from "./generalInfo/generalInfo";
import RadioFields from "./radioFields/radioFields";

const twpaModal = () => {
  const { twpaFormData, twpaFormErrors, isTwpaModalOpen } = useAppSelector(
    (state) => state.placementDetailsPage
  );
  const dispatch = useDispatch();
  const { validateForm, setFormValues, errors } = useForm<DownloadTWPAForm>({
    validations: {
      department: {
        required: { value: true, message: "Department is required" },
      },
      placementShifts: {
        required: { value: true, message: "Placement shift(s) is required" },
      },
      reportDate: {
        required: { value: true, message: "Report date is required" },
      },
      workPlacementProvide: {
        required: { value: true, message: "Please select one" },
      },
    },
  });
  const tpwaRadioOptions = [
    {
      value: TemporaryWorkPlacementEnum.MATCH_ROLE_NO_MODIFICATIONS,
      label: (
        <>
          Restrictions have been matched within your current role. No
          modifications to the process path are necessary in order to
          accommodate your restrictions.
        </>
      ),
    },
    {
      value: TemporaryWorkPlacementEnum.MATCH_ROLE_WITH_MODIFICATIONS,
      label: (
        <>
          Restrictions have been matched within your current role. The following
          modifications will be made in order to accommodate your restrictions.
        </>
      ),
    },
    {
      value: TemporaryWorkPlacementEnum.MATCH_JOB,
      label: (
        <>
          Restrictions have been matched with a job transfer to a process path
          or temporary light duty role. The following modifications to the
          process path or temporary light duty role will be made in order to
          accommodate your restrictions.
        </>
      ),
    },
    {
      value: TemporaryWorkPlacementEnum.NO_MATCH,
      label: (
        <>
          There are no Temporary Work Placements that meet your restrictions
          available at this time. Associate is responsible for calling DLS to
          initiate a Leave of Absence.
        </>
      ),
    },
  ];

  const requestForInfoRadioOptions = [
    {
      value: DownloadTWPAPayloadDecisionEnum.AGREE,

      label: <>I accept the placement outcome defined above.</>,
    },

    {
      value: DownloadTWPAPayloadDecisionEnum.NOT_AGREE,

      label: (
        <>
          I reject the placement outcome defined above and require further
          consult. Associate is responsible for contacting their Third Party
          Administrator and Disability and Leave Services to initiate a Leave of
          Absence. Contact information can be located at AtoZ portal.
        </>
      ),
    },
  ];

  const handleChange = (key: keyof DownloadTWPAForm, value: any) => {
    dispatch(setTwpaFormData({ ...twpaFormData, [key]: value }));
    dispatch(setTwpaFormErrors({ ...twpaFormErrors, [key]: undefined }));
  };

  useEffect(() => {
    if (twpaFormData) {
      setFormValues(twpaFormData);
    }
  }, [twpaFormData]);

  useEffect(() => {
    dispatch(setTwpaFormErrors(errors));
  }, [errors]);

  useEffect(() => {
    dispatch(setTwpaFormData({}));
  }, [isTwpaModalOpen]);

  return (
    <Modal
      title="Temporary Work Placement Acknowledgement Letter"
      open={isTwpaModalOpen}
      onClose={() => dispatch(setIsTwpaModalOpen(false))}
      width={"680px"}
    >
      <GeneralInfo handleChange={handleChange} />
      <RadioFields
        textInfo={
          <>
            Select which <strong>Temporary Work Placement</strong> will be
            provided.
          </>
        }
        formDataKey="workPlacementProvide"
        radioOptions={tpwaRadioOptions}
        handleChange={handleChange}
      />
      <RadioFields
        textInfo={
          <>
            The <strong>Healthcare Provider Request for Information</strong>{" "}
            form and support documentation have been reviewed with the
            Associate. The Associate:
          </>
        }
        formDataKey="associateDecision"
        radioOptions={requestForInfoRadioOptions}
        handleChange={handleChange}
      />
      <Row widths={["fill", "244px"]}>
        <div />
        <Row>
          <Button onClick={() => dispatch(setIsTwpaModalOpen(false))}>
            Cancel
          </Button>
          <Button
            onClick={() => (validateForm() ? dispatch(downloadTWPA()) : null)}
          >
            Generate PDF
          </Button>
        </Row>
      </Row>
    </Modal>
  );
};

export default twpaModal;
