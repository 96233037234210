import { Site } from "./types";

class SitesCoLocationService {

  getCoLocatedSites(site: Site, coLocationMap: Map<number, Set<string>>) : string {
    let siteCoLocations = "";
    if(!!site.siteCoLocation && coLocationMap.has(site.siteCoLocation.id)) {
      let coLocationSet = coLocationMap.get(site.siteCoLocation.id)!;
      siteCoLocations = [...coLocationSet].join(", ");
    }
    return siteCoLocations;
  }

  generateSiteCoLocationMap(sites: Site[]) : Map<number, Set<string>> {
    return sites
      .filter((site: Site) => !!site.siteCoLocation)
      .reduce((coLocatedSiteMap: Map<number, Set<string>>, currentSite: Site)=> {
        const coLocationId = currentSite.siteCoLocation!.id;
        if(!coLocatedSiteMap.has(coLocationId)) {
          coLocatedSiteMap.set(coLocationId, new Set());
        }
        coLocatedSiteMap.get(coLocationId)!.add(currentSite.site);
        return coLocatedSiteMap;
      }, new Map<number, Set<string>>());
  }
}

export default new SitesCoLocationService();