import React, { useState, useCallback } from "react";
import Modal, { ModalFooter } from "@amzn/meridian/modal";
import Row from "@amzn/meridian/row";
import Button from "@amzn/meridian/button";
import Text from "@amzn/meridian/text";
import Link from "@amzn/meridian/link";

interface confirmModalProps {
  modalTitle?: string;
  modalDescription?: string;
  width?: string;
  open: boolean;
  includeLink?: boolean;
  linkText?: string;
  linkUrl?: string;
  showCancelButton?: boolean;
  setOpen: (newOpen: boolean) => void;
  onConfirm: () => void;
}

const confirmModal = ({
  open,
  setOpen,
  onConfirm,
  modalTitle,
  modalDescription,
  width = "350px",
  includeLink = false,
  linkText = "",
  linkUrl = "",
  showCancelButton = true,
}: confirmModalProps) => {
  const onClose = useCallback(() => setOpen(false), []);
  return (
    <Modal
      title={modalTitle}
      open={open}
      onClose={onClose}
      scrollContainer="viewport"
      closeLabel="Close"
      describedById="modal-description"
      width={width}
    >
      <Text id="modal-description">
        {modalDescription}
        {includeLink && (
          <>
            <br />
            <Link target="_blank" rel="noopener noreferrer" href={linkUrl}>
              {linkText}
            </Link>
          </>
        )}
      </Text>
      <ModalFooter>
        <Row alignmentHorizontal="right" widths="fit">
          {showCancelButton && (
            <Button data-cy="confirm-modal__cancel" type="secondary" size="small" onClick={onClose}>
              Cancel
            </Button>
          )}
          <Button
            type="primary"
            size="small"
            data-cy="confirm-modal__confirm"
            onClick={() => {
              setOpen(false);
              onConfirm();
            }}
          >
            Confirm
          </Button>
        </Row>
      </ModalFooter>
    </Modal>
  );
};

export default confirmModal;
