import React, { useState, useEffect } from "react";
import BreadCrumbs from "src/components/shared/breadcrumbs/breadcrumbs";
import TabGroup from "./tabGroup/tabGroup";
import Box from "@amzn/meridian/box";
import "./siteManagement.scss";
import UpdateShifts from "./updateShifts/updateShifts";
import EmailDistros from "./updateEmailDistributions/emailDistros";
import { useLocation, useNavigate } from "react-router";
import { isPathValid, findCurrentTabToHighlight } from "./tabAndPathUtils";
import UpdateWebHooks from "./updateWebHooks/updateWebHooks";
import UpdateSiteCapacity from "./updateSiteCapacity/updateSiteCapacity";

const siteManagement = () => {
  let navigate = useNavigate();
  const { pathname } = useLocation();
  const [currentTab, setCurrentTab] = useState("update-site-capacity");

  useEffect(() => {
    if (!isPathValid(pathname)) {
      navigate(`/siteManagement/${currentTab}`);
    } else {
      setCurrentTab(findCurrentTabToHighlight(pathname));
    }
  }, [pathname]);

  const onTabChange = (tabVal: string) => {
    navigate("/siteManagement/" + tabVal);
  };

  return (
    <div className="site-management">
      <BreadCrumbs
        breadcrumbList={[
          { label: "Return To Work 2.0", url: "/" },
          { label: "Site Management", url: "/siteManagement" },
        ]}
      />

      <div className="site-management__tab-group">
        <TabGroup currentTab={currentTab} setCurrentTab={onTabChange} />
      </div>

      <Box
        type="outline"
        spacingInset="200 500 400 500"
        className="site-management__content"
      >
        {currentTab === "update-shifts" && <UpdateShifts />}
        {currentTab === "update-default-emails" && <EmailDistros />}
        {currentTab === "update-webhooks" && <UpdateWebHooks />}
        {currentTab === "update-site-capacity" && <UpdateSiteCapacity />}
      </Box>
    </div>
  );
};

export default siteManagement;
