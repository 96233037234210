export interface DownloadTWPAPayload {
  accommodationId: number;
  reportDate: string;
  department: string;
  notes: string;
  jobModifications: string;
  workPlacementProvide: string;
  isFullDuty: boolean;
  associateDecision: string;
  placementShift: string;
}

export interface DownloadOomdPayload {
  accommodationId: number;
  injury: string;
  reportDate: string;
  reportDateToAMCARE: string;
  additionalNotes: string;
}

export enum YesNoEnum {
  Yes = "Yes",
  No = "No",
}

export interface JmrPayload {
  location: string;
  siteId: number;
  shiftLength: number;
  taskRestrictions: {
    taskId: number;
    measureUnit: string;
    restrictedValue: number | YesNoEnum; // number when measureUnit is hour and YesNoEnum when measureUnit is yes/no
  }[];
}

export interface JmrWorkRestriction {
  taskId: number;
  hoursRestricted: string;
  taskName: string;
}

export interface AccommodationFields {
  associateFullName: string;
  requesterLogin: string;
  startAt: string;
  endAt: string;
  managerFullName: string;
  managerAlias: string;
  employeeId: string;
  personId: string;
  job: string;
  login: string;
  fc: string;
  siteId: number;
  accommodationType: string;
  externalCaseId: string;
  weeklyLimitedHours: string;
  modifiedShiftLength: number;
  normalShiftLength: number;
  canWorkNormalShiftHours: boolean;
  canWorkMoreThan40Hours: boolean;
  shiftCode: string;
  workRestrictions: JmrWorkRestriction[];
  allocatedJobShifts: {
    jobId: number;
    shiftId?: number;
    jobName: string;
    shiftName: undefined | string;
    allocatedHours: number;
  }[];
}

export interface UpdateAccommodationFields extends AccommodationFields {
  id: number;
  accommodationStatus: {
    id: number;
    status: string;
  };
}

export interface AccommodationPayload {
  accommodation: AccommodationFields;
  emailRecipients: string;
}

export interface UpdateAccommodationPayload extends AccommodationPayload {
  accommodation: UpdateAccommodationFields;
}

export interface UpdateAssociateInfoPayload {
  accommodationId: number;
  login: string;
  job: string;
  employeeId: string;
  personId: string;
  shiftCode: string;
  externalCaseId: string;
  caseSource: string;
  fullName: string;
};