import Column from "@amzn/meridian/column";
import DatePicker from "@amzn/meridian/date-picker";
import Divider from "@amzn/meridian/divider";
import Input from "@amzn/meridian/input";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import React from "react";
import { useDispatch } from "react-redux";
import { DownloadTWPAForm } from "src/store/placementDetailsPageSlice/types";
import { useAppSelector } from "src/store/store";

interface generalInfoProps {
  handleChange: (key: keyof DownloadTWPAForm, value: any) => void;
}
const generalInfo = ({ handleChange }: generalInfoProps) => {
  const dispatch = useDispatch();
  const { twpaFormData, twpaFormErrors } = useAppSelector(
    (state) => state.placementDetailsPage
  );

  return (
    <div>
      <Text>
        Fill out the following information. This will be used to create a PDF
        version of the Associate’s Temporary Work Placement Acknowledgement
        letter.
      </Text>
      <Row widths={["fill", "fill"]} spacingInset={"400 none none"}>
        <Column spacing={"200"} spacingInset={"200"}>
          <Text tag="label" type="b300" color="primary">
            Department
          </Text>
          <Input
            placeholder="Associate's assigned department"
            value={twpaFormData.department}
            onChange={(department) => handleChange("department", department)}
            error={twpaFormErrors.department?.error}
            errorMessage={twpaFormErrors.department?.errorMessage}
          />
        </Column>
        <Column spacing={"200"} spacingInset={"200"}>
          <Text tag="label" type="b300" color="primary">
            Placement Shift(s)
          </Text>
          <Input
            placeholder="Associate's placeholder shift"
            value={twpaFormData.placementShifts}
            onChange={(placementShifts) =>
              handleChange("placementShifts", placementShifts)
            }
            error={twpaFormErrors.placementShifts?.error}
            errorMessage={twpaFormErrors.placementShifts?.errorMessage}
          />
        </Column>
      </Row>
      <Row widths={["fill"]}>
        <Column spacing={"200"} spacingInset={"200"}>
          <Text tag="label" type="b300" color="primary">
            Additional Notes
          </Text>
          <Input
            placeholder="Enter addtional notes"
            value={twpaFormData.notes}
            onChange={(notes) => handleChange("notes", notes)}
            error={twpaFormErrors.notes?.error}
            errorMessage={twpaFormErrors.notes?.errorMessage}
          />
        </Column>
      </Row>
      <Row widths={["fill"]}>
        <Column spacing={"200"} spacingInset={"200"}>
          <Text tag="label" type="b300" color="primary">
            Job Modifications
          </Text>
          <Input
            placeholder="Enter job modifications"
            value={twpaFormData.jobModifications}
            onChange={(jobModifications) =>
              handleChange("jobModifications", jobModifications)
            }
            error={twpaFormErrors.jobModifications?.error}
            errorMessage={twpaFormErrors.jobModifications?.errorMessage}
          />
        </Column>
      </Row>
      <Row widths={["50%"]}>
        <Column spacing={"200"} spacingInset={"200"}>
          <Text tag="label" type="b300" color="primary">
            Report Date
          </Text>
          <DatePicker
            value={twpaFormData.reportDate}
            onChange={(reportDate) => handleChange("reportDate", reportDate)}
            placeholder={"MM/DD/YYYY"}
            error={twpaFormErrors.reportDate?.error}
            errorMessage={twpaFormErrors.reportDate?.errorMessage}
          />
        </Column>
      </Row>
      <Divider spacingBefore={"400"} spacingAfter={"400"} />
    </div>
  );
};

export default generalInfo;
