import React, { useState } from "react";
import Text from "@amzn/meridian/text";
import Select, { SelectOption } from "@amzn/meridian/select";
import Column from "@amzn/meridian/column";
import { useAppSelector } from "src/store/store";
import { useDispatch } from "react-redux";
import { createSearchRegExp } from "src/utils/searchUtils";
import { setSelectedFilterOptions } from "src/store/placementOverviewPageSlice";

const multipleSiteDropdown = () => {
  const dispatch = useDispatch();

  const { availableFilterOptions, selectedFilterOptions, isUserDaliUser } =
    useAppSelector((state) => state.placementOverviewPage);
  const allSites = availableFilterOptions.availableSites;

  const [searchQuery, setSearchQuery] = useState();
  const searchRegExp = createSearchRegExp(searchQuery);

  const matchedSiteOptions = allSites.filter(
    (siteName: string) => !searchQuery || searchRegExp.test(siteName)
  );

  return (
    <>
      {isUserDaliUser && (
        <Column spacing="xxsmall">
          <Text>Site</Text>
          <Select
            size="small"
            value={selectedFilterOptions.sites}
            onChange={(newValue) => {
              dispatch(
                setSelectedFilterOptions({
                  ...selectedFilterOptions,
                  sites: newValue,
                })
              );
            }}
            searchQuery={searchQuery}
            onSearch={setSearchQuery}
            placeholder="Enter value..."
          >
            {matchedSiteOptions.map((siteName: string) => (
              <SelectOption label={siteName} value={siteName} key={siteName} />
            ))}
            {!matchedSiteOptions.length ? (
              <Column
                alignmentVertical="center"
                spacing="300"
                spacingInset="500"
              >
                <Text alignment="center">No results</Text>
              </Column>
            ) : null}
          </Select>
        </Column>
      )}
    </>
  );
};

export default multipleSiteDropdown;
