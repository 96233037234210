import React, { useState } from "react";
import Tab, { TabGroup } from "@amzn/meridian/tab";

interface tabGroupProps {
  currentTab: string;
  setCurrentTab: (newTab: string) => void;
}

const tabGroup = ({ currentTab, setCurrentTab }: tabGroupProps) => {
  return (
    <TabGroup value={currentTab} onChange={setCurrentTab}>
      <Tab value="job-config">Job Configuration</Tab>
      <Tab value="precision-upload">Precision Upload</Tab>
    </TabGroup>
  );
};

export default tabGroup;
