import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import webHookService, { WebHookPayload } from "src/services/webHookService";

export type WebHookState = {
  pageDisabled: boolean;
  isLoading: boolean;
  notifyNewForNonWorkRelated: boolean;
  notifyNewForWorkRelated: boolean;
  urlForNonWorkRelated: string;
  urlForWorkRelated: string;
  urlInputLength: number;
};

const initialState: WebHookState = {
  pageDisabled: true,
  isLoading: false,
  notifyNewForNonWorkRelated: false,
  notifyNewForWorkRelated: false,
  urlForNonWorkRelated: "",
  urlForWorkRelated: "",
  urlInputLength: 255,
};

/**
 * Get site webhook settings
 * @returns {Object}
 */
export const getWebHookSettings = createAsyncThunk(
  "sites/:siteId/webhookSetting",
  async (siteId: number) => {
    const { data } = await webHookService.getWebHookSettings(siteId);
    return data;
  }
);

/**
 * Post webhook settings
 * @returns {Object}
 */
export const postWebHookSettings = createAsyncThunk(
  "/sites/webhookSetting",
  async (siteId: number, { getState }) => {
    const state: any = getState();
    const payload = {
      siteId: siteId,
      notifyNewForNonWorkRelated:
        state.updateWebHookSlice.notifyNewForNonWorkRelated,
      notifyNewForWorkRelated: state.updateWebHookSlice.notifyNewForWorkRelated,
      urlForNonWorkRelated: state.updateWebHookSlice.urlForNonWorkRelated,
      urlForWorkRelated: state.updateWebHookSlice.urlForWorkRelated,
    };

    const { data } = await webHookService.postWebHookSettings(payload);
    return data;
  }
);

/** Webhook Page Slice */
const { reducer, actions } = createSlice({
  name: "updateWebHookSlice",
  initialState,
  reducers: {
    setPageDisabled: (state, action) => {
      state.pageDisabled = action.payload;
    },
    resetState: (state) => {
      state = initialState;
    },
    setNotifyNewForWorkRelated: (state, action) => {
      state.notifyNewForWorkRelated = action.payload;
    },
    setNotifyNewForNonWorkRelated: (state, action) => {
      state.notifyNewForNonWorkRelated = action.payload;
    },
    setUrlForWorkRelated: (state, action) => {
      if(action.payload.length <= state.urlInputLength) {
        state.urlForWorkRelated = action.payload;
      }
    },
    setUrlForNonWorkRelated: (state, action) => {
      if(action.payload.length <= state.urlInputLength) {
        state.urlForNonWorkRelated = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getWebHookSettings.fulfilled, (state, { payload }) => {
      state.notifyNewForNonWorkRelated = payload.notifyNewForNonWorkRelated ?? "";
      state.notifyNewForWorkRelated = payload.notifyNewForWorkRelated ?? "";
      state.urlForWorkRelated = payload.urlForWorkRelated ?? "";
      state.urlForNonWorkRelated = payload.urlForNonWorkRelated ?? "";
    });
  },
});

export const {
  setPageDisabled,
  setNotifyNewForWorkRelated,
  setNotifyNewForNonWorkRelated,
  setUrlForWorkRelated,
  setUrlForNonWorkRelated,
} = actions;

export default reducer;
