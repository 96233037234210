enum ENV {
  PROD = "prod.na.rtw.whs.amazon.dev",
  GAMMA = "gamma.na.rtw.whs.amazon.dev",
  BETA = "beta.na.rtw.whs.amazon.dev",
  LOCAL = "localhost",
}

export const isProd = (): boolean => window.location.host.includes(ENV.PROD);
export const isGamma = (): boolean => window.location.host.includes(ENV.GAMMA);
export const isBeta = (): boolean => window.location.host.includes(ENV.BETA);
export const isLocal = (): boolean => window.location.host.includes(ENV.LOCAL);
