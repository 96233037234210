import React, { useEffect } from "react";
import Row from "@amzn/meridian/row";
import Button from "@amzn/meridian/button";
import Text from "@amzn/meridian/text";
import Checkbox from "@amzn/meridian/checkbox";
import { setSelectedTab } from "src/store/adminConfigPageSlice";
import { useDispatch } from "react-redux";
import {
  getRegionTasksById,
  setSelectedRegion,
  updateRegionTasks,
  updateRestrictionForm,
} from "src/store/regionTasksPageSlice";
import { useAppSelector } from "src/store/store";
import Input from "@amzn/meridian/input";
import FormTasksTable from "./formTasksTable/formTasksTable";

const ROW_HEIGHT = "50px";

const INPUT_FIELD_LAYOUT = ["grid-2", "grid-2"];

const formTaskConfiguration = () => {
  const dispatch = useDispatch();
  const { selectedRegion, selectedRegionTasks } = useAppSelector(
    (state) => state.regionTasksConfigPage
  );

  useEffect(() => {
    dispatch(getRegionTasksById(selectedRegion.id));
  }, []);
  return (
    <div className="view-and-edit-forms">
      <Row widths={["grid-11", "grid-1"]} height={ROW_HEIGHT}>
        <div>
          <Button
            size="small"
            onClick={() => {
              dispatch(setSelectedTab("form-config"));
            }}
          >
            Back
          </Button>
        </div>
        <div>
          <Button
            size="small"
            onClick={() => {
              dispatch(updateRestrictionForm(selectedRegion));
              dispatch(updateRegionTasks(selectedRegionTasks));
            }}
          >
            Save
          </Button>
        </div>
      </Row>
      <Row widths={INPUT_FIELD_LAYOUT} height={ROW_HEIGHT}>
        <Text>Restriction Form Name</Text>
        <Input
          type="text"
          placeholder="Enter form name..."
          value={selectedRegion.formName}
          onChange={(name) => {
            dispatch(setSelectedRegion({ ...selectedRegion, formName: name }));
          }}
        />
      </Row>
      <Row widths={INPUT_FIELD_LAYOUT} height={ROW_HEIGHT}>
        <Text>Country</Text>
        <Text>{selectedRegion.country}</Text>
      </Row>
      <Row widths={INPUT_FIELD_LAYOUT} height={ROW_HEIGHT}>
        <Text>Region/State/Province</Text>
        <Text>{selectedRegion.name}</Text>
      </Row>
      <Row widths={INPUT_FIELD_LAYOUT} height={ROW_HEIGHT}>
        <Text>Description</Text>
        <Input
          type="text"
          placeholder="Enter description..."
          value={selectedRegion.description}
          onChange={(description) =>
            dispatch(
              setSelectedRegion({ ...selectedRegion, description: description })
            )
          }
        />
      </Row>
      <Row widths={INPUT_FIELD_LAYOUT} height={ROW_HEIGHT}>
        <Text>is Active?</Text>
        <Checkbox
          checked={selectedRegion.isActive}
          onChange={(isActive) =>
            dispatch(
              setSelectedRegion({ ...selectedRegion, isActive: isActive })
            )
          }
        />
      </Row>
      <FormTasksTable />
    </div>
  );
};

export default formTaskConfiguration;
