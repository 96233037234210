import apiClient from "src/utils/apiClient";
import store from "src/store";
import { createToast } from "src/store/toastsSlice";
import { TOAST_TIMEOUT } from "src/config/Toast";
import { EmailSettingsPayload } from "./types/emailSettingsPayload";

class EmailDistributionService {
  /**
   * Get email settings of a site
   * @param siteId site id for which email settings need to be fetched
   * @returns email settings of site
   */
  getForSite(siteId: number) {
    return apiClient.get(`/sites/${siteId}/emailSetting`);
  }

  /**
   * Save email settings of a site
   * @param emailSettingsPayload payload for email settings
   * @returns saved email settings of site
   */
  async save(emailSettingsPayload: EmailSettingsPayload) {
    const res = await apiClient.post(
      `/sites/emailSetting`,
      emailSettingsPayload
    );
    if (res) {
      store.dispatch(
        createToast({
          type: "success",
          message: "Email settings updated successfully.",
          timeout: TOAST_TIMEOUT,
        })
      );
    }
    return res;
  }
}
export default new EmailDistributionService();
