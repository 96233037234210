import React, { useEffect } from "react";
import TopOptions from "./topOptions/topOptions";
import ShiftsTable from "./shiftsTable/shiftsTable";
import ShiftsModal from "./shiftsModal/shiftModal";
import "./updateShifts.scss";
import { useAppSelector } from "src/store/store";
import LoadingSpinner from "src/components/shared/loadingSpinner/loadingSpinner";
import { useDispatch } from "react-redux";
import { getUser } from "src/store/userSlice";
import { getShifts, setSearchField, setPerPage, setCurrentPage } from "src/store/updateShiftsPageSlice";
import Text from "@amzn/meridian/text";
import Heading from "@amzn/meridian/heading";
import PaginationWithDropdown from "src/components/shared/paginationWithDropdown/paginationWithDropdown";

const updateShifts = () => {
  const dispatch = useDispatch();
  const { isLoading, currentPage, totalPages, perPage } = useAppSelector(
    (state) => state.updateShiftsPage
  );
  const { preferredSite } = useAppSelector((state) => state.user);
  const { userLoading } = useAppSelector((state) => state.user);

  useEffect(() => {
    if (userLoading === "fulfilled" && preferredSite !== 0) {
      dispatch(getShifts());
    }
    return () => {
      dispatch(setSearchField(""));
    };
  }, [userLoading, preferredSite]);

  return (
    <div className="update-shifts">
      {!isLoading ? (
        <>
          <Heading
            level={1}
            type="h500"
            className="update-shifts__page-heading"
          >
            Update Shifts
          </Heading>
          <Text>Here you view, and edit shifts available at your site</Text>
          <TopOptions />
          <ShiftsTable />
          <PaginationWithDropdown
            currentPage={currentPage}
            totalPages={totalPages}
            perPage={perPage}
            setPerPage={(newPerPage) => dispatch(setPerPage(newPerPage))}
            setCurrentPage={(newPage) => dispatch(setCurrentPage(newPage))}
          />
          <ShiftsModal />
        </>
      ) : (
        <LoadingSpinner />
      )}
    </div>
  );
};

export default updateShifts;
