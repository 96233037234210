import React, { useState } from "react";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import Checkbox from "@amzn/meridian/checkbox";
import Input from "@amzn/meridian/input";
import Select, { SelectOption } from "@amzn/meridian/select";
import { useAppSelector } from "src/store/store";
import { TableDataRow } from "src/store/taskConfigPageSlice";
import _ from "lodash";
import { ErrorRecord } from "src/hooks/useForm";
import { createSearchRegExp } from "src/utils/searchUtils";

interface taskFormFieldsProps {
  formData: TableDataRow;
  errors: ErrorRecord<TableDataRow>;
  handleChange: (
    key: keyof TableDataRow,
    value: any,
    sanitizeFn?: (value: any) => any
  ) => void;
}
const taskFormFields = ({
  formData,
  errors,
  handleChange,
}: taskFormFieldsProps) => {
  const { regions, unitsOfMeasure } = useAppSelector(
    (state) => state.taskConfigPage
  );

  const [searchQuery, setSearchQuery] = useState();
  const searchRegExp = createSearchRegExp(searchQuery);
  const matchedRegionOptions = regions
    .map((region) => ({ label: region.name, value: region.name }))
    .filter((option) => !searchQuery || searchRegExp.test(option.label));

  return (
    <div>
      <Row widths="fill" spacingInset={"400 none"}>
        <Text>Task Name</Text>
        <Input
          type="text"
          placeholder="Enter task name..."
          value={formData.label}
          onChange={(label) => {
            handleChange("label", label);
          }}
          error={errors.label?.error}
          errorMessage={errors.label?.errorMessage}
        />
      </Row>
      <Row widths="fill" spacingInset={"400 none"}>
        <Text>Region/State/Province</Text>
        <Select
          searchQuery={searchQuery}
          onSearch={setSearchQuery}
          placeholder="Select region..."
          value={formData.regions}
          onChange={(regions) => {
            handleChange("regions", regions);
          }}
          error={errors.regions?.error}
          errorMessage={errors.regions?.errorMessage}
        >
          {matchedRegionOptions.map((option) => (
            <SelectOption
              label={option.label}
              value={option.value}
              key={option.value}
            />
          ))}
        </Select>
      </Row>
      <Row widths="fill" spacingInset={"400 none"}>
        <Text>Identify Body Location (L,R,B)?</Text>
        <Checkbox
          checked={formData.identifyBodyLocation === true}
          onChange={(identifyBodyLocation) => {
            handleChange(
              "identifyBodyLocation",
              identifyBodyLocation,
              (identifyBodyLocation) => (identifyBodyLocation ? true : false)
            );
          }}
        />
      </Row>
      <Row widths="fill" spacingInset={"400 none"}>
        <Text>Unit of Measure</Text>
        <Select
          placeholder="Select unit of measure..."
          value={formData.isMeasuredBy}
          onChange={(isMeasuredBy) => {
            handleChange("isMeasuredBy", isMeasuredBy);
          }}
          error={errors.isMeasuredBy?.error}
          errorMessage={errors.isMeasuredBy?.errorMessage}
        >
          {unitsOfMeasure.map((option) => (
            <SelectOption
              label={option.name}
              value={option.name}
              key={option.id}
            />
          ))}
        </Select>
      </Row>
      <Row widths="fill" spacingInset={"400 none"}>
        <Text>Description</Text>
        <Input
          placeholder="Enter description..."
          value={formData.description}
          onChange={(description) => {
            handleChange("description", description);
          }}
          error={errors.description?.error}
          errorMessage={errors.description?.errorMessage}
          type="text"
        />
      </Row>
    </div>
  );
};

export default taskFormFields;
