import { TOAST_TIMEOUT } from "src/config/Toast";
import store from "src/store";
import { getPlacementDetails } from "src/store/placementDetailsPageSlice/placementDetailsPageSlice";
import { createToast } from "src/store/toastsSlice";
import apiClient from "../utils/apiClient";

export interface UpdatePlacementEndDate {
  accommodationId: number;
  endDate: number;
}

export interface InitiatePlacementTransfer {
  accommodationId: number;
  originSiteId: number;
  destinationSiteId: number;
  emailRecipients: string;
}

export interface UpdateStatusPayload {
  accommodationId: number;
  accommodationStatusId: number;
  decisionByLogin?: string;
}

export interface UpdateEmailStatus {
  accommodationId: number;
  emailType: string;
  emailRecipient: string;
  placementDetails: string;
  dlsStatus: string;
  decision: string;
  actionAreaManager: string;
  firstDayReturn: string;
  lastDayWorked: string;
  firstDayAbsent: string;
  permanentRestrictionDate: string;
}

class PlacementDetailsService {
  /**
   * get accommodation status
   * @returns {[Object]}
   */
  async getAccommodationStatus(siteId: number) {
    return await apiClient.get(
      `/accommodation/site/${siteId}/accommodationStatus`
    );
  }

  async getAccommodationStatusForAccommodation(accommodationId: number) {
    return await apiClient.get(`/accommodation/${accommodationId}/accommodationStatus`)
  }

  /**
   * get s3 keys for accommodation
   */
  async getAccommodationKeys(accommodationId: number) {
    return await apiClient.get(`/accommodation/keys/${accommodationId}`);
  }

  /**
   * update accommodation status
   * @returns {Object}
   */
  async updateAccommodationStatus(payload: UpdateStatusPayload) {
    const res = await apiClient.put(
      `/accommodation/updateAccommodationStatus`,
      payload
    );
    if (res) {
      store.dispatch(
        createToast({
          type: "success",
          message: "Updated accommodation status successfully",
          timeout: TOAST_TIMEOUT,
        })
      );
    }
    return res;
  }

  async updateAccommodationStatusV2(payload: UpdateStatusPayload) {
    const res = await apiClient.put(
      `/accommodation/updateAccommodationStatus/v2`,
      payload
    );
    if (res) {
      store.dispatch(
        createToast({
          type: "success",
          message: "Updated accommodation status successfully",
          timeout: TOAST_TIMEOUT,
        })
      );
    }
    return res;
  }

  async updateEmailStatus(payload: UpdateEmailStatus) {
    const res = await apiClient.post(
      `/accommodation/emailStatusUpdate`,
      payload
    );
    if (res) {
      store.dispatch(
        createToast({
          type: "success",
          message: "Send status email successfully",
          timeout: TOAST_TIMEOUT,
        })
      );
    }
    return res;
  }

  async updatePlacementEndDate(payload: UpdatePlacementEndDate) {
    const res = await apiClient.put(
      `/accommodation/updateAccommodationEndDate`,
      payload
    );
    if (res) {
      store.dispatch(
        createToast({
          type: "success",
          message: "Updated placement end date successfully",
          timeout: TOAST_TIMEOUT,
        })
      );
      store.dispatch(getPlacementDetails(payload.accommodationId));
    }
    return res;
  }

  async initiatePlacementTransfer(payload: InitiatePlacementTransfer) {
    const res = await apiClient.put(
      `/jobMatchingReports/initiateTransfer`,
      payload
    );

    if (res) {
      store.dispatch(
        createToast({
          type: "success",
          message: "Transfer has been initiated. You no longer have access to this placement.",
          timeout: TOAST_TIMEOUT,
        })
      );
    }

    return res;
  }
}

export default new PlacementDetailsService();
