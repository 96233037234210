import React from "react";
import * as LDAP from "src/config/LDAP";
import { useAppSelector } from "src/store/store";
import { ALREADY_LINKED } from "./constants";
import SelectableCase from "./selectableCase";
import UnselectableCase from "./unselectableCase";
import { CaseSelectionProps } from "./caseSelectionProps";

const CaseSelection = ({
  austinCase,
  handleChange,
  selectedCaseId,
  currentAccommodationId,
  unselectableOverride,
}: CaseSelectionProps) => {
  const { ldap } = useAppSelector((state) => state.user);
  const isAdmin = ldap.includes(LDAP.ADMIN);

  const isSelectable = (selectableByAll: boolean, alreadyLinked: boolean) => {
    if (unselectableOverride) {
      return false;
    }
    let selectable = selectableByAll;
    if (!selectableByAll) {
      selectable =
        isCaseAlreadyLinkedAndIsUserAdmin(alreadyLinked) ||
        isCurrentAccommodationAndLinkedAccommodationSame(alreadyLinked);
    }
    return selectable;
  };

  const isCaseAlreadyLinkedAndIsUserAdmin = (alreadyLinked: boolean) => {
    return isAdmin && alreadyLinked;
  };

  const isCurrentAccommodationAndLinkedAccommodationSame = (alreadyLinked: boolean) => {
    return alreadyLinked && currentAccommodationId === austinCase?.accommodationId;
  };

  return (
    <>
      {isSelectable(
        austinCase.selectable,
        austinCase.reasons.some((reason) => reason.includes(ALREADY_LINKED))
      ) ? (
        <SelectableCase
          austinCase={austinCase}
          handleChange={handleChange}
          selectedCaseId={selectedCaseId}
          currentAccommodationId={currentAccommodationId}
        />
      ) : (
        <UnselectableCase austinCase={austinCase} selectedCaseId={selectedCaseId} />
      )}
    </>
  );
};
export default CaseSelection;
