import apiClient from "src/utils/apiClient";
import { Site } from "src/store/siteSlice";
import store from "src/store";
import { createToast } from "src/store/toastsSlice";
import { TOAST_TIMEOUT } from "src/config/Toast";

class UserSiteConfigService {
  /**
   * get all sites
   * @returns {Object}
   */
  getAllowedSites() {
    return apiClient.get("/sites/allowed");
  }

  /**
   * get users site configs
   * @returns {Object}
   */
  getUserSiteConfig(alias: string) {
    return apiClient.get(`/userSiteConfig/login/${alias}`);
  }

  /**
   * update user sites config
   * @returns {Object}
   */
  updateUserSites(alias: string, sites: Site[]) {
    const response = apiClient.post("/userSiteConfig", {
      login: alias,
      defaultSites: sites,
    });
    store.dispatch(
      createToast({
        type: "success",
        message: "Updated settings successfully",
        timeout: TOAST_TIMEOUT,
      })
    );
    return response;
  }
}
export default new UserSiteConfigService();
