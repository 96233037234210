import { TOAST_TIMEOUT } from "src/config/Toast";
import store from "src/store";
import { createToast } from "src/store/toastsSlice";
import apiClient from "src/utils/apiClient";

export interface JobListItem {
  id: number | undefined;
  name: string;
  region: string;
  jobType: string;
  siteType: string | undefined;
  active: boolean;
}

export interface JobRequirement {
  id: number | undefined;
  task: {
    id: number;
    label?: string;
    measureUnit?: {
      id: number;
      name: string;
    };
  };
  bodyLocation: string;
  value: string;
}

export interface JobPayload {
  id: number | undefined;
  name: string;
  active?: boolean;
  region: {
    id: number;
    name?: string;
  };
  jobType: {
    id: number;
    name?: string;
  };
  description?: string;
  siteSpecific: boolean;
  siteType: {
    id: number;
    label?: string;
  };
  specificSites: {
    id: number;
    site?: string;
  }[];
  jobRequirements: JobRequirement[];
}

class JobsService {
  /**
   * Get jobs
   */
  async getJobs() {
    return await apiClient.get("/jobs");
  }

  /**
   * Get job types
   */
  async getJobTypes() {
    return await apiClient.get("/jobs/types");
  }

  /**
   * Get job by id
   */
  async getJobById(jobId: number) {
    return await apiClient.get(`/jobs/${jobId}`);
  }

  /**
   * Get applicable sites for job
   */
  async getApplicableSitesForJob(jobId: number) {
    return await apiClient.get(`/jobs/${jobId}/applicableSites`);
  }

  /**
   * Create or update task (create if id not provided)
   * @returns {Object}
   */
  async createOrUpdateJob(data: JobPayload) {
    const res = await apiClient.post("/jobs", data);
    if (res) {
      store.dispatch(
        createToast({
          type: "success",
          message: data.id
            ? "Updated job successfully"
            : "Created job successfully",
          timeout: TOAST_TIMEOUT,
        })
      );
    }
    return res;
  }
}

export default new JobsService();
