import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Masthead, { MastheadTitle, MastheadLink } from "@amzn/meridian/masthead";
import Theme from "@amzn/meridian/theme";
import blueDarkTokens from "@amzn/meridian-tokens/theme/blue-dark";
import logo from "src/images/logo.png";
import { useAppSelector } from "src/store/store";
import * as LDAP from "src/config/LDAP";
import Select, { SelectOption, SelectOptionGroup } from "@amzn/meridian/select";
import { createSearchRegExp } from "src/utils/searchUtils";
import { useDispatch } from "react-redux";
import { setPreferredSite } from "src/store/userSlice";
import {
  getAllowedAndPreferredSites,
  NO_SITE,
  setSelectedSite,
  Site,
  SiteLabel
} from "src/store/siteSlice";
import { getDefaultSite } from "src/components/shared/header/getDefaultSite";
import ProfileMenu from "src/components/shared/header/profileMenu/profileMenu";
import { removeSpecialCharactersFromString } from "src/utils/helpers";

const themeTokens = {
  ...blueDarkTokens,
  themeBackgroundPrimaryDefault: "#03384B",
};

const header = () => {
  const { userLoading, alias, ldap, siteLocation, preferredSite, hasPermission } =
    useAppSelector((state) => state.user);
  const navigate = useNavigate();
  const { userSites, allowedSites, isSiteSelectionDisabled, selectedSite, preferredSitesAndAllowedSitesFetched, hasAllowedSite } = useAppSelector(
    (state) => state.sites
  );

  const [searchQuery, setSearchQuery] = useState();
  const searchRegExp = createSearchRegExp(searchQuery);
  const dispatch = useDispatch();

  const location = useLocation();

  const isAdmin = ldap.includes(LDAP.ADMIN);
  const isSMES = ldap.includes(LDAP.SMES);
  const isFCLeaders = ldap.includes(LDAP.FC_LEADERS);
  const isDev = ldap.includes(LDAP.DEV);

  let filteredPreferredSites =
    userSites.length >= 1
      ? userSites
          .map((site) => ({ label: site.site, value: site.id }))
          .filter((option) => !searchQuery || searchRegExp.test(option.label))
          .sort((a, b) => a.label.localeCompare(b.label))
      : [];
  let filteredAllowedSites =
    allowedSites.length >= 1
      ? allowedSites.filter(
          (option) => !searchQuery || searchRegExp.test(option.site)
        )
      : [];

  let filterNotPreferredSitesFromAllowedSites = (
    filteredPreferredSites: SiteLabel[],
    allowedSites: Site[]
  ) => {
    let preferredSiteIdsSet = new Set(
      filteredPreferredSites.map((site: { value: number }) => site.value)
    );
    return allowedSites.filter((site) => !preferredSiteIdsSet.has(site.id));
  };

  let filteredNotPreferredSites = filterNotPreferredSitesFromAllowedSites(
    filteredPreferredSites,
    filteredAllowedSites
  )
    .map((site) => ({ label: site.site, value: site.id }))
    .sort((a, b) => a.label.localeCompare(b.label));

  useEffect(() => {
    // get initial data
    if (userLoading === "fulfilled" && alias !== "") {
      dispatch(getAllowedAndPreferredSites(alias));
    }
  }, [alias]);

  useEffect(() => {
    if(selectedSite === NO_SITE && allowedSites.length > 0) {
      let siteParam = getSiteParamFromPath(location.pathname);
      const defaultSite = getDefaultSite(filteredPreferredSites, allowedSites, siteLocation, siteParam);
      dispatch(setPreferredSite(defaultSite.id));
      dispatch(setSelectedSite(defaultSite));
    }
  }, [preferredSitesAndAllowedSitesFetched]);

  const updateSelectedSite = (siteId: number) => {
    const selectedSite = allowedSites.filter((site) => site.id === siteId)[0];
    dispatch(setPreferredSite(siteId));
    dispatch(setSelectedSite(selectedSite));
  };

  const getSiteParamFromPath = (pathname: String) => {
    // Example pathname with site param will look like:
    // "/placementsOverview/ABE3/pendingPlacements"
    // which when split by "/" will result in [,"placementsOverview","ABE3", "pendingPlacements"]
    return (pathname.includes("/placementsOverview")) ? pathname.split("/")[2] : undefined;
  }

  return (
    <Theme tokens={themeTokens}>
      <Masthead backgroundColor="primary">
        <MastheadTitle>
          <img
            src={logo}
            onClick={() => navigate("/")}
            width="408"
            height="52"
          />
        </MastheadTitle>
        <MastheadLink
          onClick={() => navigate("/placementsOverview")}
          selected={location.pathname === "/placementsOverview"}
          data-cy={"placements_overview__link"}
        >
          Placements Overview
        </MastheadLink>
        {isDev || isAdmin || isSMES || isFCLeaders ? (
          <MastheadLink
            onClick={() => navigate("/siteManagement")}
            selected={location.pathname.includes("/siteManagement")}
            data-cy={"site_management__link"}
          >
            Site Management
          </MastheadLink>
        ) : null}
        {hasPermission && hasAllowedSite ? (
          <MastheadLink
            onClick={() =>
              window.open(
                "https://us-east-1.quicksight.aws.amazon.com/sn/dashboards/b824b431-f864-4d7b-b86d-432e392dfbf0/sheets/b824b431-f864-4d7b-b86d-432e392dfbf0_b1b816e0-74ae-4fe0-908a-fab35f0d3972",
                "_blank"
              )
            }
            selected={location.pathname === "/reporting"}
          >
            Reporting
          </MastheadLink>
        ) : null}
        <Select
          value={preferredSite}
          //The page that will be affected by site selection here (landing page) has not been added yet
          onChange={updateSelectedSite}
          searchQuery={searchQuery}
          onSearch={setSearchQuery}
          placeholder="Selected Site..."
          width={200}
          disabled={isSiteSelectionDisabled}
          data-cy="site__select"
        >
          <SelectOptionGroup label="Preferred Sites">
            {filteredPreferredSites.map((option) => (
              <SelectOption
                label={option.label}
                value={option.value}
                key={option.value}
                data-cy={`site-select__preferredsite-${removeSpecialCharactersFromString(
                  option.label
                )}`}
              />
            ))}
          </SelectOptionGroup>
          <SelectOptionGroup
            label="All Sites"
            data-cy={`site-select__allsite-selectOptionGroup`}
          >
            {filteredNotPreferredSites.map((option) => (
              <SelectOption
                label={option.label}
                value={option.value}
                key={option.value}
                data-cy={`site-select__allsite-${removeSpecialCharactersFromString(
                  option.label
                )}`}
              />
            ))}
          </SelectOptionGroup>
        </Select>
        <ProfileMenu></ProfileMenu>
      </Masthead>
    </Theme>
  );
};
export default header;
