import store from "src/store";
import {
  JobTask,
  setRequirementFormErrors,
  setSelectedTaskName,
} from "src/store/createNewJobSlice";

/**
 * find if a task has an error
 */
export const doesTaskHaveError = (taskName: string) => {
  const requirementFormErrors =
    store.getState().createNewJobPage.requirementFormErrors;

  return (
    requirementFormErrors[taskName] &&
    Object.keys(requirementFormErrors[taskName] || {})
      .map((key) => requirementFormErrors[taskName][key].error)
      .some((el) => el === true)
  );
};

/**
 * find if any tasks have errors
 */
export const doTasksHaveErrors = () => {
  const taskNames = store.getState().createNewJobPage.taskNames;
  return taskNames.map((t) => doesTaskHaveError(t)).some((el) => el === true);
};

/**
 * validate all tasks
 */
export const validateTasks = () => {
  const jobFormData = store.getState().createNewJobPage.jobFormData;
  let newErrors: any = {};
  let isValid = true;
  if (jobFormData) {
    for (let task of jobFormData.tasks) {
      const requirementNameError = validateRequirementValue(
        task.measureUnit as string,
        task.requirementName as string
      );
      if (requirementNameError) {
        store.dispatch(setSelectedTaskName(task.label));
      }

      newErrors[task.label as string] = {
        requirementName: {
          error: requirementNameError ? true : false,
          errorMessage: requirementNameError,
        },
      };

      if (!task.requirementName) {
        isValid = false;
      }
    }
  }
  store.dispatch(setRequirementFormErrors(newErrors));
  return isValid;
};

/**
 * validate all tasks
 */
export const validateTask = (task: Partial<JobTask>) => {
  let newErrors: any = {};
  const requirementNameError = validateRequirementValue(
    task.measureUnit as string,
    task.requirementName as string
  );

  newErrors[task.label as string] = {
    requirementName: {
      error: requirementNameError ? true : false,
      errorMessage: requirementNameError,
    },
  };
  store.dispatch(setRequirementFormErrors(newErrors));
  return requirementNameError ? false : true;
};

/**
 * validate requirement value
 */
export const validateRequirementValue = (
  measureUnit: string,
  requirementName: string
) => {
  if (!requirementName) {
    return "Requirement value is required";
  }
  if (measureUnit === "Hours") {
    if (
      isNaN(+requirementName) ||
      +requirementName > 1 ||
      +requirementName < 0
    ) {
      return "Requirement value must be a number between 0 and 1";
    }
  } else if (measureUnit !== "YES/NO") {
    if (isNaN(+requirementName) || +requirementName < 0) {
      return "Requirement value must be an number greater than 0";
    }
  }
};

/**
 * create right placeholder for requirement value given measure unit
 */
export const generatePlaceholderForRequirementName = (
  measureUnit: string | undefined
) => {
  if (!measureUnit) {
    return "Select a success criteria to enter a requirement value";
  } else if (measureUnit === "Hours") {
    return "Enter % of shift required between 0-1...";
  } else {
    return "Enter a positive number...";
  }
};
