import React from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "src/store/store";
import Row from "@amzn/meridian/row";
import SearchField from "@amzn/meridian/search-field";
import {
  setIsCreateModalOpen,
  setSearchField,
} from "src/store/siteConfigPageSlice";
import Button from "@amzn/meridian/button";

const topOptions = () => {
  const dispatch = useDispatch();
  const { searchField } = useAppSelector((state) => state.siteConfigPage);

  return (
    <Row
      widths={["85%", "15%"]}
      spacingInset="400 none"
      alignmentHorizontal="justify"
    >
      <Row width={"grid-4"}>
        <SearchField
          value={searchField}
          onChange={(newSearchField) =>
            dispatch(setSearchField(newSearchField))
          }
          placeholder="Search for..."
          onSubmit={() => {}}
        />
      </Row>
      <Button
        id="site-config-top-options-open-modal"
        onClick={() => dispatch(setIsCreateModalOpen(true))}
      >
        Add New Site
      </Button>
    </Row>
  );
};

export default topOptions;
