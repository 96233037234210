import React, {useEffect} from "react";
import BreadCrumbs from "src/components/shared/breadcrumbs/breadcrumbs";
import TabGroup from "./tabGroup/tabGroup";
import "./adminConfiguration.scss";
import Box from "@amzn/meridian/box";
import TaskConfiguration from "./taskConfiguration/taskConfiguration";
import SiteConfiguration from "./siteConfiguration/siteConfiguration";
import RestrictionFormConfiguration from "./restrictionFormConfiguration/restrictionFormConfiguration";
import FormTaskConfiguration from "./formTaskConfiguration/formTaskConfiguration";
import {useAppSelector} from "src/store/store";
import {useLocation} from "react-router-dom";
import {setSelectedTab} from "src/store/adminConfigPageSlice";
import {useDispatch} from "react-redux";

const PATH_TO_TAB: Record<string, string> = {
    "/adminConfiguration": "site-config",
    "/siteConfiguration": "site-config",
    "/taskConfiguration": "task-config",
    "/restrictionFormConfiguration":"form-config",
}

const adminConfiguration = () => {
    const { selectedTab } = useAppSelector(state => state.adminConfigPage);
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        if (PATH_TO_TAB[location.pathname]) {
            dispatch(setSelectedTab(PATH_TO_TAB[location.pathname]));
        }
    }, []);
  return (
    <div className="admin-configuration">
      <BreadCrumbs
        breadcrumbList={[
          { label: "Return To Work 2.0", url: "/" },
          { label: "Admin Portal", url: "/adminConfiguration" },
        ]}
      />

      <div className="admin-configuration__tab-group">
        <TabGroup />
      </div>

      <Box
        type="outline"
        spacingInset="200 500 400 500"
        className="admin-configuration__content"
      >
          {selectedTab === "site-config" && <SiteConfiguration />}
          {selectedTab === "task-config" && <TaskConfiguration />}
          {selectedTab === "form-config" && <RestrictionFormConfiguration />}
          {selectedTab === "form-tasks-config" && <FormTaskConfiguration />}
      </Box>
    </div>
  );
};

export default adminConfiguration;
