import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import PlacementsOverview from "src/components/pages/placementsOverview/placementsOverview";
import SiteManagement from "src/components/pages/siteManagement/siteManagement";
import Reporting from "src/components/pages/reporting/reporting";
import AdminConfiguration from "src/components/pages/adminConfiguration/adminConfiguration";
import JobConfiguration from "src/components/pages/jobConfiguration/jobConfiguration";
import DefaultSettings from "src/components/pages/defaultSettings/defaultSettings";
import CreateNewJob from "src/components/pages/createNewJob/createNewJob";
import GeneralInformation from "src/components/pages/generalInformation/generalInformation";
import NewJMR from "src/components/pages/newJMR/newJMR";
import PlacementDetails from "src/components/pages/placementDetails/placementDetails";
import PageNotFound from "src/components/shared/errorHandle/pageNotFound";
import Unauthorized from "src/components/shared/errorHandle/unauthorized";
import { useAppSelector } from "src/store/store";
import * as LDAP from "src/config/LDAP";
import {
  PLACEMENT_DETAILS_BASEURL,
  PLACEMENT_OVERVIEW_BASEURL,
} from "src/constants/Routes";
import PlacementDetailsRoute from "./placementDetailsRoute";

const MainContent = () => {
  const { hasPermission, ldap } = useAppSelector((state) => state.user);
  const { hasAllowedSite } = useAppSelector((state) => state.sites);

  const isAdmin = ldap.includes(LDAP.ADMIN);
  const isSMES = ldap.includes(LDAP.SMES);
  const isFCLeaders = ldap.includes(LDAP.FC_LEADERS);
  const isDev = ldap.includes(LDAP.DEV);

  if (!hasPermission || !hasAllowedSite) {
    return <Unauthorized />;
  } else {
    return (
      <Routes>
        <Route path="/" element={<Navigate to="/placementsOverview" />} />
        <Route path="/general" element={<GeneralInformation />} />
        <Route path="/placementsOverview" element={<PlacementsOverview />}>
          <Route path=":site/:tab" element={<PlacementsOverview />} />
        </Route>
        {isDev || isAdmin || isSMES || isFCLeaders ? (
          <Route path="/siteManagement/*" element={<SiteManagement />} />
        ) : (
          <Route path="/siteManagement/*" element={<Unauthorized />} />
        )}
        <Route path="/reporting" element={<Reporting />} />
        {[
          "/adminConfiguration",
          "/siteConfiguration",
          "/taskConfiguration",
          "/restrictionFormConfiguration",
        ].map((path) =>
          isDev || isAdmin ? (
            <Route path={path} element={<AdminConfiguration />} />
          ) : (
            <Route path={path} element={<Unauthorized />} />
          )
        )}
        {isDev || isAdmin || isSMES ? (
          <Route path="/jobConfiguration" element={<JobConfiguration />} />
        ) : (
          <Route path="/jobConfiguration" element={<Unauthorized />} />
        )}
        <Route path="/defaultSettings" element={<DefaultSettings />} />
        <Route path="/createNewJob" element={<CreateNewJob />} />
        <Route path="/newJMR" element={<NewJMR />}>
          <Route path=":placementId/update/:updateType" element={<NewJMR />} />
          <Route path=":type/:login/:caseId" element={<NewJMR />} />
        </Route>
        <Route
          path={`${PLACEMENT_OVERVIEW_BASEURL}/:placementId`} // deprecated
          element={<PlacementDetailsRoute />}
        />
        <Route
          path={`${PLACEMENT_DETAILS_BASEURL}/:type/:login/:caseId`}
          element={<PlacementDetails />}
        />
        <Route
          path={`${PLACEMENT_DETAILS_BASEURL}/:placementId`}
          element={<PlacementDetails />}
        />
        <Route path="/unauthorized" element={<Unauthorized />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    );
  }
};

export default MainContent;
