import React, { useEffect } from "react";
import Button from "@amzn/meridian/button";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import { useDispatch } from "react-redux";
import {
  generateJobMatches,
  setIsCancelModalOpen,
  setJmrFilter,
  setPageProgress,
  JmrFlowTypes,
} from "src/store/newJmrPageSlice";
import Column from "@amzn/meridian/column";
import Box from "@amzn/meridian/box";
import TopOptions from "./topOptions/topOptions";
import ResultsTable from "./resultsTable/resultsTable";
import PlacementsSelectionTable from "./placementsSelectionTable/placementsSelectionTable";
import { useAppSelector } from "src/store/store";
import PaginationWithDropdown from "src/components/shared/paginationWithDropdown/paginationWithDropdown";

const resultsPage = () => {
  const dispatch = useDispatch();
  const { isResultsPageValid, jmrFilter, jmrFlowType, backgroundFormData } =
    useAppSelector((state) => state.newJmrPage);

  useEffect(() => {
    if (backgroundFormData.alias) {
      dispatch(generateJobMatches());
    }
  }, [backgroundFormData]);

  return (
    <Box width={"80%"}>
      <Text type="d100" className="new-jmr__title" data-cy="new-jmr__title">
        Job Match Results
      </Text>

      <div>
        <Column spacing={"300"}>
          <Text type="b300" color="secondary">
            Choose a placement from the Jobs listed in the table. Jobs are
            ranked according to information recorded in previous steps of the
            Job Match Report.
          </Text>
          <TopOptions />
          <ResultsTable />
          <PaginationWithDropdown
            currentPage={jmrFilter.currentPage}
            perPage={jmrFilter.perPage}
            totalPages={jmrFilter.totalPages}
            setCurrentPage={(currentPage) =>
              dispatch(setJmrFilter({ ...jmrFilter, currentPage }))
            }
            setPerPage={(perPage) =>
              dispatch(setJmrFilter({ ...jmrFilter, perPage }))
            }
          />
          <PlacementsSelectionTable />
        </Column>
      </div>

      <Row widths={["fill", "70px", "90px", "171px"]}>
        <div></div>
        <Button
          type="link"
          onClick={() => dispatch(setIsCancelModalOpen(true))}
          className="new-jmr__cancel-btn"
        >
          Cancel
        </Button>
        <Button
          type="tertiary"
          onClick={() => dispatch(setPageProgress(1))}
          className="new-jmr__prev-btn"
          disabled={
            jmrFlowType === JmrFlowTypes.AlternativePlacementRecommended
          }
        >
          Previous
        </Button>
        <Button
          onClick={() => dispatch(setPageProgress(3))}
          disabled={!isResultsPageValid}
          className="new-jmr__next-btn"
          data-cy="new-jmr__results__next"
        >
          Review Placements
        </Button>
      </Row>
    </Box>
  );
};

export default resultsPage;
