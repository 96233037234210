import React, { useEffect } from "react";
import { BackgroundFormData } from "src/store/newJmrPageSlice";
import { AUSTIN } from "./constant";
import AustinButton from "./austinButton";

interface generalUserOptionProps {
  handleChange: (key: keyof BackgroundFormData, value: any) => void;
  backgroundFormData: BackgroundFormData;
}

const generalUserOption = ({
  handleChange,
  backgroundFormData,
}: generalUserOptionProps) => {
  useEffect(() => handleChange("caseSource", AUSTIN), []);
  return (
    <AustinButton caseSource={backgroundFormData.caseSource}></AustinButton>
  );
};

export default generalUserOption;
