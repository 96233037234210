import React from "react";
import { useAppSelector } from "src/store/store";

const GeneralInformation = () => {
  const { userLoading, token, firstName, lastName, alias, email, ldap } =
    useAppSelector((state) => state.user);

  const userInfo =
    userLoading !== "pending" && token ? (
      <div>
        <p>
          Name : {firstName} {lastName}
        </p>
        <p>Alias : {alias}</p>
        <p data-cy="emailValue">Email : {email}</p>
        <p data-cy="ldapGroupsValue">LDAP : {ldap.join(",")}</p>
      </div>
    ) : (
      <p>pending...</p>
    );

  return (
    <div>
      <h1>Hello RTW 2.0!</h1>
      {userInfo}
    </div>
  );
};

export default GeneralInformation;
