import apiClient from "src/utils/apiClient";
import { SiteJobCapacityUsageData } from "./types/siteJobCapacityUsageData";
import { TOAST_TIMEOUT } from "src/config/Toast";
import store from "src/store";
import { createToast } from "src/store/toastsSlice";

class CapacityUsageService {
  async downloadCapacitySummary(siteId: number) {
    const res = await apiClient.get(`/sites/${siteId}/capacityUsage/download`, {
      responseType: "blob",
    });
    if (res) {
      store.dispatch(
        createToast({
          type: "success",
          message: "Downloaded Capacity Summary successfully",
          timeout: TOAST_TIMEOUT,
        })
      );
    }
    return res;
  }
  /**
   * Get capacity usage for site
   */
  async getCapacityUsageForSite(siteId: number): Promise<{ data: SiteJobCapacityUsageData[] }> {
    return await apiClient.get(`/sites/${siteId}/capacityUsage`);
  }

  createShiftsMapForJob(jobId: number, capacityUsageMap: Map<number, SiteJobCapacityUsageData>) {
    let shiftsMap = new Map();
    if (capacityUsageMap.has(jobId)) {
      let jobCapacityUsage = capacityUsageMap.get(jobId);
      shiftsMap = new Map(
        jobCapacityUsage!.shifts.map(shiftCapacityUsage => [shiftCapacityUsage.shiftId, shiftCapacityUsage])
      );
    }
    return shiftsMap;
  }

}

export default new CapacityUsageService();
