import Column from "@amzn/meridian/column";
import DatePicker from "@amzn/meridian/date-picker";
import Divider from "@amzn/meridian/divider";
import Input from "@amzn/meridian/input";
import Textarea from "@amzn/meridian/textarea";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import React, { useState } from "react";
import { OomdForm } from "src/store/placementDetailsPageSlice/types";
import { useAppSelector } from "src/store/store";

interface injuryInfoProps {
  handleChange: (key: keyof OomdForm, value: any) => void;
}
const injuryInfo = ({ handleChange }: injuryInfoProps) => {
  const { oomdFormData } = useAppSelector(
    (state) => state.placementDetailsPage
  );
  const stringLengthMax = 5000;
  const [remainingCharacter, setRemainingCharacter] = useState(stringLengthMax);
  return (
    <div>
      <Text>
        Fill out the following information. This will be used to create a PDF
        version of the Associate’s Offer of Modified Duties
      </Text>
      <Row widths={["fill", "30%", "40%"]}>
        <Column spacing={"200"} spacingInset={"400 200"}>
          <Text tag="label" type="b300" color="primary">
            Injury
          </Text>
          <Input
            placeholder="Enter injury"
            value={oomdFormData.injury}
            onChange={(injury) => handleChange("injury", injury)}
          />
        </Column>
        <Column spacing={"200"} spacingInset={"200"}>
          <Text tag="label" type="b300" color="primary">
            Date of injury
          </Text>
          <DatePicker
            placeholder="Enter injury date"
            value={oomdFormData.dateOfInjury}
            onChange={(dateOfInjury) =>
              handleChange("dateOfInjury", dateOfInjury)
            }
          />
        </Column>
        <Column spacing={"200"} spacingInset={"200"}>
          <Text tag="label" type="b300" color="primary">
            Date of injury reported
          </Text>
          <DatePicker
            placeholder="Enter report date"
            value={oomdFormData.dateOfInjuryReportedToAmcare}
            onChange={(dateOfInjuryReportedToAmcare) =>
              handleChange(
                "dateOfInjuryReportedToAmcare",
                dateOfInjuryReportedToAmcare
              )
            }
          />
        </Column>
      </Row>
      <Row widths={["fill"]}>
        <Column spacing={"200"} spacingInset={"200"}>
          <Text tag="label" type="b300" color="primary">
            Additional Notes
          </Text>
          <Textarea
            placeholder="Enter addtional notes"
            value={oomdFormData.additionalNotes}
            onChange={(notes) => {
              if (notes.length <= stringLengthMax) {
                handleChange("additionalNotes", notes);
                setRemainingCharacter(stringLengthMax - notes.length);
              }
            }}
            constraintText={`${remainingCharacter} characters remaining`}
          />
        </Column>
      </Row>
      <Divider spacingBefore={"400"} spacingAfter={"400"} />
    </div>
  );
};

export default injuryInfo;
