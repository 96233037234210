import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { FilterOptions } from "src/services/placementOverview/types/filterOptions";
import { AvailableFilterOptions } from "src/services/placementOverview/types/availableFilterOptions";
import { FilterTag } from "src/services/placementOverview/types/filterTag";
import { PlacementData } from "src/services/placementOverview/types/placementData";
import { TableDataRow } from "src/services/placementOverview/types/tableDataRow";
import PlacementOverviewService from "src/services/placementOverview/placementOverviewService";
import FilterTagsAndOptionsService from "src/services/placementOverview/filterTagsAndOptionsService";
import PlacementTableDataService from "src/services/placementOverview/placementTableDataService";
import AvailableFilterOptionsService from "src/services/placementOverview/availableFilterOptionsService";
import { DEFAULT_TABLE_PAGE_SIZE } from "src/constants/Pagination";

export type PlacementOverviewPageState = {
  isLoading: boolean;
  tableData: TableDataRow[];
  activePlacementsTableData: TableDataRow[];
  expiredPlacementsTableData: TableDataRow[];
  pendingPlacementsTableData: TableDataRow[];
  closedPlacementsTableData: TableDataRow[];
  transferredInPlacementsTableData: TableDataRow[];
  currentPage: number;
  totalPages: number;
  searchField: string;
  isUserDaliUser: boolean;
  isAdvancedFilterSheetOpen: boolean;
  availableFilterOptions: AvailableFilterOptions;
  selectedFilterOptions: FilterOptions;
  appliedFilterOptions: FilterOptions;
  filterTags: FilterTag[];
  perPage: number;
};

const initialFilterOptions: FilterOptions = {
  sites: [],
  placementTypes: [],
  jobs: [],
  daysInCategory: [],
  jobTypes: [],
  shiftCodes: [],
  placementStartDate: "",
  placementEndDate: "",
};

const initialAvailableFilterOptions: AvailableFilterOptions = {
  availableSites: [],
  availablePlacementTypes: [],
  availableJobs: [],
  availableDaysInCategory: [],
  availableJobTypes: [],
  availableShiftCodes: [],
};

const initialState: PlacementOverviewPageState = {
  isLoading: true,
  tableData: [],
  activePlacementsTableData: [],
  expiredPlacementsTableData: [],
  pendingPlacementsTableData: [],
  closedPlacementsTableData: [],
  transferredInPlacementsTableData: [],
  currentPage: 1,
  totalPages: 0,
  searchField: "",
  isUserDaliUser: false,
  isAdvancedFilterSheetOpen: false,
  availableFilterOptions: initialAvailableFilterOptions,
  selectedFilterOptions: initialFilterOptions,
  appliedFilterOptions: initialFilterOptions,
  filterTags: [],
  perPage: DEFAULT_TABLE_PAGE_SIZE,
};

/**
 * get open placements by requester login
 * @returns {Object}
 */
export const getOpenPlacementsByRequesterLogin = createAsyncThunk(
  "placements/getByRequesterLogin",
  async (requesterLogin: string, {getState}) => {
    const state = await (getState() as any);
    const { data } =
      await PlacementOverviewService.getOpenPlacementsByRequesterLogin(
        requesterLogin
      );
    let appliedFilterOptions = state.placementOverviewPage.appliedFilterOptions;
    return {data, appliedFilterOptions};
  }
);

/**
 * get open and closed placements by site id
 * @returns {Object}
 */
export const getOpenAndClosedPlacementsBySiteId = createAsyncThunk(
  "placements/getBySiteId",
  async (siteId: number, {getState}) => {
    const state = await (getState() as any);
    const { data: openPlacements } = await PlacementOverviewService.getOpenPlacementsBySiteId(
      siteId
    );
    const {data: closedPlacements} = await PlacementOverviewService.getClosedPlacementsBySiteId(
      siteId
    );
    let appliedFilterOptions = state.placementOverviewPage.appliedFilterOptions;
    let placements = openPlacements.concat(closedPlacements);
    return {placements, appliedFilterOptions};
  }
);


/** Placement Overview Page Slice */
const { reducer, actions } = createSlice({
  name: "placementOverviewPageSlice",
  initialState,
  reducers: {
    setIsDaliUser: (state, action) => {
      state.isUserDaliUser = action.payload;
    },
    setSearchField: (state, action) => {
      state.searchField = action.payload;
    },
    setIsAdvancedFilterSheetOpen: (state, action) => {
      state.isAdvancedFilterSheetOpen = action.payload;
    },
    setSelectedFilterOptions: (state, action) => {
      state.selectedFilterOptions = action.payload;
    },
    resetFilters: (state) => {
      state.selectedFilterOptions = initialFilterOptions;
    },
    applyFilters: (state) => {
      state.appliedFilterOptions = state.selectedFilterOptions;
      const {pendingPlacementData, activePlacementData, expiredPlacementData, closedPlacementData} = extractAndFilter(state.tableData, state.appliedFilterOptions);
      state.pendingPlacementsTableData = pendingPlacementData;
      state.activePlacementsTableData = activePlacementData;
      state.expiredPlacementsTableData = expiredPlacementData;
      state.closedPlacementsTableData = closedPlacementData;
      state.filterTags = FilterTagsAndOptionsService.createFilterTags(
        state.appliedFilterOptions
      );
      state.currentPage = 1;
    },
    setAppliedFilterOptions: (state, action) => {
      state.appliedFilterOptions = action.payload;
    },
    removeTagAndUpdateFilters: (state, action) => {
      state.filterTags = FilterTagsAndOptionsService.removeFilterTag(
        state.filterTags,
        action.payload
      );
      state.appliedFilterOptions =
        FilterTagsAndOptionsService.getUpdatedAppliedFilterOptionsOnTagRemoval(
          state.appliedFilterOptions,
          action.payload.optionName,
          action.payload.value
        );
      state.selectedFilterOptions = state.appliedFilterOptions;
      const {pendingPlacementData, activePlacementData, expiredPlacementData, closedPlacementData} = extractAndFilter(state.tableData, state.appliedFilterOptions);
      state.pendingPlacementsTableData = pendingPlacementData;
      state.activePlacementsTableData = activePlacementData;
      state.expiredPlacementsTableData = expiredPlacementData;
      state.closedPlacementsTableData = closedPlacementData;
      state.currentPage = 1;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setTotalPages: (state, action) => {
      state.totalPages = action.payload;
    },
    setPerPage: (state, action) => {
      state.perPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getOpenPlacementsByRequesterLogin.fulfilled,
      (state, { payload }) => {
        state.tableData = payload.data.map((placementData: PlacementData) =>
          PlacementTableDataService.placementsDataToTableRow(placementData)
        );
        const {pendingPlacementData, activePlacementData, expiredPlacementData} = extractAndFilter(state.tableData, state.appliedFilterOptions);
        state.pendingPlacementsTableData = pendingPlacementData;
        state.activePlacementsTableData = activePlacementData;
        state.expiredPlacementsTableData = expiredPlacementData;
        state.availableFilterOptions =
          AvailableFilterOptionsService.extractAvailableFilterOptions(
            state.tableData
          );
        state.isLoading = false;
      }
    );

    builder.addCase(
      getOpenAndClosedPlacementsBySiteId.fulfilled,
      (state, { payload }) => {
        state.tableData = payload.placements.map((placementData: PlacementData) =>
          PlacementTableDataService.placementsDataToTableRow(placementData)
        );
        const {pendingPlacementData, activePlacementData, expiredPlacementData, closedPlacementData, transferredInPlacementData} = extractAndFilter(state.tableData, state.appliedFilterOptions);
        state.pendingPlacementsTableData = pendingPlacementData;
        state.activePlacementsTableData = activePlacementData;
        state.expiredPlacementsTableData = expiredPlacementData;
        state.closedPlacementsTableData = closedPlacementData;
        state.transferredInPlacementsTableData = transferredInPlacementData;
        state.availableFilterOptions =
          AvailableFilterOptionsService.extractAvailableFilterOptions(
            state.tableData
          );
        state.isLoading = false;
      }
    );
  },
});

const extractAndFilter = (tableData: TableDataRow[], appliedFilterOptions: FilterOptions) => {
  const pendingPlacementData =  PlacementTableDataService.extractAndFilterPending(
    tableData,
    appliedFilterOptions
  );
  const activePlacementData = PlacementTableDataService.extractAndFilterActive(
    tableData,
    appliedFilterOptions
  );
  const expiredPlacementData = PlacementTableDataService.extractAndFilterExpired(
    tableData,
    appliedFilterOptions
  );

  const closedPlacementData = PlacementTableDataService.extractAndFilterClosed(
    tableData,
    appliedFilterOptions
  );

  const transferredInPlacementData = PlacementTableDataService.extractTransferred(
    tableData
  );

  return {pendingPlacementData, activePlacementData, expiredPlacementData, closedPlacementData, transferredInPlacementData};
}

export const {
  setIsDaliUser,
  setSearchField,
  setIsAdvancedFilterSheetOpen,
  setAppliedFilterOptions,
  setSelectedFilterOptions,
  resetFilters,
  applyFilters,
  removeTagAndUpdateFilters,
  setCurrentPage,
  setTotalPages,
  setPerPage,
} = actions;

export default reducer;
