import React from "react";
import { ButtonOption } from "@amzn/meridian/button-group";
import { AUSTIN } from "./constant";

const austinOption = ({ disabled }: { disabled: boolean }) => {
  return (
    <ButtonOption
      disabled={disabled}
      value={AUSTIN}
      data-cy="new-jmr__background__caseSource-austin-btnOption"
    >
      {AUSTIN}
    </ButtonOption>
  );
};

export default austinOption;
