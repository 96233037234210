import React, { useState } from "react";
import BreadCrumbs from "src/components/shared/breadcrumbs/breadcrumbs";
import TabGroup from "./tabGroup/tabGroup";
import "./jobConfiguration.scss";
import Box from "@amzn/meridian/box";
import JobConfiguration from "./jobConfiguration/jobConfiguration";

const jobConfiguration = () => {
  const [currentTab, setCurrentTab] = useState("job-config");

  return (
    <div className="job-configuration">
      <BreadCrumbs
        breadcrumbList={[
          { label: "Return To Work 2.0", url: "/" },
          { label: "Job Configuration Portal", url: "/adminConfiguration" },
        ]}
      />

      <div className="job-configuration__tab-group">
        <TabGroup currentTab={currentTab} setCurrentTab={setCurrentTab} />
      </div>

      <Box
        type="outline"
        spacingInset="200 500 400 500"
        className="job-configuration__content"
      >
        {currentTab === "job-config" && <JobConfiguration />}
      </Box>
    </div>
  );
};

export default jobConfiguration;
