import { createSlice } from "@reduxjs/toolkit";

export type SiteState = {
  pageDisabled: boolean;
  areMultipleSitesSelected: boolean;
  defaultHomesiteDropdownValue: number;
  multipleSitesDropdownValues: number[];
};

const initialState: SiteState = {
  pageDisabled: true,
  areMultipleSitesSelected: false,
  defaultHomesiteDropdownValue: 0,
  multipleSitesDropdownValues: [],
};

/** Default Settings Page Slice */
const { reducer, actions } = createSlice({
  name: "defaultSettingsPageSlice",
  initialState,
  reducers: {
    setAreMultipleSitesSelected: (state, action) => {
      state.areMultipleSitesSelected = action.payload;
    },
    setPageDisabled: (state, action) => {
      state.pageDisabled = action.payload;
    },
    setDefaultHomesiteDropdownValue: (state, action) => {
      state.defaultHomesiteDropdownValue = action.payload;
    },
    setMultipleSitesDropdownValues: (state, action) => {
      state.multipleSitesDropdownValues = action.payload;
    },
  },
});

export const {
  setAreMultipleSitesSelected,
  setPageDisabled,
  setDefaultHomesiteDropdownValue,
  setMultipleSitesDropdownValues,
} = actions;

export default reducer;
