import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ShiftsService, { ShiftPayload } from "src/services/shiftsService";
import { DEFAULT_TABLE_PAGE_SIZE } from "src/constants/Pagination";

export interface TableDataRow {
  id: number;
  siteId: number;
  shiftCode: string;
  shiftLength: string;
  dayOrNight: string;
  active: string;
  daysOfWeek: string[];
  daysString: string;
}

export interface ShiftFieldLength {
  maxShiftCodeCharLength: number;
}

const shiftFieldLengthValues: ShiftFieldLength = {
  maxShiftCodeCharLength: 100,
}

export type UpdateShiftsPageState = {
  tableData: TableDataRow[];
  currentPage: number;
  totalPages: number;
  perPage: number;
  searchField: string;
  isCreateModalOpen: boolean;
  selectedShift?: TableDataRow;
  isLoading: boolean;
  shiftFieldLengths: ShiftFieldLength;
};

const initialState: UpdateShiftsPageState = {
  tableData: [],
  currentPage: 1,
  totalPages: 0,
  perPage: DEFAULT_TABLE_PAGE_SIZE,
  searchField: "",
  isCreateModalOpen: false,
  isLoading: true,
  shiftFieldLengths: shiftFieldLengthValues,
};

/**
 * generate a string for days of the week based on the
 */
const generateDaysOfWeekString = (days: string[]) => {
  let result = "";
  for (let day of days) {
    switch (day) {
      case "MONDAY":
        result += "Mon, ";
        break;
      case "TUESDAY":
        result += "Tue, ";
        break;
      case "WEDNESDAY":
        result += "Wed, ";
        break;
      case "THURSDAY":
        result += "Thur, ";
        break;
      case "FRIDAY":
        result += "Fri, ";
        break;
      case "SATURDAY":
        result += "Sat, ";
        break;
      case "SUNDAY":
        result += "Sun, ";
        break;
    }
  }
  return result.substring(0, result.length - 2);
};

const tableRowToShift = (row: TableDataRow) => {
  const shift: ShiftPayload = {
    ...row,
    shiftLengthHours: parseInt(row.shiftLength.replace("Hours", "")),
    isActive: row.active === "Active",
    isDayShift: row.dayOrNight === "Day",
  };
  return shift;
};

const shiftToTableRow = (shift: ShiftPayload) => {
  const row: TableDataRow = {
    ...shift,
    shiftLength: `${shift.shiftLengthHours} Hours`,
    active: shift.isActive ? "Active" : "Inactive",
    dayOrNight: shift.isDayShift ? "Day" : "Night",
    daysString: generateDaysOfWeekString(shift.daysOfWeek),
  };
  return row;
};

/**
 * get all shifts
 * @returns {Object}
 */
export const getShifts = createAsyncThunk(
  "updateShifts/getShifts",
  async (_, { getState }) => {
    const preferredSite = await (getState() as any).user.preferredSite;
    const { data } = await ShiftsService.getShifts(preferredSite);
    return data;
  }
);

export const addOrEditShift = createAsyncThunk(
  "shifts/createOrUpdateShift",
  async (shift: any, { getState }) => {
    const preferredSite = await (getState() as any).user.preferredSite;
    const { data } = await ShiftsService.createOrUpdateShift(
      tableRowToShift({ ...shift, siteId: preferredSite })
    );

    if (data) {
      return shiftToTableRow(data);
    }
  }
);

/** Task Config Page Slice */
const { reducer, actions } = createSlice({
  name: "updateShiftsPageSlice",
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setSearchField: (state, action) => {
      state.searchField = action.payload;
    },
    setIsCreateModalOpen: (state, action) => {
      state.isCreateModalOpen = action.payload;
    },
    setSelectedShift: (state, action) => {
      state.selectedShift = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setTotalPages: (state, action) => {
      state.totalPages = action.payload;
    },
    setPerPage: (state, action) => {
      state.perPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getShifts.fulfilled, (state, { payload }) => {
      state.tableData = payload.map((shift: ShiftPayload) =>
        shiftToTableRow(shift)
      );
      state.isLoading = false;
    });
    builder.addCase(addOrEditShift.fulfilled, (state, { payload }) => {
      if (payload) {
        state.searchField = payload.shiftCode;

        const isAlreadyInTable = state.tableData.some((row) => {
          return row.id === payload.id;
        });

        if (isAlreadyInTable) {
          // this will be the case when editing an existing site
          state.tableData = state.tableData.map((row) =>
            row.id === payload.id ? payload : row
          );
        } else {
          // this will be the case when adding a new site
          state.tableData = [...state.tableData, payload];
        }
      }
    });
  },
});

export const {
  setSearchField,
  setIsCreateModalOpen,
  setSelectedShift,
  setCurrentPage,
  setPerPage,
  setTotalPages,
  setIsLoading,
} = actions;

export default reducer;
