import Button from "@amzn/meridian/button";
import Column from "@amzn/meridian/column";
import Pagination from "@amzn/meridian/pagination";
import Table, { TableCell, TableRow } from "@amzn/meridian/table";
import { TableSortDirection } from "@amzn/meridian/table/table";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import UncontrolledExpandableRow from "src/components/shared/uncontrolledExpandableRow/uncontrolledExpandableRow";
import { downloadJMR } from "src/store/placementDetailsPageSlice/placementDetailsPageSlice";
import { PlacementDetailsRevision } from "src/store/placementDetailsPageSlice/types";
import { useAppSelector } from "src/store/store";
import { sortAndPaginateTableData } from "src/utils/tableUtils";
import Tooltip from "@amzn/meridian/tooltip";
import Icon from "@amzn/meridian/icon";
import alertKnockoutTokens from "@amzn/meridian-tokens/base/icon/alert-knockout";
import "./revisionHistory.scss";
import { TRANSFER_PENDING } from "src/config/AccommodationStatusNames";
import Text from "@amzn/meridian/text";

// millisecnds per second
const MS_PER_SEC = 1000;

const revisionHistory = () => {
  const dispatch = useDispatch();
  const { placementDetails, jmrS3Keys } = useAppSelector((state) => state.placementDetailsPage);
  const [sortColumn, setSortColumn] = useState("revisionDate");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [sortDirection, setSortDirection] = useState<TableSortDirection>("descending");
  const onSort = useCallback(({ sortColumn, sortDirection }) => {
    setSortDirection(sortDirection);
    setSortColumn(sortColumn);
  }, []);
  const [sortedTableData, setSortedTableData] = useState(placementDetails.revisions);

  useEffect(() => {
    // filter, sorting, and pagination
    if (placementDetails?.revisions) {
      const [paginatedData, totalPages] = sortAndPaginateTableData(
        [...placementDetails.revisions],
        sortColumn,
        sortDirection,
        currentPage,
        5
      );
      setSortedTableData(paginatedData as PlacementDetailsRevision[]);
      setTotalPages(totalPages as number);
    }
  }, [sortColumn, sortDirection, placementDetails.revisions, currentPage]);

  return (
    <Column alignmentHorizontal="center" spacing={"400"}>
      <Table
        headerRows={1}
        rowComponents={[UncontrolledExpandableRow, TableRow]}
        showStripes={true}
        onSort={onSort}
        sortColumn={sortColumn}
        sortDirection={sortDirection}
        showDividers={true}
      >
        <TableRow>
          <TableCell sortColumn="revisionDate">Revision Date</TableCell>
          <TableCell sortColumn="status">Status Update</TableCell>
          <TableCell>Download JMR Link</TableCell>
          <TableCell sortColumn="revisedBy">Revised By</TableCell>
        </TableRow>

        {sortedTableData?.map((revision, index) => (
          <TableRow key={index}>
            <TableCell>{new Date(revision.revisionDate)?.toLocaleDateString("en-US", { timeZone: "UTC" })}</TableCell>
            <TableCell data-cy={`placement-details__revision__status-${index}`}>
              {revision.status.status}
              {revision.status.status === TRANSFER_PENDING && revision.previousFc !== null && (
                <span> (from {revision.previousFc})</span>
              )}
              {(revision.status.status === "Associate Decision Pending" || revision.status.status === "Denied") &&
                revision.decisionNotes !== null && (
                  <Text>
                    <strong>Decision Notes: </strong>
                    {revision.decisionNotes}
                  </Text>
                )}
            </TableCell>
            <TableCell>
              {revision.jmrFileName && (
                <>
                  <Button
                    data-cy={`placement-details__revision__download-${index}`}
                    type="link"
                    onClick={() => dispatch(downloadJMR(revision.jmrFileName))}
                  >
                    {(revision.revisionDate && "Download JMR") || ""}
                  </Button>
                  {revision.austinUploadFailed && (
                    <Tooltip
                      position="top"
                      title="JMR upload to AUSTIN in progress. Please wait a few minutes and refresh. If unsuccessful, return to AUSTIN for manual upload using Date (YYYY-MM-DD) Login_document name. Example: 2020-02-28_johndoe_JMR."
                    >
                      <Button className="warning" type="link">
                        <Icon tokens={alertKnockoutTokens} />
                      </Button>
                    </Tooltip>
                  )}
                </>
              )}
            </TableCell>
            <TableCell data-cy={`placement-details__revision__revisedBy-${index}`}>{revision.revisedBy}</TableCell>
          </TableRow>
        ))}
      </Table>
      <Pagination
        numberOfPages={totalPages}
        onChange={setCurrentPage}
        currentPage={currentPage}
        showSkipArrows={true}
      />
    </Column>
  );
};

export default revisionHistory;
