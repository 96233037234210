import Column from "@amzn/meridian/column";
import Input from "@amzn/meridian/input";
import Row from "@amzn/meridian/row";
import Select, { SelectOption } from "@amzn/meridian/select";
import Text from "@amzn/meridian/text";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { EmailSettings } from "src/services/emailDistribution/types/emailSettings";
import { setSendEmailFormData } from "src/store/placementDetailsPageSlice/placementDetailsPageSlice";
import {
  SelectedEmailTypeEnum,
  SendEmailFormData,
} from "src/store/placementDetailsPageSlice/types";
import { useAppSelector } from "src/store/store";
import { getAustinLink } from "src/utils/helpers";
import Link from "@amzn/meridian/link";

interface InputFieldsData {
  label: string;
  formDataKey?: keyof SendEmailFormData;
  disabled: boolean;
  placeholder: string;
}
interface commonFieldsProps {
  handleChange: (key: keyof SendEmailFormData, value: any) => void;
}
const commonFields = ({ handleChange }: commonFieldsProps) => {
  const {
    sendEmailFormData,
    sendEmailFormErrors,
    placementDetails,
    emailSettings,
    bccEmailList
  } = useAppSelector((state) => state.placementDetailsPage);
  const dispatch = useDispatch();
  const [inputFields, setInputFields] = useState<InputFieldsData[]>();

  // get email recipients from settings given the setting type
  const getEmailFromSettings = (
    emailSettingsKey: keyof EmailSettings,
    includeManagerKey: keyof EmailSettings
  ) => {
    if (emailSettings) {
      const managerEmail = `${placementDetails.managerAlias}@amazon.com`;
      const emailRecipients = emailSettings[emailSettingsKey];
      const emails =
        emailSettings[includeManagerKey] && placementDetails.managerAlias
          ? `${emailRecipients};${managerEmail}`
          : (emailRecipients as string) || "";
      // trim leading semicolon
      return emails.replace(/^;/, "");
    }
    return "";
  };

  const getEmailBccList = () => {
    if (bccEmailList.length > 0) {
      return (<>
        <Text>This email automatically notifies
        {bccEmailList.map((email, i) =>
            <>
              {" "} <Link>{email}</Link> {i < bccEmailList.length - 1 ? "&" : " "}
            </>
        )}
        when sent. You may add additional email addresses in the recipients field
        </Text>
      </>)
    }
  }

  useEffect(() => {
    // set email recipient based on email type
    if (emailSettings && sendEmailFormData.emailType) {
      let emailRecipients = "";
      switch (sendEmailFormData.emailType) {
        case SelectedEmailTypeEnum.ApprovedOnsitePlacement:
          emailRecipients = getEmailFromSettings(
            "placementEmailRecipients",
            "supervisorsForPlacementEmail"
          );
          break;
        case SelectedEmailTypeEnum.AssociateRejectedPlacement:
          emailRecipients = getEmailFromSettings(
            "deniedEmailRecipients",
            "supervisorsForDeniedEmail"
          );
          break;
        case SelectedEmailTypeEnum.NoOnsitePlacementLOA:
          emailRecipients = getEmailFromSettings(
            "loaEmailRecipients",
            "supervisorsForLoaEmail"
          );
          break;
        case SelectedEmailTypeEnum.ReturnedtoFullDuty:
          emailRecipients = getEmailFromSettings(
            "rtfdEmailRecipients",
            "supervisorsForRtfdEmail"
          );
          break;
      }
      dispatch(setSendEmailFormData({ ...sendEmailFormData, emailRecipients }));
    }
  }, [emailSettings, sendEmailFormData.emailType]);

  useEffect(() => {
    // set input fields based on the email type
    const newInputFields: InputFieldsData[] = [
      {
        label: "Email Recipients",
        formDataKey: "emailRecipients",
        placeholder: "Enter semicolon separated email recipients",
        disabled: false,
      },
      {
        label: "AUSTIN Link",
        placeholder: getAustinLink(placementDetails.externalCaseId),
        disabled: true,
      },
      {
        label: "Placement Details",
        formDataKey: "placementDetails",
        placeholder: "Enter additional notes",
        disabled: false,
      },
    ];
    if (
      sendEmailFormData.emailType ===
      SelectedEmailTypeEnum.ApprovedOnsitePlacement
    ) {
      newInputFields[2] = {
        label: "Actions for HR",
        formDataKey: "actionsForHr",
        placeholder: "Enter additional notes",
        disabled: false,
      };
      newInputFields[3] = {
        label: "Area Manager Actions Needed",
        formDataKey: "areaManagerActionsNeeded",
        placeholder: "Enter additional notes",
        disabled: false,
      };
    }

    setInputFields(newInputFields);
  }, [sendEmailFormData.emailType]);

  return (
    <div>
      <Row widths={["fill"]} spacingInset={"200 none"}>
        <Column spacing="200">
          <Text tag="label" type="b200" color="primary">
            Email Type
          </Text>
          <Select
            value={sendEmailFormData.emailType}
            placeholder="Select an email type"
            onChange={(emailType) => handleChange("emailType", emailType)}
            error={sendEmailFormErrors.emailType?.error}
            errorMessage={sendEmailFormErrors.emailType?.errorMessage}
            data-cy="email-type__select"
          >
            {Object.values(SelectedEmailTypeEnum).map(
              (key: string, index: number) => (
                <SelectOption
                  label={key}
                  value={key}
                  key={key}
                  data-cy={`email-type__selectoption--${index}`}
                />
              )
            )}
          </Select>
        </Column>
      </Row>
      {sendEmailFormData.emailType &&
        inputFields?.map((row, index) => (
            <>
              <Row widths={["fill"]} spacingInset={"200 none"} key={index}>
                <Column spacing="200">
                  <Text tag="label" type="b200" color="primary">
                    {row.label}
                  </Text>
                  <Input
                    disabled={row.disabled}
                    value={row.formDataKey && sendEmailFormData[row.formDataKey]}
                    placeholder={row.placeholder}
                    error={
                      row.formDataKey && sendEmailFormErrors[row.formDataKey]?.error
                    }
                    errorMessage={
                      row.formDataKey &&
                      sendEmailFormErrors[row.formDataKey]?.errorMessage
                    }
                    onChange={(value) =>
                      row.formDataKey && handleChange(row.formDataKey, value)
                    }
                    data-cy={row.label.split(" ")[0]}
                  />
                </Column>
              </Row>
              {row.formDataKey === "emailRecipients" && getEmailBccList()}
            </>))}
    </div>
  );
};

export default commonFields;
