import Box from "@amzn/meridian/box";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Select, { SelectOption } from "@amzn/meridian/select";
import Text from "@amzn/meridian/text";
import Icon from "@amzn/meridian/icon";
import closeSmallTokens from "@amzn/meridian-tokens/base/icon/close-small";
import chevronRightSmallTokens from "@amzn/meridian-tokens/base/icon/chevron-right-small";
import chevronDownSmallTokens from "@amzn/meridian-tokens/base/icon/chevron-down-small";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  JmrFlowTypes,
  JmrTask,
  setRestrictionsFormErrors,
  setSelectedTaskNames,
  setSelectedTasks,
} from "src/store/newJmrPageSlice";
import { useAppSelector } from "src/store/store";
import { createSearchRegExp } from "src/utils/searchUtils";
import Input from "@amzn/meridian/input";
import _ from "lodash";
import IdentifyBodyLocationToggle from "./identifyBodyLocationToggle/identifyBodyLocationToggle";
import { getBodyLocationLabel } from "src/utils/getBodyLocationLabel";
import Coachmark from "@amzn/meridian/coachmark";
import Image from "@amzn/meridian/image";
import identifyBodyLocationImage from "src/images/coachmark/identify_body_location.png";
import { COACHMARK_IDENTIFY_BODY_LOCATION_DISMISSED } from "src/config/CoachmarkConstants";
import CoachmarkService from "src/services/coachmarkService";

const restrictionList = () => {
  const dispatch = useDispatch();
  const {
    regionTasks,
    selectedTaskNames,
    selectedTasks,
    restrictionsFormErrors,
    jmrFlowType,
  } = useAppSelector((state) => state.newJmrPage);
  const [coachmarkDismissed, setCoachmarkDismissed] = useState(CoachmarkService.isCoachmarkDismissed(COACHMARK_IDENTIFY_BODY_LOCATION_DISMISSED));
  const [tasksQuery, setTasksQuery] = useState();

  useEffect(() => {
    if(coachmarkDismissed) {
      CoachmarkService.dismissCoachmark(COACHMARK_IDENTIFY_BODY_LOCATION_DISMISSED);
    }
  }, [coachmarkDismissed]);

  const siteSearchRegExp = createSearchRegExp(tasksQuery);
  const matchedTaskOptions = regionTasks
    .map((task) => ({ label: task.label, value: task.label, rank: task.rank }))
    .filter((option) => !tasksQuery || siteSearchRegExp.test(option.label))
    .sort(
      (a, b) =>
        (a.rank !== null ? a.rank : Infinity) -
        (b.rank != null ? b.rank : Infinity)
    );

  // task will be removed from list
  const removeTask = (taskIndex: number) => {
    if (jmrFlowType !== JmrFlowTypes.UpdateDates) {
      const selectedTasksCopy = _.cloneDeep(selectedTasks);
      const selectedTaskNamesCopy = _.cloneDeep(selectedTaskNames);
      selectedTasksCopy.splice(taskIndex, 1);
      selectedTaskNamesCopy.splice(taskIndex, 1);
      dispatch(setSelectedTasks(selectedTasksCopy));
      dispatch(setSelectedTaskNames(selectedTaskNamesCopy));
    }
  };

  // set restricted value for a task
  const setRestrictedValue = (restrictedValue: string, task: JmrTask) => {
    dispatch(
      setSelectedTasks(
        selectedTasks.map((t) =>
          t.taskId === task.taskId ? { ...t, restrictedValue } : t
        )
      )
    );
    dispatch(
      setRestrictionsFormErrors({
        ...restrictionsFormErrors,
        [task.label]: undefined,
      })
    );
  };

  const setIdentifyBodyLocationValue = (bodyLocationValue: string | null, task: JmrTask) => {
    dispatch(
      setSelectedTasks(
        selectedTasks.map((t) =>
          t.taskId === task.taskId ? { ...t, bodyLocationValue } : t
        )
      )
    );
  };

  // toggle task
  const toggleTask = (task: JmrTask) => {
    dispatch(
      setSelectedTasks(
        selectedTasks.map((t) =>
          t.taskId === task.taskId ? { ...t, isExpanded: !t.isExpanded } : t
        )
      )
    );
  };

  // select tasks from dropdown
  const selectTasks = (taskNames: string[]) => {
    dispatch(setSelectedTaskNames(taskNames));
    dispatch(
      setSelectedTasks(
        taskNames.map((name: string) => {
          const existing = selectedTasks.filter((t) => t?.label === name);
          return existing.length > 0
            ? existing[0]
            : regionTasks.filter((t) => t.label === name)[0];
        })
      )
    );
  };

  const dismissCoachmark = () => {
    setCoachmarkDismissed(true);
  };

  return (
    <>
      <Column spacing={"200"}>
        <Text type="b300">Tasks</Text>
        <Text type="b300" color="secondary">
          You can choose multiple tasks at once from the drop-down.
        </Text>
      </Column>
      <Column spacing={"300"} spacingInset={"300 none 600 none"}>
        <Row widths={["fill","fit"]}>
          <Box maxWidth={"80%"}>
            <Select
              data-cy="new-jmr__restrictions__dropdown"
              searchQuery={tasksQuery}
              onSearch={setTasksQuery}
              placeholder="Select task(s)"
              value={selectedTaskNames}
              onChange={(taskNames) => {
                selectTasks(taskNames);
              }}
              disabled={jmrFlowType === JmrFlowTypes.UpdateDates}
            >
              {matchedTaskOptions.map((option) => (
                <SelectOption
                  label={option.label}
                  value={option.value}
                  key={option.value}
                  data-cy="new-jmr__restrictions__dropdown-item"
                />
              ))}
              {!matchedTaskOptions.length ? (
                <Column alignmentVertical="center" spacing="300" spacingInset="500">
                  <Text alignment="center">No tasks found</Text>
                </Column>
              ) : null}
            </Select>
          </Box>
          {!coachmarkDismissed && <Coachmark popoverPosition="top" onDismiss={dismissCoachmark}>
            <Column width="200px">
              <Image
                src={identifyBodyLocationImage}
                size="contain"
                viewportBackgroundColor="#B9E8F7"
              />
              <Text>Indicate which side of the body the task is restricted. 
                L/R/B is for notation only at this time and does not affect job matching.
              </Text>
            </Column>
          </Coachmark>}
        </Row>
        <Box maxWidth={"80%"}>
          {selectedTasks.map((task, index) => (
          <Row
            spacingInset={"200 none"}
            widths={["fill"]}
            className="new-jmr__restrictions-page__task"
            key={index}
            alignmentVertical={"center"}
          >
            <Box type="outline" spacingInset={"400"}>
              <Row spacing={"300"}>
                <div
                  data-cy={`new-jmr__restrictions__expand-btn-${index}`}
                  className="new-jmr__restrictions-page__task__expand-btn"
                  onClick={() => toggleTask(task)}
                >
                  <Icon
                    className="new-jmr__restrictions-page__caret"
                    tokens={
                      task.isExpanded
                        ? chevronDownSmallTokens
                        : chevronRightSmallTokens
                    }
                  />
                </div>
                <Text type="h100">{task.label}</Text>
                <div
                  className="new-jmr__restrictions-page__remove-task"
                  onClick={() => removeTask(index)}
                >
                  <Icon tokens={closeSmallTokens} />
                </div>
              </Row>
              {task.isExpanded && (
                <Row
                  widths={["10px", "700px"]}
                  spacingInset="300 none none none"
                >
                  <div></div>
                  {task.measureUnit === "YES/NO" ? (
                    <Text color="secondary">No</Text>
                  ) : (
                    <Column>
                      <Row widths={"500px"}>
                        <Input
                          disabled={jmrFlowType === JmrFlowTypes.UpdateDates}
                          data-cy={`new-jmr__restrictions__input-${index}`}
                          type="number"
                          placeholder={`Restricted ${task.measureUnit.toLowerCase()}`}
                          value={task.restrictedValue}
                          onChange={(restrictedValue) =>
                            setRestrictedValue(restrictedValue, task)
                          }
                          errorMessage={restrictionsFormErrors[task.label]}
                          error={restrictionsFormErrors[task.label] !== undefined}
                        />
                      </Row>
                      <Row>
                        {task.identifyBodyLocation && <IdentifyBodyLocationToggle task={task} setIdentifyBodyLocationValue={setIdentifyBodyLocationValue}/>}
                        {jmrFlowType === JmrFlowTypes.CompleteTransfer && !!task.bodyLocationValue && <IdentifyBodyLocationToggle task={task} setIdentifyBodyLocationValue={setIdentifyBodyLocationValue}/>}
                        {jmrFlowType === JmrFlowTypes.UpdateDates && !!task.bodyLocationValue && <Text color="secondary">Restricted body location: {getBodyLocationLabel(task.bodyLocationValue)}</Text>}
                      </Row>
                    </Column>
                  )}
                </Row>
              )}
            </Box>
          </Row>
        ))}
        </Box>
      </Column>
    </>
  );
};

export default restrictionList;
