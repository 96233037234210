import { TOAST_TIMEOUT } from "src/config/Toast";
import store from "src/store";
import { TableDataRow } from "src/store/taskConfigPageSlice";
import { createToast } from "src/store/toastsSlice";
import apiClient from "src/utils/apiClient";
import { SitePayload, SiteCapacityPayload } from "./types";

class SitesService {
  /**
   * get all sites
   * @returns {Object}
   */
  async getSites() {
    return await apiClient.get("/sites");
  }

  /**
   * get countries
   * @returns {Object}
   */
  async getCountries() {
    return await apiClient.get("/countries");
  }

  /**
   * get regions
   * @returns {Object}
   */
  async getRegions() {
    return await apiClient.get("/regions");
  }

  /**
   * get orgs
   * @returns {Object}
   */
  async getOrgs() {
    return await apiClient.get("/organizations");
  }

  /**
   * get orgs
   * @returns {Object}
   */
  async getSubOrgs() {
    return await apiClient.get("/suborganizations");
  }

  /**
   * get buildingTypes
   * @returns {Object}
   */
  async getBuildingTypes() {
    return await apiClient.get("/buildingTypes");
  }

  /**
   * Create or update site
   * @returns {Object}
   */
  async createOrUpdateSite(data: SitePayload, selectedSite: TableDataRow) {
    let res = null;
    if (selectedSite) {
      res = await apiClient.put(`/sites/${selectedSite.id}`, data);
    } else {
      res = await apiClient.post("/sites", data);
    }
    if (res) {
      store.dispatch(
        createToast({
          type: "success",
          message: selectedSite
            ? "Updated site successfully"
            : "Created site successfully",
          timeout: TOAST_TIMEOUT,
        })
      );
    }
    return res;
  }

  /**
   * get applicable jobs for a site
   * @returns {Object}
   */
  async getApplicableJobs(siteId: number) {
    return await apiClient.get(`/sites/${siteId}/applicableJobs`);
  }

  /**
   * Create or update site capacity
   * @returns {Object}
   */
  async createOrUpdateSiteCapacity(data: SiteCapacityPayload) {
    let res = await apiClient.post(`/sites/jobs`, data);

    if (res) {
      store.dispatch(
        createToast({
          type: "success",
          message: "Updated site capacity successfully",
          timeout: TOAST_TIMEOUT,
        })
      );
    }
    return res;
  }

  /**
   * get region specific sites
   * @returns {Object}
   */
  async getRegionSites(regionId: number) {
    return await apiClient.get(`/sites/region/${regionId}`);
  }

  /**
   * get all active sites
   */
  async getActiveSites() {
    return await apiClient.get(`/sites/active`);
  }
}

export default new SitesService();
