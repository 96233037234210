import apiClient from "src/utils/apiClient";
import { TOAST_TIMEOUT } from "src/config/Toast";
import store from "src/store";
import { createToast } from "src/store/toastsSlice";
import { Task } from "src/store/regionTasksPageSlice";
import { FormDataRow } from "src/store/regionTasksPageSlice";


class RegionTasksService {

    /**
     * get users site configs
     * @returns {Object}
     */
    getTasksByRegionId(regionId: number) {
        return apiClient.get(`/regionTasks/${regionId}`);
    }

    /**
     * update RestrictionForm tasks
     * @returns {Object}
     */
    async updateRegionTasks(data: Task[]) {
        const res = await apiClient.post("/regionTasks", data);
        if (res) {
            store.dispatch(
                createToast({
                    type: "success",
                    message: "Updated region tasks successfully",
                    timeout: TOAST_TIMEOUT,
                })
            );
        }
        return res;
    }

    /**
     * update RestrictionForm tasks
     * @returns {Object}
     */
    async updateRestrictionForm(data: FormDataRow) {
        const payload =  {
            id: data.id,
            name: data.name,
            restrictionFormName: data.formName,
            restrictionFormActive: data.isActive,
            restrictionFormDescription: data.description
        }
        const res = await apiClient.post("/regions", payload);
        if (res) {
            store.dispatch(
                createToast({
                    type: "success",
                    message: "Updated restriction form successfully",
                    timeout: TOAST_TIMEOUT,
                })
            );
        }
        return res;
    }
}

export default new RegionTasksService();