import React from "react";
import Tab, { TabGroup } from "@amzn/meridian/tab";
import { useAppSelector } from "src/store/store";
import Tooltip from "@amzn/meridian/tooltip";

interface tabGroupProps {
  currentTab: string;
  setCurrentTab: (newTab: string) => void;
}
const tabGroup = ({ currentTab, setCurrentTab }: tabGroupProps) => {
  const {
    activePlacementsTableData,
    expiredPlacementsTableData,
    pendingPlacementsTableData,
    closedPlacementsTableData,
    transferredInPlacementsTableData
  } = useAppSelector((state) => state.placementOverviewPage);
  const { featureFlags } = useAppSelector((state) => state.user);

  return (
    <TabGroup value={currentTab} onChange={setCurrentTab}>
      <Tab value="pendingPlacements">
        Pending Placements ({pendingPlacementsTableData.length})
      </Tab>
      <Tab value="expiredPlacements">
        Expired Placements ({expiredPlacementsTableData.length})
      </Tab>
      <Tab value="activePlacements">
        Active Placements ({activePlacementsTableData.length})
      </Tab>
      <Tab value="closedPlacements">
        Closed Placements ({closedPlacementsTableData.length})
      </Tab>
      {featureFlags.PLACEMENT_TRANSFER && transferredInPlacementsTableData.length > 0 &&
        <Tooltip
        position="top"
        title="Another site has transferred these placements to your site.
        Note: Filters do not apply to transferred in placements."
      >
        <Tab value="transferredInPlacements">
          Transferred In Placements ({transferredInPlacementsTableData.length})
        </Tab>
        </Tooltip>}
    </TabGroup>
  );
};

export default tabGroup;
