import React, { useRef, useState, useCallback } from "react";
import Popover, { PopoverHeader } from "@amzn/meridian/popover";
import Column from "@amzn/meridian/column";
import Button from "@amzn/meridian/button";
import SearchField from "@amzn/meridian/search-field";
import Table, { TableCell, TableRow } from "@amzn/meridian/table";
import { filterTableRows } from "src/utils/tableUtils";
import { Shift } from "src/store/updateSiteCapacitySlice";
import Text from "@amzn/meridian/text";

interface shiftsPerJobPopoverProps {
  shifts: Shift[];
}
const shiftsPerJobPopover = ({ shifts }: shiftsPerJobPopoverProps) => {
  const buttonRef = useRef();
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const onClickButton = useCallback(() => setOpen(true), []);
  const onClose = useCallback(() => setOpen(false), []);

  return (
    <React.Fragment>
      <Button
        onClick={onClickButton}
        ref={buttonRef}
        size="medium"
        type="link"
        className="site-capcity__shifts-popover-btn"
        disabled={shifts.length === 0}
      >
        {`${shifts?.length} shifts`}
      </Button>
      <Popover
        anchorNode={buttonRef.current}
        open={open}
        onClose={onClose}
        position="bottom"
        minWidth={448}
      >
        <PopoverHeader closeLabel="Close">Shifts</PopoverHeader>
        <SearchField
          value={searchText}
          onChange={setSearchText}
          size="small"
          onSubmit={() => {}}
          placeholder="Search for a shift..."
          width={300}
        ></SearchField>
        <Column
          height={"200px"}
          overflowY="scroll"
          spacingInset={"400 none none none"}
        >
          <Table
            headerRows={1}
            showDividers={true}
            fixHeaderRows={true}
            rowComponents={[TableRow]}
          >
            <TableRow>
              <TableCell>Shift Code</TableCell>
              <TableCell>Slots</TableCell>
              <TableCell>Total Shift Hrs</TableCell>
              <TableCell>Capacity Remaining</TableCell>
            </TableRow>
            {shifts &&
              filterTableRows(shifts, searchText, [
                "slots",
                "totalShiftHours",
              ]).map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.shiftCode}</TableCell>
                  <TableCell>{row.slots}</TableCell>
                  <TableCell>
                    {row.totalHours} hrs
                  </TableCell>
                  <TableCell>
                    {row.capacityRemaining === 0 ? (
                      <Text color="error">{row.capacityRemaining} hrs</Text>
                    ) :
                    (
                      <Text>{row.capacityRemaining} hrs</Text>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </Table>
        </Column>
      </Popover>
    </React.Fragment>
  );
};

export default shiftsPerJobPopover;
