import { TOAST_TIMEOUT } from "src/config/Toast";
import store from "src/store";
import { createToast } from "src/store/toastsSlice";
import apiClient from "src/utils/apiClient";

export interface ShiftPayload {
  id: number;
  siteId: number;
  shiftCode: string;
  shiftLengthHours: number;
  isActive: boolean;
  isDayShift: boolean;
  daysOfWeek: string[];
}

class ShiftsService {
  /**
   * get all shifts
   * @returns {Object}
   */
  async getShifts(siteId: number) {
    return await apiClient.get(`/shifts/sites/${siteId}`);
  }

  /**
   * Create or update shift (create if id not provided)
   * @returns {Object}
   */
  async createOrUpdateShift(data: ShiftPayload) {
    const res = await apiClient.post("/shifts", data);
    if (res) {
      store.dispatch(
        createToast({
          type: "success",
          message: data.id
            ? "Updated shift successfully"
            : "Created shift successfully",
          timeout: TOAST_TIMEOUT,
        })
      );
    }
    return res;
  }
}

export default new ShiftsService();
