import React, { useState, useCallback } from "react";
import Table, { TableRow, TableCell } from "@amzn/meridian/table";

// Expandable rows can be "uncontrolled", managing their own state.
// Be sure to pass the `rowComponents` prop to each Meridian row instance.
interface uncontrolledExpandableRowProps {
  children: any;
  rowComponents?: any;
  className?: string;
  onClick?: () => void;
}
const uncontrolledExpandableRow = ({
  children,
  rowComponents,
  onClick,
}: uncontrolledExpandableRowProps) => {
  const [open, setOpen] = useState(false);
  const toggleOpen = useCallback(() => {
    setOpen(!open);
    onClick?.();
  }, [open]);

  return (
    <TableRow open={open} onClick={toggleOpen} rowComponents={rowComponents}>
      {children}
    </TableRow>
  );
};

export default uncontrolledExpandableRow;
