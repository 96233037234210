import { AvailableFilterOptions } from "src/services/placementOverview/types/availableFilterOptions";
import { TableDataRow } from "src/services/placementOverview/types/tableDataRow";

class AvailableFilterOptionsService {
  extractAvailableFilterOptions(placementTableRows: TableDataRow[]): AvailableFilterOptions {
    let availableSites = this.extractAvailableSites(placementTableRows);
    let availableJobs = this.extractAvailableJobs(placementTableRows);
    let availableJobTypes = this.extractAvailableJobTypes(placementTableRows);
    let availableShiftCodes = this.extractAvailableShiftCodes(placementTableRows);
    let availableDaysInCategory = this.extractAvailableDaysInCategory(placementTableRows);
    let availablePlacementTypes = this.extractAvailablePlacementTypes(placementTableRows);

    return {
      availableSites,
      availableJobs,
      availableJobTypes,
      availableShiftCodes,
      availableDaysInCategory,
      availablePlacementTypes,
    };
  }

  private extractAvailableSites(placementTableRows: TableDataRow[]): string[] {
    const sortedAvailableSites = placementTableRows.map((placementTableDataRow) => placementTableDataRow.site).sort();
    return Array.from(new Set(sortedAvailableSites));
  }

  private extractAvailableJobs(placementTableRows: TableDataRow[]): string[] {
    const availableJobs = placementTableRows.filter((placementTableRows) => placementTableRows.jobsDisplayed)
      .map((placementTableDataRow) => placementTableDataRow.jobs);
    const sortedAndFlattenedAvailableJobs = availableJobs
      .reduce((acc, jobsArr) => acc.concat(jobsArr), [])
      .filter((job) => job !== null)
      .sort();

    return Array.from(new Set(sortedAndFlattenedAvailableJobs)) as string[];
  }

  private extractAvailableJobTypes(placementTableRows: TableDataRow[]): string[] {
    const availableJobTypes = placementTableRows.filter((placementTableRows) => placementTableRows.jobsDisplayed)
      .map((placementTableDataRow) => placementTableDataRow.jobTypes);
    const sortedAndFlattenedAvailableJobTypes = availableJobTypes
      .reduce((acc, jobTypesArr) => acc.concat(jobTypesArr), [])
      .filter((jobType) => jobType !== null && jobType !== undefined)
      .sort();

    return Array.from(new Set(sortedAndFlattenedAvailableJobTypes)) as string[];
  }

  private extractAvailableShiftCodes(placementTableRows: TableDataRow[]): string[] {
    const availableShiftCodes = placementTableRows.filter((placementTableRows) => placementTableRows.jobsDisplayed)
      .map((placementTableDataRow) => placementTableDataRow.shiftCodes);
    const sortedAndFlattenedAvailableShiftCodes = availableShiftCodes
      .reduce((acc, shiftCodeArr) => acc.concat(shiftCodeArr), [])
      .filter((shiftCode) => shiftCode !== null)
      .sort();
    return Array.from(new Set(sortedAndFlattenedAvailableShiftCodes)) as string[];
  }

  private extractAvailableDaysInCategory(placementTableRows: TableDataRow[]): number[] {
    const sortedAvailableDaysInCategory = placementTableRows
      .map((placementTableDataRow) => placementTableDataRow.daysInCategory)
      .sort((firstDaysInCategory, secondDaysInCategory) => firstDaysInCategory - secondDaysInCategory);
    return Array.from(new Set(sortedAvailableDaysInCategory));
  }

  private extractAvailablePlacementTypes(placementTableRows: TableDataRow[]): string[] {
    const availablePlacementTypes = placementTableRows
      .map((placementTableDataRow) => placementTableDataRow.placementType)
      .filter((placementType) => placementType !== null);

    const sortedAvailablePlacementTypes = [...availablePlacementTypes].sort();
    return Array.from(new Set(sortedAvailablePlacementTypes)) as string[];
  }
}

export default new AvailableFilterOptionsService();
