import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import React, { useEffect, useState } from "react";
import { TRANSFER_PENDING } from "src/config/AccommodationStatusNames";
import { COUNTRY_ID_MAPPING } from "src/config/CountryIdMapping";
import { useAppSelector } from "src/store/store";

interface RowItem {
  label: JSX.Element;
  value: JSX.Element;
  data_cy: string;
}
const topDetails = () => {
  const { placementDetails } = useAppSelector(
    (state) => state.placementDetailsPage
  );

  // format time to be displayed in UI per hours or days
  const formatTime = (time: number) => {
    const unit = time < 100 ? "Hours" : "Days";
    const formattedTime = time < 100 ? time : time / 24;
    return `${formattedTime.toFixed()} ${unit}`;
  };

  const [rows, setRows] = useState<RowItem[]>([]);

  useEffect(() => {
    const transferPendingRows = [
      {
        label: <>Placement Transfer Date</>,
        value: <>{placementDetails.transferredAtDate || ""}</>,
        data_cy: "placement-details__top-details__transferredAtDate"
      },
      {
        label: <>Transfer Pending Days</>,
        value: <>{placementDetails.timeInTransferPending || 0} days</>,
        data_cy: "placement-details__top-details__timeInTransferPending"
      }
    ];

    const otherStatusRows = [
      {
        label: <>Placement Start Date</>,
        value: <>{placementDetails.startAtDate || ""}</>,
        data_cy: "placement-details__top-details__startDate",
      },
      {
        label: <>Placement End Date</>,
        value: <>{placementDetails.endAtDate || ""}</>,
        data_cy: "placement-details__top-details__endDate",
      },
      {
        label: placementDetails.timeInPending ? (
          <>Time in Pending</>
        ) : (
          <>Placement Time</>
        ),
        value: (
          <>
            {placementDetails.timeInPending ? (
              <>{formatTime(placementDetails.timeInPending)}</>
            ) : (
              <>{`${placementDetails.placementTime || 0} days`}</>
            )}
          </>
        ),
        data_cy: "placement-details__top-details__timeInPending",
      },
    ];

    const rowsToDisplay = (placementDetails.accommodationStatus?.status === TRANSFER_PENDING) ?
      transferPendingRows : otherStatusRows;

    // add 180 day counter if country is not canada
    if (
      placementDetails.countryId !== undefined &&
      placementDetails.countryId !== COUNTRY_ID_MAPPING.CANADA_COUNTRY_ID
    ) {
      setRows([
        ...rowsToDisplay,
        {
          label: <>Total Days in Placement(s)</>,
          value: (
            <>{`${
              placementDetails.totalAccommodatedDaysByAssociate || 0
            } days`}</>
          ),
          data_cy: "placement-details__top-details__totalDays",
        },
      ]);
    } else {
      // if countryId is canada, set to first three rows
      setRows([...rowsToDisplay]);
    }
  }, [placementDetails]);

  return (
    <Row
      widths={["fill", "fill", "fill", "fill"]}
      spacingInset="400 none 500 none"
    >
      {rows.map((row, index) => (
        <div id="placement-details__top-detail-item" key={index}>
          <Row
            className="placement-details__top-detail-item"
            widths={["fill", "none"]}
          >
            <Text type="b300">{row.label}</Text>
            <Text type="b300" data-cy={row.data_cy}>
              {row.value}
            </Text>
          </Row>
        </div>
      ))}
    </Row>
  );
};

export default topDetails;
