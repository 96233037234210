import React from "react";
import { useDispatch } from "react-redux";
import {
    setSearchField,
} from "src/store/regionTasksPageSlice";
import Row from "@amzn/meridian/row";
import { useAppSelector } from "src/store/store";
import SearchField from "@amzn/meridian/search-field";

const topOptions = () => {
    const dispatch = useDispatch();
    const { searchField } = useAppSelector((state) => state.regionTasksConfigPage);

    return (
        <Row width={"grid-4"}
             spacingInset="400 none"
             alignmentHorizontal="justify"
        >
            <SearchField
              value={searchField}
              onChange={(newSearchField) =>
                dispatch(setSearchField(newSearchField))
              }
              placeholder="Search for..."
              onSubmit={() => {}}
            />
        </Row>
    );
};

export default topOptions;
