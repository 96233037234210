import Box from "@amzn/meridian/box";
import Column from "@amzn/meridian/column";
import Input from "@amzn/meridian/input";
import Text from "@amzn/meridian/text";
import React, { useEffect, useState } from "react";
import { ApprovalFormData } from "src/store/newJmrPageSlice";
import { useAppSelector } from "src/store/store";
import { getAustinLink } from "src/utils/helpers";

interface approvalEmailFormProps {
  handleChange: (key: keyof ApprovalFormData, value: any) => void;
}
const approvalEmailForm = ({ handleChange }: approvalEmailFormProps) => {
  const [selectedResultNames, setSelectedResultNames] = useState<string[]>([]);
  const {
    approvalFormData,
    approvalFormErrors,
    backgroundFormData,
    jmrResults,
    selectedTaskNames,
  } = useAppSelector((state) => state.newJmrPage);
  const { alias } = useAppSelector((state) => state.user);

  useEffect(() => {
    setSelectedResultNames(
      jmrResults
        .filter((jmrResult) => jmrResult.isChecked)
        .map((jmrResult) => jmrResult.name)
    );
  }, [jmrResults]);

  const rows = [
    {
      value: (
        <>{`Associate can work: 
  ${
    backgroundFormData.cannotWorkNormalShiftHrs
      ? backgroundFormData.limitedHoursPerDay
      : backgroundFormData.shiftLength
  }`}</>
      ),
    },
    {
      value: (
        <>{`AUSTIN/Gensuite Link: ${getAustinLink(
          backgroundFormData.caseID
        )}`}</>
      ),
    },
    {
      value: (
        <>{`Job Placement(s): 
    ${
      selectedResultNames.length > 0
        ? selectedResultNames.reduce((prev, curr) => `${prev}, ${curr}`)
        : "None"
    }`}</>
      ),
    },
    { value: <>Requestor: {alias}</> },
    {
      value: (
        <>{`Additional Modifications: 
    ${
      selectedTaskNames.length > 0
        ? selectedTaskNames.reduce((prev, curr) => `${prev}, ${curr}`)
        : "None"
    }`}</>
      ),
    },
  ];

  return (
    <div>
      <Text type="b300">
        Choose who to send this Job Placement to for approval. Content in this
        email cannot be edited. Note that this information will also send a
        webhook if setup. An email will be sent to you and anyone you specify
        below
      </Text>

      <Column spacingInset={"400 none 600 none"} spacing={"300"}>
        <Text type="b300">Email Recipients</Text>
        <Text type="b200" color="secondary">
          Please enter email addresses separated by a semi-colon. Addresses must
          include @amazon.com.
        </Text>
        <Input
          data-cy="new-jmr__approval__email"
          placeholder="e.g. jasrulo@amazon.com"
          value={approvalFormData.emailRecipients}
          onChange={(emailRecipients) =>
            handleChange("emailRecipients", emailRecipients)
          }
          error={approvalFormErrors.emailRecipients?.error}
          errorMessage={approvalFormErrors.emailRecipients?.errorMessage}
        />
        <Box type="outline" spacingInset={"400"}>
          <Text color={"secondary"} type={"b300"}>
            A Job Safety Analysis has been completed using the physical
            restrictions outlined by the Associate’s medical provider. Based on
            findings, the RTW tool has determined the following role(s) fits the
            below Associates restrictions.
          </Text>
          {rows.map((row, index) => (
            <Column
              key={index}
              spacingInset={index === 0 ? "500 none none none" : ""}
            >
              <Text color={"secondary"} type={"b300"}>
                {row.value}
              </Text>
            </Column>
          ))}
        </Box>
      </Column>
    </div>
  );
};

export default approvalEmailForm;
