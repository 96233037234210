import React, { useEffect, useState } from "react";
import { useAppSelector } from "src/store/store";
import { useDispatch } from "react-redux";
import Box from "@amzn/meridian/box";
import SearchField from "@amzn/meridian/search-field";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import Column from "@amzn/meridian/column";
import Input from "@amzn/meridian/input";
import { setSiteCapacityFormData } from "src/store/updateSiteCapacitySlice";
import { createSearchRegExp } from "src/utils/searchUtils";

const shiftList = () => {
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
  const { siteCapacityFormData, siteCapacityFormErrors } = useAppSelector(
    (state) => state.updateSiteCapacityPage
  );
  const searchRegExp = createSearchRegExp(searchValue);
  const matchedShifts = siteCapacityFormData?.shifts?.filter((shift) =>
    searchRegExp.test(shift?.shiftCode)
  );

  return (
    <Box
      type={matchedShifts && matchedShifts?.length > 0 ? "outline" : "fill"}
      spacingInset={"400"}
      overflowY={"scroll"}
      height={"240px"}
    >
      {matchedShifts?.length === 0 ? (
        <Column
          alignmentVertical="center"
          spacing="300"
          spacingInset="500"
          height="190px"
        >
          <Text alignment="center" color="secondary">
            No results
          </Text>
        </Column>
      ) : (
        <>
          <Row widths={["fill"]} spacingInset={"200"}>
            <SearchField
              value={searchValue}
              onChange={setSearchValue}
              onSubmit={() => {}}
              placeholder="Search for shift"
            />
          </Row>

          {matchedShifts?.map((shift, index) => (
            <Row widths={["fill"]} key={index}>
              <Column spacingInset={"300"} spacing="100">
                <Text>{shift?.shiftCode}</Text>
                <Input
                  error={siteCapacityFormErrors?.shifts[shift?.shiftCode]?.error}
                  errorMessage={
                    siteCapacityFormErrors?.shifts[shift?.shiftCode]
                      ?.errorMessage
                  }
                  placeholder="Select slot"
                  value={shift?.slots?.toString()}
                  onChange={(slots) => {
                    dispatch(
                      setSiteCapacityFormData({
                        ...siteCapacityFormData,
                        shifts: siteCapacityFormData?.shifts?.map((s) =>
                          s?.shiftCode === shift?.shiftCode
                            ? { ...s, slots }
                            : s
                        ),
                      })
                    );
                  }}
                  suffix="Slots"
                ></Input>
              </Column>
            </Row>
          ))}
        </>
      )}
    </Box>
  );
};

export default shiftList;
