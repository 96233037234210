import Row from "@amzn/meridian/row";
import Select, { SelectOption } from "@amzn/meridian/select";
import Text from "@amzn/meridian/text";
import RadioButton from "@amzn/meridian/radio-button";
import React, { useState } from "react";
import { setJobFormData } from "src/store/createNewJobSlice";
import { useAppSelector } from "src/store/store";
import { createSearchRegExp } from "src/utils/searchUtils";
import Column from "@amzn/meridian/column";
import Divider from "@amzn/meridian/divider";
import { useDispatch } from "react-redux";
import ButtonGroup, { ButtonOption } from "@amzn/meridian/button-group";

const jobSiteInfoFields = () => {
  const dispatch = useDispatch();
  const { isDisabled, specificSites, siteTypes, jobFormData, jobFormErrors } =
    useAppSelector((state) => state.createNewJobPage);
  const [siteTypeSearchQuery, setSiteTypeSearchQuery] = useState();
  const [applicableSitesSearchQuery, setApplicableSitesSearchQuery] =
    useState();

  const siteTypeSearchRegExp = createSearchRegExp(siteTypeSearchQuery);
  const applicableSitesSearchRegExp = createSearchRegExp(
    applicableSitesSearchQuery
  );

  const matchedSiteTypeOptions = siteTypes
    .map((siteTypes) => ({ label: siteTypes.label, value: siteTypes.label }))
    .filter(
      (option) =>
        !siteTypeSearchQuery || siteTypeSearchRegExp.test(option.label)
    );
  const matchedApplicableSitesOptions = specificSites
    .map((site) => ({ label: site.site, value: site.site }))
    .filter(
      (option) =>
        !applicableSitesSearchQuery ||
        applicableSitesSearchRegExp.test(option.label)
    );

  return (
    <div>
      <Text type="h300">Job Site Information</Text>
      <Row widths={"fill"} spacingInset={"400 none 300 none"}>
        <Text>Job Applicability</Text>
      </Row>
      {jobFormErrors?.jobApplicability?.error && (
        <Row widths={"fill"} spacingInset={"100 none 100 none"}>
          <Text
            type="b200"
            color="error"
            className="create-new-job__content__add-task-subtitle"
          >
            {jobFormErrors.jobApplicability?.errorMessage}
          </Text>
        </Row>
      )}
      <Row widths={"fill"} spacingInset={"300 none"}>
        <Column>
          <RadioButton
            checked={jobFormData?.jobApplicability === "siteType"}
            onChange={(jobApplicability) => {
              dispatch(setJobFormData({ ...jobFormData, jobApplicability }));
            }}
            value="siteType"
            disabled={isDisabled}
          >
            Site Type
          </RadioButton>
          <Select
            searchQuery={siteTypeSearchQuery}
            onSearch={setSiteTypeSearchQuery}
            placeholder="Enter site type..."
            value={
              jobFormData?.jobApplicability === "siteType"
                ? jobFormData?.siteType
                : ""
            }
            disabled={
              jobFormData?.jobApplicability !== "siteType" || isDisabled
            }
            onChange={(siteType) => {
              dispatch(
                setJobFormData({
                  ...jobFormData,
                  siteType,
                })
              );
            }}
            error={jobFormErrors?.jobApplicability?.error}
          >
            {matchedSiteTypeOptions.map((option) => (
              <SelectOption
                label={option.label}
                value={option.value}
                key={option.value}
              />
            ))}
            {!matchedSiteTypeOptions.length ? (
              <Column
                alignmentVertical="center"
                spacing="300"
                spacingInset="500"
              >
                <Text alignment="center">No results</Text>
              </Column>
            ) : null}
          </Select>
        </Column>
        <Column>
          <RadioButton
            checked={jobFormData?.jobApplicability === "siteSpecific"}
            onChange={(jobApplicability) => {
              dispatch(setJobFormData({ ...jobFormData, jobApplicability }));
            }}
            value="siteSpecific"
            disabled={isDisabled}
          >
            Site Specific
          </RadioButton>
          <Select
            searchQuery={applicableSitesSearchQuery}
            onSearch={setApplicableSitesSearchQuery}
            placeholder={
              jobFormData?.region
                ? "Enter specific sites..."
                : "Select a region first"
            }
            disabled={
              jobFormData?.jobApplicability !== "siteSpecific" ||
              isDisabled ||
              !jobFormData.region
            }
            value={
              jobFormData?.jobApplicability === "siteSpecific"
                ? jobFormData?.applicableSites
                : ""
            }
            onChange={(applicableSites) => {
              dispatch(
                setJobFormData({
                  ...jobFormData,
                  applicableSites,
                })
              );
            }}
            error={jobFormErrors?.jobApplicability?.error}
          >
            {matchedApplicableSitesOptions.map((option) => (
              <SelectOption
                label={option.label}
                value={option.value}
                key={option.value}
              />
            ))}
            {!matchedApplicableSitesOptions.length ? (
              <Column
                alignmentVertical="center"
                spacing="300"
                spacingInset="500"
              >
                <Text alignment="center">No results</Text>
              </Column>
            ) : null}
          </Select>
        </Column>
      </Row>
      <Row widths={"fill"} spacingInset={"300 none 300 none"}>
        <Column spacing={"300"}>
          <Text tag="label">Job Type</Text>
          {jobFormErrors?.jobType?.error && (
            <Text
              type="b200"
              color="error"
              className="create-new-job__content__add-task-subtitle"
            >
              {jobFormErrors.jobType?.errorMessage}
            </Text>
          )}
          <ButtonGroup
            value={jobFormData?.jobType}
            onChange={(jobType) => {
              dispatch(
                setJobFormData({
                  ...jobFormData,
                  jobType,
                })
              );
            }}
          >
            <ButtonOption disabled={isDisabled} value="Process Path">
              Process Path
            </ButtonOption>
            <ButtonOption disabled={isDisabled} value="TLD Assignment">
              TLD Assignment
            </ButtonOption>
          </ButtonGroup>
        </Column>
      </Row>
      <Divider spacingAfter={"500"} spacingBefore={"450"} />
    </div>
  );
};

export default jobSiteInfoFields;
