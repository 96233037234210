import { createSlice } from "@reduxjs/toolkit";

export type adminConfigState = {
    selectedTab: string
};

const initialState: adminConfigState = {
    selectedTab: "site-config"
};
/** Default Settings Page Slice */
const { reducer, actions } = createSlice({
    name: "adminConfigPageSlice",
    initialState,
    reducers: {
        setSelectedTab: (state, action) => {
            state.selectedTab = action.payload;
        }
    },
});

export const {
    setSelectedTab
} = actions;

export default reducer;
