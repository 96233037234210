import React from "react";
import Tab, { TabGroup } from "@amzn/meridian/tab";

interface tabGroupProps {
  currentTab: string;
  setCurrentTab: (newTab: string) => void;
}
const tabGroup = ({ currentTab, setCurrentTab }: tabGroupProps) => {
  return (
    <TabGroup value={currentTab} onChange={setCurrentTab}>
      <Tab value="update-site-capacity">Update Site Capacity</Tab>
      <Tab value="update-webhooks">Update Webhooks</Tab>
      <Tab value="update-default-emails" data-cy={"update-default-emails__tab"}>
        Update Default Emails
      </Tab>
      <Tab value="update-shifts">Update Shifts</Tab>
    </TabGroup>
  );
};

export default tabGroup;
