import {
  BackgroundFormData,
  JmrTask,
  TableDataRow,
} from "src/store/newJmrPageSlice";

/**
 *  Calculate the placement hours for a row
 */
export const calculatePlacementHours = (row: TableDataRow, task: JmrTask) => {
  // get the percentage from the job requirements
  const requirmentValues = row.jobRequirements?.filter(
    (t) => t.task.id === task.taskId
  );
  // multiply by the assigned hours of each job
  const assignedValue =
    requirmentValues?.length > 0
      ? +(+requirmentValues[0].value * +row.totalHours).toFixed(1)
      : 0;
  return assignedValue;
};

/**
 *  Calculate the total placement hours for a table
 */
export const calculateTotalHours = (
  task: JmrTask,
  selectedResults: TableDataRow[]
) => {
  return selectedResults.length > 0
    ? +selectedResults
        .map((r) => calculatePlacementHours(r, task))
        .reduce((prev, curr) => prev + curr)
        .toFixed(1)
    : 0;
};

/**
 *  Calculate the unused hours in a task
 */
export const calculateUnusedHoursForTask = (
  task: JmrTask,
  selectedResults: TableDataRow[]
) => {
  return +(
    +task.restrictedValue - calculateTotalHours(task, selectedResults)
  ).toFixed(1);
};

/**
 *  Calculate unused total hours for a table
 */
export const calculateUnusedTotalHours = (
  selectedResults: TableDataRow[],
  backgroundFormData: BackgroundFormData
) => {
  const totalHoursSum =
    selectedResults.length > 0
      ? selectedResults
          .map((r) => +r.totalHours)
          .reduce((prev, curr) => prev + curr)
      : 0;
  const shiftLength = backgroundFormData.limitedHoursPerDay
    ? backgroundFormData.limitedHoursPerDay
    : backgroundFormData.shiftLength;
  return +shiftLength - totalHoursSum;
};

/**
 *  Validate total unused hours based on the contraints in background page
 */
export const isTotalUnusedHoursValid = (
  totalUnusedHours: number,
  shiftLength: number,
  limitedHoursPerDay?: number
) => {
  const totalCap = limitedHoursPerDay ? limitedHoursPerDay : shiftLength;
  return totalUnusedHours >= 0 && totalUnusedHours < totalCap;
};

export const areAllUsedHoursMoreThanZero = (selectedJobs: TableDataRow[]) => {
  return selectedJobs.every(selectedJob => +selectedJob.totalHours > 0);
}
