import apiClient from "../utils/apiClient";

class PeopleApiService {
  /**
   * get employee info
   * @returns {Object}
   */
  async getEmployeeInfo(login: string) {
    return await apiClient.get(`employee/login/${login}`);
  }
}

export default new PeopleApiService();
