import Button from "@amzn/meridian/button";
import Modal from "@amzn/meridian/modal";
import Row from "@amzn/meridian/row";
import React, { useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "src/store/store";
import {
  setIsInitiateTransferModalOpen,
  getEmailsForTransferPlacement,
  setIsConfirmTransferModalOpen,
  setConfirmTransferPayload
} from "src/store/placementDetailsPageSlice/placementDetailsPageSlice";
import { getAllActiveSites } from "src/store/siteSlice";
import Text from "@amzn/meridian/text";
import Select, { SelectOption } from "@amzn/meridian/select";
import Column from "@amzn/meridian/column";
import Textarea from "@amzn/meridian/textarea";
import { createSearchRegExp } from "src/utils/searchUtils";

const initiateTransferModal = () => {
  const [searchQuery, setSearchQuery] = useState();
  const [selectedSiteId, setSelectedSiteId] = useState(undefined);
  const [emailRecipients, setEmailRecipients] = useState("");
  const { isInitiateTransferModalOpen, transferModalEmails } = useAppSelector((state) => state.placementDetailsPage);
  const { activeSites } = useAppSelector((state) => state.sites);

  const searchRegExp = createSearchRegExp(searchQuery);
  const matchedSiteOptions = activeSites
    .filter((option) => !searchQuery || searchRegExp.test(option.site));
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isInitiateTransferModalOpen) {
      dispatch(getAllActiveSites());
    }
  }, [isInitiateTransferModalOpen]);

  useEffect(() => {
    if (selectedSiteId !== undefined) {
      dispatch(getEmailsForTransferPlacement(selectedSiteId!));
    }
  }, [selectedSiteId]);

  useEffect(() => {
    setEmailRecipients(transferModalEmails);
  }, [transferModalEmails]);

  return (
    <Modal
      title='Initiate Transfer'
      open={isInitiateTransferModalOpen}
      onClose={() => dispatch(setIsInitiateTransferModalOpen(false))}
      width={"416px"}
    >
      <Column>
        <Row>
          <Text>
            Please note that you are transferring ownership of the RTW placement to the site selected below. Once
            transferred, your site will no longer have access to this RTW placement.
          </Text>
        </Row>
        <Row widths={["fit", "fill"]}>
          <Text tag='label' type='b300' color='primary'>
            Site
          </Text>
          <Select
            searchQuery={searchQuery}
            onSearch={setSearchQuery}
            value={selectedSiteId}
            placeholder='Select site to transfer placement to'
            onChange={(siteId) => setSelectedSiteId(siteId)}
          >
            {matchedSiteOptions.map((site) => (
              <SelectOption label={site.site} value={site.id} key={site.id}></SelectOption>
            ))}
          </Select>
        </Row>

        {selectedSiteId !== undefined && (
          <Column>
            <Text>
              Recipients populated below originate from the "Approved Onsite Placement" email settings at both sites
              involved in the transfer. You may add additional recipients if necessary.
            </Text>
            <Row widths={["grid-12"]}>
              <Textarea
                size='medium'
                value={emailRecipients}
                onChange={(val) => {
                  setEmailRecipients(val);
                }}
                label='Email recipients'
                error={false}
              />
            </Row>
          </Column>
        )}

        <Row widths={["fill", "fit"]}>
          <div />
          <Button
            disabled={selectedSiteId === undefined}
            onClick={() => {
              const payload = { destinationSiteId: selectedSiteId!, emailRecipients: emailRecipients }
              dispatch(setIsInitiateTransferModalOpen(false));
              dispatch(setConfirmTransferPayload(payload));
              dispatch(setIsConfirmTransferModalOpen(true));
            }}
          >
            Transfer
          </Button>
        </Row>
      </Column>
    </Modal>
  );
};

export default initiateTransferModal;
