import React, { useState } from "react";
import Text from "@amzn/meridian/text";
import Column from "@amzn/meridian/column";
import Select, { SelectOption } from "@amzn/meridian/select";
import Row from "@amzn/meridian/row";
import { useAppSelector } from "src/store/store";
import { useDispatch } from "react-redux";
import { setDefaultHomesiteDropdownValue } from "src/store/defaultSettingsPageSlice";
import { createSearchRegExp } from "src/utils/searchUtils";

const singleSiteSelection = () => {
  const dispatch = useDispatch();
  const { allowedSites } = useAppSelector((state) => state.sites);
  const {
    pageDisabled,
    areMultipleSitesSelected,
    defaultHomesiteDropdownValue,
  } = useAppSelector((state) => state.defaultSettingsPage);
  const [searchQuery, setSearchQuery] = useState();
  const searchRegExp = createSearchRegExp(searchQuery);

  const matchedSiteOptions = allowedSites
    .map((site) => ({ label: site.site, value: site.id }))
    .filter((option) => !searchQuery || searchRegExp.test(option.label));

  return (
    <Row widths={["grid-4", "grid-2"]}>
      <Text>Your Default Home Site</Text>
      <Select
        size="small"
        value={defaultHomesiteDropdownValue}
        onChange={(newValue) =>
          dispatch(setDefaultHomesiteDropdownValue(newValue))
        }
        searchQuery={searchQuery}
        onSearch={setSearchQuery}
        disabled={areMultipleSitesSelected || pageDisabled}
        placeholder="Enter value..."
        data-cy="default-settings__single-site-select"
      >
        {matchedSiteOptions.map((option) => (
          <SelectOption
            label={option.label}
            value={option.value}
            key={option.value}
            data-cy="default-settings__single-site-select-option"
          />
        ))}
        {!matchedSiteOptions.length ? (
          <Column alignmentVertical="center" spacing="300" spacingInset="500">
            <Text alignment="center">No results</Text>
          </Column>
        ) : null}
      </Select>
    </Row>
  );
};

export default singleSiteSelection;
