import React from "react";
import TopOptions from "./topOptions/topOptions";
import TaskTable from "./taskTable/taskTable";
import TaskModal from "./taskModal/taskModal";
import Text from "@amzn/meridian/text";
import "./taskConfiguration.scss";
import PaginationWithDropdown from "src/components/shared/paginationWithDropdown/paginationWithDropdown";
import { useAppSelector } from "src/store/store";
import { useDispatch } from "react-redux";
import { setPerPage, setCurrentPage } from "src/store/taskConfigPageSlice";

const viewAndEditTasks = () => {
  const dispatch = useDispatch();

  const { currentPage, totalPages, perPage } = useAppSelector(
    (state) => state.taskConfigPage
  );

  return (
    <div className="view-and-edit-tasks">
      <Text className="view-and-edit-tasks__content__text">
        Task configuration allows you to update the global dictionary to
        represent all possible physical actions an Amazon job might require and
        how the task is measured
      </Text>
      <TopOptions />
      <TaskTable />
      <PaginationWithDropdown
        currentPage={currentPage}
        totalPages={totalPages}
        perPage={perPage}
        setPerPage={(newPerPage) => dispatch(setPerPage(newPerPage))}
        setCurrentPage={(newPage) => dispatch(setCurrentPage(newPage))}
      />
      <TaskModal />
    </div>
  );
};

export default viewAndEditTasks;
