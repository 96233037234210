import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import TasksService, { TaskPayload } from "src/services/tasksService";
import { DEFAULT_TABLE_PAGE_SIZE } from "src/constants/Pagination";

export interface Region {
  id: number;
  name: string;
  country?: Region;
  restrictionFormName?: string;
}

export interface MeasureUnit {
  id: number;
  name: string;
}

export interface TableDataRow {
  id: number;
  label: string;
  regions: string[];
  countries: (string | undefined)[];
  identifyBodyLocation: boolean;
  isMeasuredBy: string;
  description: string;
}

export interface TaskModalFieldLength {
  maxTaskNameCharLength: number;
  maxDescriptionCharLength: number;
}

const taskModalFieldLengthValues: TaskModalFieldLength = {
  maxTaskNameCharLength: 255,
  maxDescriptionCharLength: 100,
}

export type TaskConfigPageState = {
  tableData: TableDataRow[];
  currentPage: number;
  totalPages: number;
  perPage: number;
  searchField: string;
  isCreateModalOpen: boolean;
  regions: Region[];
  unitsOfMeasure: MeasureUnit[];
  selectedTask?: TableDataRow;
  taskModalFieldLengths: TaskModalFieldLength;
};

const initialState: TaskConfigPageState = {
  tableData: [],
  currentPage: 1,
  totalPages: 0,
  perPage: DEFAULT_TABLE_PAGE_SIZE,
  searchField: "",
  isCreateModalOpen: false,
  unitsOfMeasure: [],
  regions: [],
  taskModalFieldLengths: taskModalFieldLengthValues,
};

/**
 * convert task object to a table row
 */
export const taskToTableRow = (task: TaskPayload): TableDataRow => {
  const tableRow: TableDataRow = {
    label: task.label,
    description: task.description ?? "",
    regions: task.regions?.map((r: Region) => r.name),
    countries: task.regions?.map((r: Region) => r.country?.name),
    isMeasuredBy: task.measureUnit?.name,
    identifyBodyLocation: task.identifyBodyLocation ? true : false,
  } as TableDataRow;
  task.id !== undefined ? (tableRow.id = task.id) : "";
  return tableRow;
};

/**
 * convert a table row to a task object
 */
export const tableRowToTask = (
  tableRow: TableDataRow,
  regions: Region[],
  units: MeasureUnit[]
): TaskPayload => {
  const task: TaskPayload = {
    label: tableRow.label,
    description: tableRow.description,
    identifyBodyLocation: tableRow.identifyBodyLocation,
    regions: tableRow.regions.map(
      (rName: string) => regions.filter((r: Region) => rName === r.name)[0]
    ),
    measureUnit: units.filter(
      (unit: MeasureUnit) => tableRow.isMeasuredBy === unit.name
    )[0],
    // these fields need to be passed because backend requires it currently
    // will be removed later when backend fixed
    createdAt: "2022-02-25T23:06:47.000+00:00",
    updatedAt: new Date().toISOString(),
    id : tableRow.id !== undefined ? tableRow.id : ""
  } as TaskPayload;
  return task;
};

/**
 * get all tasks
 * @returns {Object}
 */
export const getTasks = createAsyncThunk("tasks/getTasks", async () => {
  const { data } = await TasksService.getTasks();
  return data;
});

/**
 * get units of measure
 * @returns {Object}
 */
export const getUnitsOfMeasure = createAsyncThunk(
  "tasks/getUnitOfMeasure",
  async () => {
    const { data } = await TasksService.getUnitsOfMeasure();
    return data;
  }
);

/**
 * get regions
 * @returns {Object}
 */
export const getRegions = createAsyncThunk("tasks/getRegions", async () => {
  const { data } = await TasksService.getRegions();
  return data;
});

export const addOrEditTask = createAsyncThunk(
  "tasks/createOrUpdateTask",
  async (task: any, { getState }) => {
    const state: any = getState();
    let payload = tableRowToTask(
      task,
      state.taskConfigPage.regions,
      state.taskConfigPage.unitsOfMeasure
    );
    const res = await TasksService.createOrUpdateTask(payload);

    if (res) {
      payload.id = res.data.id;
      return taskToTableRow(payload);
    }
  }
);

/** Task Config Page Slice */
const { reducer, actions } = createSlice({
  name: "taskConfigPageSlice",
  initialState,
  reducers: {
    setSearchField: (state, action) => {
      state.searchField = action.payload;
    },
    setIsCreateModalOpen: (state, action) => {
      state.isCreateModalOpen = action.payload;
    },
    setSelectedTask: (state, action) => {
      state.selectedTask = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setTotalPages: (state, action) => {
      state.totalPages = action.payload;
    },
    setPerPage: (state, action) => {
      state.perPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTasks.fulfilled, (state, { payload }) => {
      state.tableData = payload.map((task: any) => taskToTableRow(task));
    });
    builder.addCase(getUnitsOfMeasure.fulfilled, (state, { payload }) => {
      state.unitsOfMeasure = payload;
    });
    builder.addCase(getRegions.fulfilled, (state, { payload }) => {
      state.regions = payload;
    });
    builder.addCase(addOrEditTask.fulfilled, (state, { payload }) => {
      if (payload) {
        state.searchField = payload.label;

        const isAlreadyInTable = state.tableData.some((row) => {
          return row.id === payload.id;
        });

        if (isAlreadyInTable) {
          // this will be the case when editing an existing site
          state.tableData = state.tableData.map((row) =>
            row.id === payload.id ? payload : row
          );
        } else {
          // this will be the case when adding a new site
          state.tableData = [...state.tableData, payload];
        }
      }
    });
  },
});

export const {
  setSearchField,
  setIsCreateModalOpen,
  setSelectedTask,
  setCurrentPage,
  setTotalPages,
  setPerPage,
} = actions;

export default reducer;
