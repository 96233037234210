import React, { useEffect } from "react";
import TopOptions from "./topOptions/topOptions";
import SiteCapacityTable from "./siteCapacityTable/siteCapacityTable";
import SiteCapacityModal from "./siteCapacityModal/siteCapacityModal";
import PaginationWithDropdown from "src/components/shared/paginationWithDropdown/paginationWithDropdown";
import "./updateSiteCapacity.scss";
import { useAppSelector } from "src/store/store";
import LoadingSpinner from "src/components/shared/loadingSpinner/loadingSpinner";
import { useDispatch } from "react-redux";
import {
  getShifts,
  getSiteCapacity,
  setCurrentPage,
  setPerPage,
  setSearchField,
  downloadSiteCapacitySummaryCsv
} from "src/store/updateSiteCapacitySlice";
import Text from "@amzn/meridian/text";
import Heading from "@amzn/meridian/heading";
import Button from "@amzn/meridian/button";
import Row from "@amzn/meridian/row";

const updateSiteCapacity = () => {
  const dispatch = useDispatch();
  const { isLoading, currentPage, perPage, totalPages } = useAppSelector(
    (state) => state.updateSiteCapacityPage
  );
  const { preferredSite } = useAppSelector((state) => state.user);
  const { userLoading } = useAppSelector((state) => state.user);

  useEffect(() => {
    // TODO when user first time log into website and they have no preferredSite
    // we should either give them a preferredSite or show message let them pick a site
    if (userLoading === "fulfilled" && preferredSite !== 0) {
      dispatch(setCurrentPage(1));
      dispatch(getSiteCapacity());
      dispatch(getShifts());
    }
    return () => {
      dispatch(setSearchField(""));
    };
  }, [userLoading, preferredSite]);

  return (
    <div className="update-site-capacity">
      {!isLoading ? (
        <>
          <Row width="100%" widths={["grid-10", "grid-2"]}>
            <Heading
              level={1}
              type="h500"
              className="update-site-capacity__page-heading"
            >
              Update Site Capacity
            </Heading>
            <Button
              id="update-placement-status-save-btn"
              type="primary"
              size="medium"
              onClick={() => dispatch(downloadSiteCapacitySummaryCsv())}
            >
              Export Capacity Summary
            </Button>
          </Row>
          <Text>
            Here you can view and edit jobs available at your site, as well as
            capacity available for accommodations per shift
          </Text>
          <TopOptions />
          <SiteCapacityTable />
          <PaginationWithDropdown
            currentPage={currentPage}
            totalPages={totalPages}
            perPage={perPage}
            setPerPage={(newPerPage) => dispatch(setPerPage(newPerPage))}
            setCurrentPage={(newPage) => dispatch(setCurrentPage(newPage))}
          />
          <SiteCapacityModal />
        </>
      ) : (
        <LoadingSpinner />
      )}
    </div>
  );
};

export default updateSiteCapacity;
