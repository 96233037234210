import React from "react";
import Text from "@amzn/meridian/text";
import "./unauthorized.scss";
import { SUBMIT_FEATURE_REQUEST_AND_BUG_URL, RTW_WIKI_URL } from "src/config/ExternalLinks";

const unauthorized = () => {
    return (
        <div className="unauthorized" >
            <Text type = "d50">Looks like you don't have the correct permissions to access this tool.
                Please request permissions via the <a target="_blank" rel="noopener noreferrer" href={SUBMIT_FEATURE_REQUEST_AND_BUG_URL}>RTW River</a>,
                or reference the <a target="_blank" rel="noopener noreferrer" href={RTW_WIKI_URL}>RTW Wiki</a> for further information.
            </Text>
        </div>
    )};

export default unauthorized;