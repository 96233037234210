import React from "react";
import Text from "@amzn/meridian/text";
import "./restrictionFormConfiguration.scss";
import RegionTaskTable from "./restrictionFormTable/restrictionFormTable";
import TopOptions from "./topOptions/topOptions";
import RegionTaskPagination from "./restrictionFormPagination/restrictionFormPagination";
import "./restrictionFormConfiguration.scss";

const viewAndEditForms = () => {
  return (
    <div className="view-and-edit-forms">
      <Text className="view-and-edit-forms__content__text">
        Here you can update the regional work Restriction/Requirement forms
        available within the RTW tool
      </Text>
      <TopOptions />
      <RegionTaskTable />
      <RegionTaskPagination />
    </div>
  );
};

export default viewAndEditForms;
