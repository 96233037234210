import Row from "@amzn/meridian/row";
import Tab, { TabGroup } from "@amzn/meridian/tab";
import React from "react";
import { useAppSelector } from "src/store/store";
import { useDispatch } from "react-redux";
import { setJmrFilter } from "src/store/newJmrPageSlice";

const topOptions = () => {
  const dispatch = useDispatch();
  const { jmrFilter } = useAppSelector((state) => state.newJmrPage);
  return (
    <Row widths={["fill", "170px"]}>
      <TabGroup
        value={jmrFilter.jobType}
        onChange={(jobType) =>
          dispatch(setJmrFilter({ ...jmrFilter, jobType }))
        }
      >
        <Tab value="Process Path" data-cy="new-jmr__results__process-path-btn">
          Process Path
        </Tab>
        <Tab value="TLD Assignment" data-cy="new-jmr__results__tld-btn">
          TLD
        </Tab>
      </TabGroup>
    </Row>
  );
};

export default topOptions;
