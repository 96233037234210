import React, { useState, useEffect } from "react";
import { useAppSelector } from "src/store/store";
import AustinOption from "./austinOption";
import BaliOrDaliOption from "./baliOrDaliOption";
import ButtonGroup from "@amzn/meridian/button-group";
import { BackgroundFormData } from "src/store/newJmrPageSlice";
import { COUNTRY_ID_MAPPING } from "src/config/CountryIdMapping";
import { DALI, BALI } from "./constant";

interface adminOptionProps {
  handleChange: (key: keyof BackgroundFormData, value: any) => void;
}

const adminOption = ({ handleChange }: adminOptionProps) => {
  const { backgroundFormData } = useAppSelector((state) => state.newJmrPage);
  const isCanadian =
    backgroundFormData.site?.countryId === COUNTRY_ID_MAPPING.CANADA_COUNTRY_ID;

  const [baliOrDali, setbaliOrDali] = useState(DALI);
  useEffect(
    () => (isCanadian ? setbaliOrDali(BALI) : setbaliOrDali(DALI)),
    [backgroundFormData.site?.countryId]
  );
  return (
    <ButtonGroup
      value={backgroundFormData?.caseSource}
      onChange={(caseSource) => {
        handleChange("caseSource", caseSource);
      }}
    >
      <AustinOption disabled={false}></AustinOption>
      <BaliOrDaliOption
        disabled={false}
        baliOrDali={baliOrDali}
      ></BaliOrDaliOption>
    </ButtonGroup>
  );
};

export default adminOption;