export const TRANSFER_PENDING =  "Transfer Pending";
export const ALTERNATIVE_PLACEMENT_RECOMMENDED = "Alternative Placement Recommended";
export const NEW_RESTRICTIONS_RECEIVED = "New Restrictions Received";
export const PLACEMENT_DECISION_PENDING = "Placement Decision Pending";
export const PROVIDER_PLACED_OFF_WORK = "Provider Placed off Work";
export const ASSOCIATE_DECISION_PENDING = "Associate Decision Pending";
export const APPROVED_TWP = "Approved TWP";
export const DENIED_TWP = "Denied TWP";
export const INDEFINITE_RESTRICTIONS = "Indefinite Restrictions";
export const DLS_REFERRAL_RECEIVED = "DLS Referral Received";
export const TWP_BUT_PENDING_UPDATED_RESTRICTIONS = "TWP but Pending Updated Restrictions";