import React from "react";
import Row from "@amzn/meridian/row";
import { useDispatch } from "react-redux";
import { setCurrentPage, setPerPage } from "src/store/regionTasksPageSlice";
import { useAppSelector } from "src/store/store";
import PaginationWithDropdown from "src/components/shared/paginationWithDropdown/paginationWithDropdown";

const restrictionFormPagination = () => {
  const dispatch = useDispatch();
  const { currentPage, totalPages, perPage } = useAppSelector(
    (state) => state.regionTasksConfigPage
  );

  return (
    <PaginationWithDropdown
      currentPage={currentPage}
      totalPages={totalPages}
      perPage={perPage}
      setPerPage={(newPerPage) => dispatch(setPerPage(newPerPage))}
      setCurrentPage={(newPage) => dispatch(setCurrentPage(newPage))}
    />
  );
};

export default restrictionFormPagination;
