import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DEFAULT_TABLE_PAGE_SIZE } from "src/constants/Pagination";
import ShiftsService, { ShiftPayload } from "src/services/shiftsService";
import SitesService from "src/services/sites/sitesService";
import {SiteCapacityPayload} from "src/services/sites/types";
import CapacityUsageService from "../services/capacityUsage/capacityUsageService";
import { SiteJobCapacityUsageData } from "../services/capacityUsage/types/siteJobCapacityUsageData";
import { downloadFile, createFileUrl } from "src/utils/fileUtils";

export interface TableDataRow {
  jobId: number;
  name: string;
  jobType: string;
  siteSpecific: string;
  active: string;
  shifts: Shift[];
  totalAccommodation: number;
  shiftNames: string[];
  shiftsPerJob: number;
  capacityRemaining: number;
  currentOpenAccommodations: number;
  allocatedHours: number;
}

export interface Shift {
  shiftId: number;
  shiftCode: string;
  shiftLengthHours: number;
  slots: string;
  totalHours: number;
  capacityRemaining: number;
  allocatedHours: number;
}

export interface Capacity {
  siteId: number;
  jobId: number;
  active: boolean;
  shifts: Shift[];
  totalHours: number;
}

export interface ApplicableJob {
  jobId: number;
  name: string;
  jobType: string;
  siteSpecific: boolean;
  capacity?: Capacity;
}

export interface SiteCapacityUpdateResponse extends Capacity, ApplicableJob {}

export type UpdateSiteCapacityState = {
  tableData: TableDataRow[];
  currentPage: number;
  totalPages: number;
  perPage: number;
  searchField: string;
  isCreateModalOpen: boolean;
  displayedShiftsPerJob: Shift[];
  siteCapacityFormData?: Partial<TableDataRow>;
  siteCapacityFormErrors?: any;
  shifts: ShiftPayload[];
  isLoading: boolean;
};

const initialState: UpdateSiteCapacityState = {
  tableData: [],
  currentPage: 1,
  totalPages: 0,
  perPage: DEFAULT_TABLE_PAGE_SIZE,
  searchField: "",
  isCreateModalOpen: false,
  isLoading: true,
  displayedShiftsPerJob: [],
  shifts: [],
  siteCapacityFormData: { shiftNames: [], shifts: [] },
};

const updateSiteCapacityToTableRow = (
  res: SiteCapacityUpdateResponse,
  row: TableDataRow
): TableDataRow => {
  let totalHours = res?.totalHours || 0;
  let capacityRemaining = totalHours - row.allocatedHours;
  // Since the existing table row already has information on shifts,
  // we can use that instead of using a capacityUsageMap
  let shiftsMap = new Map(
    row.shifts.map((shift) => {
      return [shift.shiftId, shift];
    })
  );
  let shifts = res?.shifts || [];
  shifts = shifts.map((shift) =>
    addCapacityRemainingAndAllocatedHoursToShift(shift, shiftsMap)
  );

  return {
    ...res,
    siteSpecific: res.siteSpecific ? "Yes" : "No",
    active: res?.active ? "Active" : "Inactive",
    shiftNames: res?.shifts.map((s: Shift) => s.shiftCode) || [],
    shifts: shifts || [],
    totalAccommodation: totalHours,
    shiftsPerJob: res?.shifts.length,
    capacityRemaining: capacityRemaining >= 0 ? capacityRemaining : 0,
    currentOpenAccommodations: row.currentOpenAccommodations,
    allocatedHours: row.allocatedHours,
  };
};

const applicableJobsToTableRow = (
  applicableJob: ApplicableJob,
  capacityUsageMap: Map<number, SiteJobCapacityUsageData>
): TableDataRow => {
  let currentOpenAccommodations = capacityUsageMap.has(applicableJob.jobId)
    ? capacityUsageMap.get(applicableJob.jobId)!.openAccommodationsTotal
    : 0;
  let allocatedHours = capacityUsageMap.has(applicableJob.jobId)
    ? capacityUsageMap.get(applicableJob.jobId)!.allocatedHours
    : 0;
  let totalHours = applicableJob.capacity?.totalHours || 0;
  let capacityRemaining = totalHours - allocatedHours;
  let shifts = applicableJob.capacity?.shifts || [];
  let shiftsMap = CapacityUsageService.createShiftsMapForJob(
    applicableJob.jobId,
    capacityUsageMap
  );
  shifts = shifts.map((shift) =>
    addCapacityRemainingAndAllocatedHoursToShift(shift, shiftsMap)
  );

  return {
    ...applicableJob,
    siteSpecific: applicableJob.siteSpecific ? "Yes" : "No",
    active: applicableJob.capacity?.active ? "Active" : "Inactive",
    shiftNames: applicableJob.capacity?.shifts.map((s) => s.shiftCode) || [],
    shifts: shifts,
    totalAccommodation: totalHours,
    shiftsPerJob: applicableJob.capacity?.shifts.length || 0,
    capacityRemaining: capacityRemaining >= 0 ? capacityRemaining : 0,
    currentOpenAccommodations: currentOpenAccommodations,
    allocatedHours: allocatedHours,
  };
};

const addCapacityRemainingAndAllocatedHoursToShift = (
  shift: Shift,
  shiftsMap: Map<number, Shift>
) => {
  let capacityRemaining = shift.totalHours;
  let allocatedHours = 0;
  if (shiftsMap.has(shift.shiftId)) {
    capacityRemaining =
      capacityRemaining - shiftsMap.get(shift.shiftId)!.allocatedHours;
    allocatedHours = shiftsMap.get(shift.shiftId)!.allocatedHours;
  }
  return {
    ...shift,
    capacityRemaining: capacityRemaining,
    allocatedHours: allocatedHours,
  };
};

const tableRowToSiteCapacityPayload = (
  row: TableDataRow,
  preferredSite: number
): SiteCapacityPayload => {
  return {
    siteId: preferredSite,
    jobId: row.jobId,
    active: row.active === "Active",
    shifts: row.shifts.map((s) => ({
      shiftId: s.shiftId,
      slots: parseInt(s.slots),
    })),
  };
};

/**
 * get all shifts
 * @returns {Object}
 */
export const getShifts = createAsyncThunk(
  "updateSites/getShifts",
  async (_, { getState }) => {
    const preferredSite = await (getState() as any).user.preferredSite;
    const { data } = await ShiftsService.getShifts(preferredSite);
    return data.map((s: any) => ({ ...s, shiftId: s.id }));
  }
);

/**
 * get all tasks
 * @returns {Object}
 */
export const getSiteCapacity = createAsyncThunk(
  "updateSites/getSiteCapacity",
  async (_, { getState }) => {
    const preferredSite = await (getState() as any).user.preferredSite;
    const { data: applicableJobsData } = await SitesService.getApplicableJobs(
      preferredSite
    );
    const { data: jobCapacityUsageData } =
      await CapacityUsageService.getCapacityUsageForSite(preferredSite);

    return { applicableJobsData, jobCapacityUsageData };
  }
);

/**
   * download site capacity summary
   * @returns {Object}
   */
export const downloadSiteCapacitySummaryCsv = createAsyncThunk(
  "siteCapacity/downloadSummary",
  async (_, { getState }) => {
    const site = await (getState() as any).sites.selectedSite;
    const { data } = await CapacityUsageService.downloadCapacitySummary(site.id);
    return {data, site: site.site };
  }
 );

export const addOrEditSiteCapacity = createAsyncThunk(
  "updateSites/updateSiteCapacity",
  async (row: TableDataRow, { getState }) => {
    const preferredSite = await (getState() as any).user.preferredSite;
    const payload = tableRowToSiteCapacityPayload(row, preferredSite);
    const { data } = await SitesService.createOrUpdateSiteCapacity(payload);
    if (data) {
      return updateSiteCapacityToTableRow(data, row);
    }
  }
);

/** Task Config Page Slice */
const { reducer, actions } = createSlice({
  name: "updateSiteCapacitySlice",
  initialState,
  reducers: {
    setSiteCapacityFormData: (state, action) => {
      state.siteCapacityFormData = action.payload;
    },
    setSiteCapacityFormErrors: (state, action) => {
      state.siteCapacityFormErrors = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setSearchField: (state, action) => {
      state.searchField = action.payload;
    },
    setIsCreateModalOpen: (state, action) => {
      state.isCreateModalOpen = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setTotalPages: (state, action) => {
      state.totalPages = action.payload;
    },
    setPerPage: (state, action) => {
      state.perPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getShifts.fulfilled, (state, { payload }) => {
      state.shifts = payload;
    });
    builder.addCase(getSiteCapacity.fulfilled, (state, { payload }) => {
      let { applicableJobsData, jobCapacityUsageData } = payload;
      const capacityUsageMap = new Map(
        jobCapacityUsageData.map((jobCapacityUsage) => [
          jobCapacityUsage.jobId,
          jobCapacityUsage,
        ])
      );
      state.tableData = applicableJobsData.map((job: ApplicableJob) =>
        applicableJobsToTableRow(job, capacityUsageMap)
      );
      state.isLoading = false;
    });
    builder.addCase(addOrEditSiteCapacity.fulfilled, (state, { payload }) => {
      if (payload) {
        const isAlreadyInTable = state.tableData.some((row) => {
          return row.jobId === payload.jobId;
        });
        if (isAlreadyInTable) {
          // this will be the case when editing an existing site
          state.tableData = state.tableData.map((row) => {
            if (row.jobId === payload.jobId) {
              state.searchField = row.name;
              return { ...row, ...payload };
            } else {
              return row;
            }
          });
        }
      }
    });
    builder.addCase(downloadSiteCapacitySummaryCsv.fulfilled, (_, { payload }) => {
      if (payload.data) {
        // download file
        downloadFile(createFileUrl(payload.data, "text/csv"), `site_capacity_${payload.site}.csv`);
      }
    });
  },
});

export const {
  setSearchField,
  setIsCreateModalOpen,
  setCurrentPage,
  setTotalPages,
  setSiteCapacityFormData,
  setSiteCapacityFormErrors,
  setIsLoading,
  setPerPage,
} = actions;

export default reducer;
