import Button from "@amzn/meridian/button";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import ConfirmModal from "src/components/shared/confirmModal/confirmModal";
import {
  setJmrResults,
  setPageProgress,
  JmrFlowTypes,
} from "src/store/newJmrPageSlice";
import { useAppSelector } from "src/store/store";
import { getBodyLocationLabel } from "src/utils/getBodyLocationLabel";

const restrictionsReview = () => {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { selectedTasks, jmrFlowType } = useAppSelector(
    (state) => state.newJmrPage
  );

  const goToSecondPage = () => {
    dispatch(setPageProgress(1));
    dispatch(setJmrResults([]));
  };

  return (
    <Column spacingInset={"300 none none none"}>
      <Row widths={["111px", "fill", "61px"]} alignmentVertical="center">
        <Text type="h300">Restrictions</Text>
        <div />
        <Button
          type="secondary"
          disabled={
            jmrFlowType === JmrFlowTypes.AlternativePlacementRecommended
          }
          onClick={() => setIsModalOpen(true)}
        >
          Edit
        </Button>
      </Row>
      {selectedTasks.length > 0 ? (
        <Column spacing={"200"}>
          {selectedTasks.map((task) => (
            <Column spacing={"none"} key={task.taskId}>
              <Text type="b300">{task.label}</Text>
              <Text type="b300" color="secondary">
                {task.measureUnit === "YES/NO"
                  ? "No"
                  : `${task.restrictedValue} ${task.measureUnit}`}
              </Text>
              { !!task.bodyLocationValue && <Text type="b300" color="secondary">Body Location: {getBodyLocationLabel(task.bodyLocationValue)}</Text> }
            </Column>
          ))}
        </Column>
      ) : (
        <Text type="b300" color="secondary">
          No tasks selected
        </Text>
      )}

      <ConfirmModal
        modalTitle="Leave page"
        modalDescription="Leaving this page will restart your Job Match Results process."
        open={isModalOpen}
        setOpen={() => setIsModalOpen(false)}
        onConfirm={() => goToSecondPage()}
        width="419px"
      />
    </Column>
  );
};

export default restrictionsReview;
