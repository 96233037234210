import { TOAST_TIMEOUT } from "src/config/Toast";
import store from "src/store";
import { MeasureUnit, Region } from "src/store/taskConfigPageSlice";
import { createToast } from "src/store/toastsSlice";
import apiClient from "src/utils/apiClient";

export interface TaskPayload {
  id?: number;
  label: string;
  regions: Region[];
  identifyBodyLocation: boolean;
  measureUnit: MeasureUnit;
  description: string;
}

class TasksService {
  /**
   * get all tasks
   * @returns {Object}
   */
  async getTasks() {
    return await apiClient.get("/tasks");
  }

  /**
   * get units of measure
   * @returns {Object}
   */
  async getUnitsOfMeasure() {
    return await apiClient.get("/measureUnits");
  }

  /**
   * get regions
   * @returns {Object}
   */
  async getRegions() {
    return await apiClient.get("/regions");
  }

  /**
   * get region by Id
   * @returns {Object}
   */
  async getRegionById(id: number) {
    return await apiClient.get(`/regions/${id}`);
  }

  /**
   * Create or update task (create if id not provided)
   * @returns {Object}
   */
  async createOrUpdateTask(data: TaskPayload) {
    const res = await apiClient.post("/tasks", data);
    if (res) {
      store.dispatch(
        createToast({
          type: "success",
          message: data.id
            ? "Updated task successfully"
            : "Created task successfully",
          timeout: TOAST_TIMEOUT,
        })
      );
    }
    return res;
  }
}

export default new TasksService();
