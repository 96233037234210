import { TOAST_TIMEOUT } from "src/config/Toast";
import store from "src/store";
import { createToast } from "src/store/toastsSlice";
import apiClient from "src/utils/apiClient";

export interface WebHookPayload {
  siteId: number;
  urlForWorkRelated: string | null;
  urlForNonWorkRelated: string | null;
  notifyNewForWorkRelated: boolean;
  notifyNewForNonWorkRelated: boolean;
}

class WebHookService {
  /**
   * Get webhooksettings for site id
   * @returns {Object}
   */
  async getWebHookSettings(siteId: number) {
    return await apiClient.get(`/sites/${siteId}/webhookSetting`);
  }

  /**
   * Post webhooksettings for site id
   * @returns {Object}
   */
   async postWebHookSettings(data: WebHookPayload) {
    const res = await apiClient.post(`/sites/webhookSetting`, data);
    if (res) {
      store.dispatch(
        createToast({
          type: "success",
          message: "Updated Webhook successfully.",
          timeout: TOAST_TIMEOUT
        })
      );
    }
    return res;
  }
}

export default new WebHookService();
