import React from "react";
import { useAppSelector } from "src/store/store";
import * as LDAP from "src/config/LDAP";
import { BackgroundFormData } from "src/store/newJmrPageSlice";
import AdminOption from "./adminOption";
import DlsNewOption from "./dlsNewOption";
import DlsUpdateOption from "./dlsUpdateOption";
import GeneralUserOption from "./generalUserOption";

interface caseSourceOptionProps {
  handleChange: (key: keyof BackgroundFormData, value: any) => void;
  isNewJMR: boolean;
}
const caseSourceOption = ({
  handleChange,
  isNewJMR,
}: caseSourceOptionProps) => {
  const { ldap } = useAppSelector((state) => state.user);

  const isAdmin = ldap.includes(LDAP.ADMIN);
  const isDLS = ldap.includes(LDAP.DLS);
  const isSMES = ldap.includes(LDAP.SMES);
  const isRegional = ldap.includes(LDAP.REGIONAL_VIEWER);

  const { backgroundFormData } = useAppSelector((state) => state.newJmrPage);

  if (isAdmin || isSMES || isRegional) {
    return <AdminOption handleChange={handleChange}></AdminOption>;
  } else if (isDLS) {
    if (isNewJMR)
      return <DlsNewOption handleChange={handleChange}></DlsNewOption>;
    return <DlsUpdateOption></DlsUpdateOption>;
  }
  return (
    <GeneralUserOption
      handleChange={handleChange}
      backgroundFormData={backgroundFormData}
    ></GeneralUserOption>
  );
};

export default caseSourceOption;
