import React, { useEffect } from "react";
import Table, { TableRow, TableCell } from "@amzn/meridian/table";
import { useDispatch } from "react-redux";
import { useAppSelector } from "src/store/store";
import {
  addOrEditSite,
  setIsCreateModalOpen,
  setSelectedSite,
  TableDataRow,
  setIsLoading,
} from "src/store/siteConfigPageSlice";
import { useForm } from "src/hooks/useForm";
import SiteFormFields from "../siteFormFields/siteFormFields";
import Modal, { ModalFooter } from "@amzn/meridian/modal";
import Row from "@amzn/meridian/row";
import Button from "@amzn/meridian/button";

const siteModal = () => {
  const dispatch = useDispatch();
  const { isCreateModalOpen, selectedSite, siteModalFieldLengths } = useAppSelector(
    (state) => state.siteConfigPage
  );
  const {
    validateForm,
    handleChange,
    resetForm,
    setFormValues,
    formData,
    errors,
  } = useForm<TableDataRow>({
    validations: {
      site: {
        required: {
          value: true,
          message: "Site name is required",
        },
        custom: {
          isValid: (value) => value.length <= siteModalFieldLengths.maxSiteCharLength,
          message: `Site name must be less than or equal to ${siteModalFieldLengths.maxSiteCharLength} characters.`
        }
      },
      country: {
        required: {
          value: true,
          message: "Country is required",
        },
      },
      region: {
        required: {
          value: true,
          message: "Region is required",
        },
      },
      org: {
        required: {
          value: true,
          message: "Org is required",
        },
      },
      buildingType: {
        required: {
          value: true,
          message: "Building Type is required",
        },
      },
      description: {
        required: {
          value: false,
        },
        custom: {
          isValid: (value) => value.length <= siteModalFieldLengths.maxDescriptionCharLength,
          message: `Description must be less than or equal to ${siteModalFieldLengths.maxDescriptionCharLength} characters.`
        }
      },
    },
    initialValues: {
      isActive: false,
      site: "",
      description: ""
    },
  });

  useEffect(() => {
    if (selectedSite) {
      setFormValues(selectedSite);
    }
  }, [selectedSite]);

  const onSave = () => {
    if (validateForm()) {
      dispatch(setIsLoading(true));
      dispatch(addOrEditSite(formData));
      dispatch(setIsCreateModalOpen(false));
      dispatch(setSelectedSite(undefined));
      resetForm();
    }
  };

  const onCancel = () => {
    dispatch(setIsCreateModalOpen(false));
    dispatch(setSelectedSite(undefined));
    resetForm();
  };

  return (
    <Modal
      title={selectedSite ? "Edit Site" : "Add New Site"}
      open={isCreateModalOpen}
      onClose={onCancel}
      closeLabel="Close"
      describedById="modal-description"
      width="700px"
      scrollContainer="modal"
    >
      <SiteFormFields
        formData={formData}
        errors={errors}
        handleChange={handleChange}
      />
      <ModalFooter>
        <Row alignmentHorizontal="right" widths="fill">
          <Button
            id="site-modal-cancel-btn"
            type="secondary"
            size="small"
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            id="site-modal-save-btn"
            type="primary"
            size="small"
            onClick={onSave}
          >
            Save
          </Button>
        </Row>
      </ModalFooter>
    </Modal>
  );
};

export default siteModal;
