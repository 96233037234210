import React, { useCallback, useEffect } from "react";
import Modal, { ModalFooter } from "@amzn/meridian/modal";
import Row from "@amzn/meridian/row";
import Button from "@amzn/meridian/button";
import { useAppSelector } from "src/store/store";
import { useDispatch } from "react-redux";
import {
  addOrEditTask, getTasks,
  setIsCreateModalOpen,
  setSelectedTask,
  TableDataRow,
} from "src/store/taskConfigPageSlice";
import _ from "lodash";
import { useForm } from "src/hooks/useForm";
import TaskFormFields from "../taskFormFields/taskFormFields";

const createNewTask = () => {
  const dispatch = useDispatch();
  const { isCreateModalOpen, selectedTask, taskModalFieldLengths } = useAppSelector(
    (state) => state.taskConfigPage
  );
  const {
    validateForm,
    handleChange,
    resetForm,
    setFormValues,
    formData,
    errors,
  } = useForm<TableDataRow>({
    validations: {
      label: {
        required: {
          value: true,
          message: "Task name is required",
        },
        custom: {
          isValid: (value) => value.length <= taskModalFieldLengths.maxTaskNameCharLength,
          message: `Task name must be less than or equal to ${taskModalFieldLengths.maxTaskNameCharLength} characters.`
        }
      },
      regions: {
        required: {
          value: true,
          message: "Region is required",
        },
      },
      isMeasuredBy: {
        required: {
          value: true,
          message: "Unit of measurement is required",
        },
      },
      description: {
        required: {
          value: false,
        },
        custom: {
          isValid: (value) => value.length <= taskModalFieldLengths.maxDescriptionCharLength,
          message: `Description must be less than or equal to ${taskModalFieldLengths.maxDescriptionCharLength} characters.`
        }
      },
    },
    initialValues: {
      regions: [],
      label: "",
      description: ""
    },
  });

  useEffect(() => {
    if (selectedTask) {
      setFormValues(selectedTask);
    }
  }, [selectedTask]);

  const onSave = () => {
    if (validateForm()) {
      dispatch(addOrEditTask(formData));
      dispatch(setIsCreateModalOpen(false));
      dispatch(setSelectedTask(undefined));
      resetForm();
    }
  };

  const onCancel = () => {
    dispatch(setIsCreateModalOpen(false));
    dispatch(setSelectedTask(undefined));
    resetForm();
  };

  return (
    <Modal
      title={selectedTask ? "Edit Task" : "Add New Task"}
      open={isCreateModalOpen}
      onClose={onCancel}
      closeLabel="Close"
      describedById="modal-description"
      width="700px"
      scrollContainer="modal"
    >
      <TaskFormFields
        formData={formData}
        errors={errors}
        handleChange={handleChange}
      />
      <ModalFooter>
        <Row alignmentHorizontal="right" widths="fill">
          <Button
            className="view-and-edit-tasks__modal__cancel-btn"
            type="secondary"
            size="small"
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            className="view-and-edit-tasks__modal__submit-btn"
            size="small"
            onClick={onSave}
          >
            Save
          </Button>
        </Row>
      </ModalFooter>
    </Modal>
  );
};

export default createNewTask;
