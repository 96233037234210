// allowed email domain names
import { AUSTIN } from "src/components/pages/newJMR/backgroundPage/associateInformation/caseSource/constant";

const ALLOWED_EMAIL_DOMAINS = ["amazon.com", "sedgwick.com", "sedgwickcms.com", "origamirisk.com"];
const EMAIL_REGEX = /^[a-zA-Z0-9_+&*-]+(?:\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,7}$/;

export const arrayMove = (arr: any[], fromIndex: number, toIndex: number) => {
  const element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
  return arr;
};

export const getAustinLink = (caseId: string) => {
  return window.location.host === "prod.na.rtw.whs.amazon.dev"
    ? `https://na.ehs-amazon.com/incidents/incident/${caseId}/summary`
    : `https://na-gamma.austin.a2z.com/incidents/incident/${caseId}/summary`;
};

export const isEmailValid = (value: string) => {
  if (!value) {
    return true;
  }

  // remove spaces, then split by semicolon
  const emails = value.replace(/\s+/g, "").split(";");
  for (let email of emails) {
    let trimmedEmail = email.trim();
    if (!trimmedEmail.includes("@")) {
      trimmedEmail = trimmedEmail + "@amazon.com";
    }
    if (!email.match(EMAIL_REGEX) || !ALLOWED_EMAIL_DOMAINS.includes(email.split("@")[1].toLowerCase())) {
      return false;
    }
  }
  return true;
};

export const linkClickHandler = (url: string) => {
  window.open(url, "_blank");
};

export const removeSpecialCharactersFromString = (str: string) => {
  return str.replace(/[^a-zA-Z0-9_]/g, "");
};

export const isNumber = (value?: string | number): boolean => {
  return value != null && value !== "" && !isNaN(Number(value.toString()));
};

export const getExampleCaseIdText = (caseSource: string) => {
  if (caseSource === AUSTIN) {
    return "e.g. ABE4-23-112";
  } else {
    return "e.g. A-2023-825678";
  }
};

export const stripOutLeadingHash = (caseId: string) => {
  return caseId && caseId.replace(/^#/, "");
};

/*
  Check if a value is within a numeric range.
  e.g. for lowerBound = 0 and upperBound = 40
    4 = true
    "3.5" = true
    100 = false
    "-2" = false
    "a" = false
    "" = false
    " " = false
    undefined = false
    null = false
 */
export const isWithinRange = (value: any, lowerBound: number, upperBound: number): boolean => {
  // after stringify, possible values are "3.5", "100", "-2", "a", "", "undefined", "null"
  const stringValue : string = String(value).trim();
  return stringValue !== "" // detect ""
      && +stringValue >= lowerBound // detect "-2", "a", "undefined", "null"
      && +stringValue <= upperBound; // detect "100", which is larger than shift length
}
