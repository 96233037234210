import React, { useState, useCallback, useEffect } from "react";
import Table, { TableRow, TableCell } from "@amzn/meridian/table";
import { TableSortDirection } from "@amzn/meridian/table/table";
import Box from "@amzn/meridian/box";
import editTokens from "@amzn/meridian-tokens/base/icon/edit";
import { useAppSelector } from "src/store/store";
import Icon from "@amzn/meridian/icon";
import { useDispatch } from "react-redux";
import UncontrolledExpandableRow from "src/components/shared/uncontrolledExpandableRow/uncontrolledExpandableRow";
import {
  getRegions,
  getTasks,
  getUnitsOfMeasure,
  setCurrentPage,
  setIsCreateModalOpen,
  setSearchField,
  setSelectedTask,
  setTotalPages,
  TableDataRow,
} from "src/store/taskConfigPageSlice";
import { getUser } from "src/store/userSlice";
import { filterSortAndPaginateTableData } from "src/utils/tableUtils";

const taskTable = () => {
  const dispatch = useDispatch();
  const [sortColumn, setSortColumn] = useState("label");
  const [sortDirection, setSortDirection] =
    useState<TableSortDirection>("ascending");
  const onSort = useCallback(({ sortColumn, sortDirection }) => {
    setSortDirection(sortDirection);
    setSortColumn(sortColumn);
  }, []);
  const { tableData, searchField, currentPage, perPage } = useAppSelector(
    (state) => state.taskConfigPage
  );
  const { userLoading } = useAppSelector((state) => state.user);
  const [sortedTableData, setSortedTableData] = useState(tableData);

  useEffect(() => {
    if (userLoading === "fulfilled") {
      dispatch(getUnitsOfMeasure());
      dispatch(getTasks());
      dispatch(getRegions());
    }
    return () => {
      dispatch(setSearchField(""));
    };
  }, [userLoading]);

  useEffect(() => {
    // filter, sorting, and pagination
    const [paginatedData, totalPages] = filterSortAndPaginateTableData(
      tableData,
      searchField,
      sortColumn,
      sortDirection,
      currentPage,
      ["id"],
      perPage
    );
    setSortedTableData(paginatedData as TableDataRow[]);
    dispatch(setTotalPages(totalPages));
  }, [sortColumn, sortDirection, tableData, searchField, currentPage, perPage]);

  useEffect(() => {
    // go back to first page when filter/sort
    dispatch(setCurrentPage(1));
  }, [searchField, sortColumn, sortDirection]);

  return (
    <Box
      overflowY="scroll"
      overflowX="scroll"
      type="outline"
      className="view-and-edit-tasks__table"
    >
      <Table
        headerRows={1}
        sortColumn={sortColumn}
        sortDirection={sortDirection}
        onSort={onSort}
        showDividers={true}
        fixHeaderRows={true}
        rowComponents={[UncontrolledExpandableRow, TableRow]}
      >
        <TableRow>
          <TableCell width="20%" sortColumn="label">
            Task Name
          </TableCell>
          <TableCell sortColumn="regions">State/Region/Province</TableCell>
          <TableCell sortColumn="countries">Country</TableCell>
          <TableCell sortColumn="identifyBodyLocation">
            Identify Body Location?
          </TableCell>
          <TableCell sortColumn="isMeasuredBy">Is Measured By?</TableCell>
          <TableCell sortColumn="description">Description</TableCell>
          <TableCell>Edit</TableCell>
        </TableRow>
        {sortedTableData.map((row) => (
          <UncontrolledExpandableRow key={row.id}>
            <TableCell>{row.label}</TableCell>
            <TableCell>
              {row.regions.length === 1
                ? `${row.regions[0]}`
                : `${row.regions.length} regions`}
            </TableCell>
            <TableCell>{row.countries[0]}</TableCell>
            {row.regions.length > 1 &&
              row.regions.map((_, index) => (
                <TableRow key={index}>
                  <TableCell></TableCell>
                  <TableCell>{row.regions[index]}</TableCell>
                  <TableCell>{row.countries[index]}</TableCell>
                </TableRow>
              ))}
            <TableCell>{row.identifyBodyLocation ? "Yes" : "No"}</TableCell>
            <TableCell>{row.isMeasuredBy}</TableCell>
            <TableCell>{row.description}</TableCell>
            <TableCell>
              <div
                className="view-and-edit-tasks__edit-icon"
                onClick={() => {
                  dispatch(setIsCreateModalOpen(true));
                  dispatch(setSelectedTask(row));
                }}
              >
                <Icon tokens={editTokens}></Icon>
              </div>
            </TableCell>
          </UncontrolledExpandableRow>
        ))}
      </Table>
    </Box>
  );
};

export default taskTable;
