const findCurrentTabToHighlight = (pathname: string) => {
  const subPaths = pathname.split("/");
  return subPaths[subPaths.length - 1];
};

const isPathValid = (pathname: string) => {
  const setOfPaths = new Set();
  setOfPaths.add("/siteManagement/update-shifts");
  setOfPaths.add("/siteManagement/update-default-emails");
  setOfPaths.add("/siteManagement/update-webhooks");
  setOfPaths.add("/siteManagement/update-site-capacity");
  return setOfPaths.has(pathname);
};

export { findCurrentTabToHighlight, isPathValid };
