import Column from "@amzn/meridian/column";
import RadioButton from "@amzn/meridian/radio-button";
import Text from "@amzn/meridian/text";
import React, { ReactNode } from "react";
import {
  AgreeWithHealthCareRecommendationsEnum,
  ReferralAndClaimsStatusEnum,
  SelectedEmailTypeEnum,
  SendEmailFormData,
} from "src/store/placementDetailsPageSlice/types";
import { useAppSelector } from "src/store/store";

interface bottomFieldsProps {
  handleChange: (key: keyof SendEmailFormData, value: any) => void;
}
const bottomFields = ({ handleChange }: bottomFieldsProps) => {
  const { sendEmailFormData, sendEmailFormErrors } = useAppSelector(
    (state) => state.placementDetailsPage
  );
  const staticTextParagraphs = {
    [SelectedEmailTypeEnum.ApprovedOnsitePlacement]: [
      <>
        A Job Safety Analysis has been completed using the physical restrictions
        outlined by the AA’s medical provider.
      </>,
      <>
        Operations has reviewed the RTW tool report and determined restrictions
        have been matched with an available Process Path position onsite.
      </>,
    ],
    [SelectedEmailTypeEnum.AssociateRejectedPlacement]: [
      <>
        The Work Placement Letter and Return to Work report support
        documentation has been reviewed with the Associate. The Associate:
      </>,
    ],
    [SelectedEmailTypeEnum.NoOnsitePlacementLOA]: [
      <>
        A Job Safety Analysis has been completed using the physical restrictions
        outlined by the AA’s medical provider.
      </>,
      <>
        Operations has reviewed the RTW tool report and determined there are no
        positions at the site that meet the Associate's restrictions at this
        time. Associate to call DLS to set up a LOA.
      </>,
    ],
    [SelectedEmailTypeEnum.ReturnedtoFullDuty]: [
      <>
        Paperwork has been received notifying the site that the Associate has
        now been returned to full duty.
      </>,
      <>
        <strong>Actions for LOA:</strong> Please bring Associate back to active
        status.
      </>,
      <>
        <strong>Actions for Learning:</strong> Please ensure Associate is up to
        date on necessary training for return to role.
      </>,
    ],
    [SelectedEmailTypeEnum.DLSReferral]: [<>The Associate:</>],
  };
  const radioFields: any = {
    [SelectedEmailTypeEnum.AssociateRejectedPlacement]: [
      {
        label: (
          <>
            Does not agree with recommendations by their healthcare provider and
            requires further consult
          </>
        ),
        value: AgreeWithHealthCareRecommendationsEnum.DoesNotAgree,
        formDataKey: "agreeWithHealthCareRecommendations",
      },
      {
        label: (
          <>
            Is uncertain about recommendations provided by their healthcare
            provider and requires further consult
          </>
        ),
        value: AgreeWithHealthCareRecommendationsEnum.IsUncertain,
        formDataKey: "agreeWithHealthCareRecommendations",
      },
    ],
    [SelectedEmailTypeEnum.DLSReferral]: [
      {
        label: (
          <>Has reached the 180-day TLD TWP limit and requires DLS referral</>
        ),
        value: ReferralAndClaimsStatusEnum.HasReachedLimit,
        formDataKey: "referralAndClaimsStatus",
      },
      {
        label: <>Has permanent restrictions and requires DLS referral</>,
        value: ReferralAndClaimsStatusEnum.HasPermanentRestrictions,
        formDataKey: "referralAndClaimsStatus",
      },
      {
        label: (
          <>
            Has had their work-related claim denied and still requires further
            accommodation
          </>
        ),
        value: ReferralAndClaimsStatusEnum.HasHadWorkRelatedClaimDenied,
        formDataKey: "referralAndClaimsStatus",
      },
    ],
  };

  const getErrorText = () => {
    if (sendEmailFormErrors.referralAndClaimsStatus?.error) {
      return sendEmailFormErrors.referralAndClaimsStatus?.errorMessage;
    }
    if (sendEmailFormErrors.agreeWithHealthCareRecommendations?.error) {
      return sendEmailFormErrors.agreeWithHealthCareRecommendations
        ?.errorMessage;
    }
  };

  return (
    <div>
      {sendEmailFormData.emailType && (
        <Column spacing={"200"}>
          {staticTextParagraphs[sendEmailFormData.emailType]?.map(
            (paragraph, index) => (
              <Text key={index}>{paragraph}</Text>
            )
          )}
          <Text color="error">{getErrorText()}</Text>
          {radioFields[sendEmailFormData.emailType]?.map(
            (radioOption: any, index: number) => (
              <RadioButton
                key={index}
                checked={
                  sendEmailFormData[
                    radioOption.formDataKey as keyof SendEmailFormData
                  ] === radioOption.value
                }
                onChange={(value) =>
                  handleChange(
                    radioOption.formDataKey as keyof SendEmailFormData,
                    value
                  )
                }
                value={radioOption.value}
                data-cy={`${radioOption.formDataKey}__${index}`}
              >
                {radioOption.label}
              </RadioButton>
            )
          )}
        </Column>
      )}
    </div>
  );
};

export default bottomFields;
