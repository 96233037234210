import apiClient from "src/utils/apiClient";

class IncidentService {
  /**
   * get Austin cases by alias
   * @returns {Object}
   */
  async getAustinCasesByAlias(alias: string) {
    const response = await apiClient.get(`/incident/associate/${alias}`);
    return response;
  }
}

export default new IncidentService();
