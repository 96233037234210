import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setIsResultsPageValid, TableDataRow } from "src/store/newJmrPageSlice";
import { useAppSelector } from "src/store/store";
import {
  calculateTotalHours,
  calculateUnusedHoursForTask,
  calculateUnusedTotalHours,
  isTotalUnusedHoursValid,
  areAllUsedHoursMoreThanZero
} from "./placementSelectionUtils";
import LeftColumn from "./leftColumn/leftColumn";
import RightColumn from "./rightColumn/rightColumn";
import MiddleColumn from "./middleColumn/middleColumn";

export interface SummationDataForTask {
  totalHours: number;
  unusedHours: number;
  restrictedValue: number;
}

function placementsSelectionTable() {
  const dispatch = useDispatch();
  const { selectedTasks, jmrResults, backgroundFormData } = useAppSelector(
    (state) => state.newJmrPage
  );
  const [selectedResults, setSelectedResults] = useState<TableDataRow[]>([]);
  const [unusedTotalHours, setUnusedTotalHours] = useState<number>(0);
  const [sumDataForTask, setSumDataForTask] = useState<SummationDataForTask[]>(
    []
  );

  useEffect(() => {
    const newSelectedResults = jmrResults.filter(
      (jmrResult) => jmrResult.isChecked === true
    );
    const newTotalUnusedHours = calculateUnusedTotalHours(
      newSelectedResults,
      backgroundFormData
    );
    const newSumDataForTask = selectedTasks.map((task) => ({
      totalHours: calculateTotalHours(task, newSelectedResults),
      unusedHours: calculateUnusedHoursForTask(task, newSelectedResults),
      restrictedValue: +task.restrictedValue,
    }));

    setSelectedResults(newSelectedResults);
    setUnusedTotalHours(newTotalUnusedHours);
    setSumDataForTask(newSumDataForTask);

    dispatch(
      setIsResultsPageValid(
        isTotalUnusedHoursValid(
          newTotalUnusedHours,
          backgroundFormData.shiftLength,
          +backgroundFormData.limitedHoursPerDay
        ) && areAllUsedHoursMoreThanZero(newSelectedResults)
      )
    );
  }, [jmrResults]);

  return (
    <div className="new-jmr__placements">
      <Text type="h300" className="new-jmr__placements-title">
        Placement Selection(s)
      </Text>
      <Text
        type="b300"
        color="secondary"
        className="new-jmr__placements-description"
      >
        Assign the Total Hours the Associate should spend on each Job. Inputted values must be greater than
        zero (0). The table will calculate how to distribute the hours depending on each Job's Task
        Restrictions. Scroll right to see more restrictions.
      </Text>
      <div className="new-jmr__placements-table">
        <Row widths={["220px", "fill", "145px"]} spacing={"none"}>
          <LeftColumn selectedResults={selectedResults} />
          <MiddleColumn
            selectedResults={selectedResults}
            sumDataForTask={sumDataForTask}
          />
          <RightColumn
            selectedResults={selectedResults}
            unusedTotalHours={unusedTotalHours}
          />
        </Row>
      </div>
    </div>
  );
}

export default placementsSelectionTable;
