import React, { useEffect } from "react";
import { useAppSelector } from "src/store/store";
import BaliOrDaliButton from "./baliOrDaliButton";
import { BackgroundFormData } from "src/store/newJmrPageSlice";
import { COUNTRY_ID_MAPPING } from "src/config/CountryIdMapping";
import { DALI, BALI } from "./constant";

interface dlsNewOptionProps {
  handleChange: (key: keyof BackgroundFormData, value: any) => void;
}

const dlsNewOption = ({ handleChange }: dlsNewOptionProps) => {
  const { backgroundFormData } = useAppSelector((state) => state.newJmrPage);
  const isCanadian =
    backgroundFormData.site?.countryId === COUNTRY_ID_MAPPING.CANADA_COUNTRY_ID;

  useEffect(
    () => handleChange("caseSource", isCanadian ? BALI : DALI),
    [backgroundFormData.site?.countryId]
  );
  return (
    <BaliOrDaliButton
      caseSource={backgroundFormData.caseSource}
    ></BaliOrDaliButton>
  );
};

export default dlsNewOption;
