import React from "react";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import { BackgroundFormData } from "src/store/newJmrPageSlice";
import CaseSourceOption from "src/components/pages/newJMR/backgroundPage/associateInformation/caseSource/caseSourceOption";

interface associateInformationProps {
  handleChange: (key: keyof BackgroundFormData, value: any) => void;
  isNewJMR: boolean;
}
const CaseSource = ({ handleChange, isNewJMR }: associateInformationProps) => {
  return (
    <Row className="new-jmr__background-page__caseSource-row">
      <Column spacing="200">
        <Text tag="label" type="b300" color="primary">
          Case Source
        </Text>
        <CaseSourceOption handleChange={handleChange} isNewJMR={isNewJMR}></CaseSourceOption>
      </Column>
    </Row>
  );
};

export default CaseSource;
