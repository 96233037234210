import Button from "@amzn/meridian/button";
import Column from "@amzn/meridian/column";
import Popover, { PopoverHeader } from "@amzn/meridian/popover";
import Text from "@amzn/meridian/text";
import React, { useCallback, useRef, useState } from "react";
import { TaskRestriction } from "src/store/newJmrPageSlice";

interface taskRestrictionsPopoverProps {
  taskRestrictions: TaskRestriction[];
}
function taskRestrictionsPopover({
  taskRestrictions,
}: taskRestrictionsPopoverProps) {
  const buttonRef = useRef();
  const [open, setOpen] = useState(false);
  const onClickButton = useCallback(() => setOpen(true), []);
  const onClose = useCallback(() => setOpen(false), []);
  return (
    <>
      <Button
        onClick={onClickButton}
        ref={buttonRef}
        size="medium"
        type="link"
        className="site-capcity__shifts-popover-btn"
      >
        {`View ${taskRestrictions?.length} restrictions`}
      </Button>
      <Popover
        anchorNode={buttonRef.current}
        open={open}
        onClose={onClose}
        position="bottom"
        minWidth={"200px"}
        maxWidth={"200px"}
      >
        <PopoverHeader closeLabel="Close">Task Restrictions</PopoverHeader>
        <Column overflowY="scroll" height="200px">
          {taskRestrictions.map((taskRestriction: TaskRestriction) => (
            <Column spacing={"200"} key={taskRestriction.taskId}>
              <Text>{taskRestriction.taskName}</Text>
              <Text type="b200" color="secondary">
                Requirement Hours: {taskRestriction.originalValue}
              </Text>
              <Text type="b200" color="secondary">
                Restricted hours: {taskRestriction.restrictedValue}
              </Text>
            </Column>
          ))}
        </Column>
      </Popover>
    </>
  );
}

export default taskRestrictionsPopover;
