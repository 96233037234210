import { TableDataRow } from "src/services/placementOverview/types/tableDataRow";
import moment from "moment";

type IndexableTableDataRow = TableDataRow & {
  [key: string]: string;
};

export enum SortStrategy {
  DEFAULT,
  NUMBER,
  DATE,
}

export const sortBasedOnStrategy = (
  sortStrategy: SortStrategy,
  tableRows: any[],
  sortColumn: string,
  sortDirection: string
) => {
  switch (sortStrategy) {
    case SortStrategy.DEFAULT:
      return defaultStrategy(tableRows, sortColumn, sortDirection);
    case SortStrategy.DATE:
      return dateStrategy(tableRows, sortColumn, sortDirection);
    case SortStrategy.NUMBER:
      return numberStrategy(tableRows, sortColumn, sortDirection);
    default:
      return defaultStrategy(tableRows, sortColumn, sortDirection);
  }
};

const defaultStrategy = (tableRows: IndexableTableDataRow[], sortColumn: string, sortDirection: string) => {
  return tableRows.sort((a: IndexableTableDataRow, b: IndexableTableDataRow) => {
    const aValue = a[sortColumn]?.toString().toLowerCase() || "";
    const bValue = b[sortColumn]?.toString().toLowerCase() || "";

    if (aValue === bValue) {
      return 0;
    }

    // Null or undefined values are positioned
    // at the start when sorted descending
    // and at the end when sorted ascending
    if (!aValue) {
      return sortDirection === "ascending" ? 1 : -1;
    }
    if (!bValue) {
      return sortDirection === "ascending" ? -1 : 1;
    }

    return sortDirection === "ascending" ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
  });
};

const numberStrategy = (tableRows: any[], sortColumn: string, sortDirection: string) => {
  return tableRows.sort((a: any, b: any) => {
    let aValue = +a[sortColumn];
    let bValue = +b[sortColumn];

    if (aValue < bValue) {
      return sortDirection === "ascending" ? -1 : 1;
    } else if (aValue > bValue) {
      return sortDirection === "ascending" ? 1 : -1;
    }

    return 0;
  });
};

const dateStrategy = (tableRows: any[], sortColumn: string, sortDirection: string) => {
  return tableRows?.sort((a: any, b: any) => {
    let aValue = moment(a[sortColumn]);
    let bValue = moment(b[sortColumn]);

    if (aValue.isBefore(bValue)) {
      return sortDirection === "ascending" ? -1 : 1;
    } else if (aValue.isAfter(bValue)) {
      return sortDirection === "ascending" ? 1 : -1;
    }

    return 0;
  });
};
