import React, { useEffect, useState } from "react";
import BreadCrumbs from "src/components/shared/breadcrumbs/breadcrumbs";
import Box from "@amzn/meridian/box";
import "./createNewJob.scss";
import Text from "@amzn/meridian/text";
import { useDispatch } from "react-redux";
import {
  getRegions,
  getSpecificSites,
  getRegionTasks,
  initialFormData,
  setJobFormData,
  setJobFormErrors,
  setRequirementFormErrors,
  setTaskNames,
  setRestrictionFormName,
  getBuildingTypes,
  getJobTypes,
  setSelectedJobId,
  getJobById,
} from "src/store/createNewJobSlice";
import {
  addOrEditJob,
  setCreatedJobSuccessfully,
  TableDataRow,
  setIsLoading,
} from "src/store/jobConfigPageSlice";
import { useAppSelector } from "src/store/store";
import Row from "@amzn/meridian/row";
import Button from "@amzn/meridian/button";
import useForm from "src/hooks/useForm";
import ConfirmModal from "src/components/shared/confirmModal/confirmModal";
import BasicJobInfoFields from "./basicJobInfoFields/basicJobInfoFields";
import JobSiteInfoFields from "./jobSiteInfoFields/jobSiteInfoFields";
import CustomizeTaskFields from "./customizeTaskFields/customizeTaskFields";
import {
  doTasksHaveErrors,
  validateTasks,
} from "../../../utils/requirementFormUtils";
import { useNavigate } from "react-router-dom";

const createNewJob = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { jobFormData, regions, selectedJobId, isDisabled, jobFieldLengths } = useAppSelector(
    (state) => state.createNewJobPage
  );
  const { createdJobSuccessfully } = useAppSelector(
    (state) => state.jobConfigPage
  );
  const { token } = useAppSelector((state) => state.user);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  const { resetForm, validateForm, setFormValues, errors } =
    useForm<TableDataRow>({
      validations: {
        name: {
          required: {
            value: true,
            message: "Job name is required",
          },
          custom: {
            isValid: (value) => value.length <= jobFieldLengths.maxJobNameCharLength,
            message: `Job name must be less than or equal to ${jobFieldLengths.maxJobNameCharLength} characters.`
          },
        },
        region: {
          required: {
            value: true,
            message: "Region is required",
          },
        },
        jobType: {
          required: {
            value: true,
            message: "Job type is required",
          },
        },
        tasks: {
          custom: {
            isValid: validateTasks,
          },
        },
        description: {
          required: {
            value: false,
          },
          custom: {
            isValid: (value) => value.length <= jobFieldLengths.maxDescriptionCharLength,
            message: `Description must be less than or equal to ${jobFieldLengths.maxDescriptionCharLength} characters.`
          }
        },
        jobApplicability: {
          custom: {
            isValid: () =>
              jobFormData?.jobApplicability &&
              !(jobFormData.applicableSites?.length || jobFormData.siteType)
                ? false
                : true,
            message:
              "Please select either one site type or multiple specific sites",
          },
        },
      },
      initialValues: {
        applicableSites: [],
        tasks: [],
      },
    });

  useEffect(() => {
    if (createdJobSuccessfully) {
      navigate("/jobConfiguration");
      dispatch(setCreatedJobSuccessfully(false));
    }
  }, [createdJobSuccessfully]);

  useEffect(() => {
    if (selectedJobId) {
      dispatch(getJobById(selectedJobId));
    }
  }, [selectedJobId]);

  useEffect(() => {
    dispatch(setJobFormErrors(errors));
  }, [errors]);

  useEffect(() => {
    if (jobFormData) {
      setFormValues(jobFormData);
    }
  }, [jobFormData]);

  useEffect(() => {
    if (jobFormData && jobFormData.region && regions) {
      const region = regions.filter((r) => r.name === jobFormData.region)[0];
      if (region) {
        dispatch(getRegionTasks(region.id));
        dispatch(getSpecificSites(region.id));
        dispatch(setRestrictionFormName(region?.restrictionFormName));
      }
    }
  }, [jobFormData?.region, regions]);

  useEffect(() => {
    if (token) {
      dispatch(getRegions());
      dispatch(getBuildingTypes());
      dispatch(getJobTypes());
    }

    return () => {
      // clear out data when navigating away from form
      dispatch(setJobFormData(initialFormData));
      dispatch(setJobFormErrors({}));
      dispatch(setRequirementFormErrors({}));
      dispatch(setSelectedJobId(undefined));
      dispatch(setTaskNames([]));
      resetForm();
    };
  }, [token]);

  /**
   * Create a new job
   * */
  const createJob = () => {
    if (jobFormData) {
      dispatch(setIsLoading(true));
      dispatch(addOrEditJob(jobFormData));
    }
  };

  /**
   * ensure for data is valid
   */
  const validateData = () => {
    if (jobFormData) {
      if (validateForm() && !doTasksHaveErrors()) {
        return true;
      }
    }
  };

  return (
    <>
      <Box width="50%" className="create-new-job">
        <BreadCrumbs
          breadcrumbList={[
            { label: "Return To Work 2.0", url: "/" },
            { label: "Job Configuration Portal", url: "/jobConfiguration" },
            {
              label: selectedJobId ? "Edit Job" : "Create New",
              url: "/createNewJob",
            },
          ]}
        />

        <Text type="h500" className="create-new-job__heading">
          {selectedJobId ? "Edit Job" : "Create New Job"}
        </Text>

        <Box
          spacingInset="100"
          className="create-new-job__content"
          overflowY="scroll"
        >
          <BasicJobInfoFields />
          <JobSiteInfoFields />
          <CustomizeTaskFields />
        </Box>

        <Row alignmentHorizontal="right">
          <Button
            type="secondary"
            onClick={() => setIsCancelModalOpen(true)}
            className="create-new-job__cancel"
          >
            Cancel
          </Button>
          <Button
            onClick={() => (validateData() ? setIsCreateModalOpen(true) : "")}
            disabled={isDisabled}
            className="create-new-job__save"
          >
            {selectedJobId ? "Save Edits" : "Create New Job"}
          </Button>
        </Row>
      </Box>

      <ConfirmModal
        modalTitle="Confirm cancellation?"
        modalDescription="You will lose all data in the form and be returned to the previous page"
        open={isCancelModalOpen}
        setOpen={setIsCancelModalOpen}
        onConfirm={() => navigate("/jobConfiguration")}
      />
      <ConfirmModal
        modalTitle={selectedJobId ? "Save edits?" : "Create new job?"}
        modalDescription={
          selectedJobId
            ? "This will update the selected job"
            : "This will create a new job"
        }
        open={isCreateModalOpen}
        setOpen={setIsCreateModalOpen}
        onConfirm={() => createJob()}
      />
    </>
  );
};

export default createNewJob;
