import { TOAST_TIMEOUT } from "src/config/Toast";
import store from "src/store";
import { createToast } from "src/store/toastsSlice";
import apiClient from "../../utils/apiClient";
import {
  JmrPayload,
  AccommodationPayload,
  UpdateAccommodationPayload,
  DownloadTWPAPayload,
  DownloadOomdPayload,
  UpdateAssociateInfoPayload,
} from "./types";

class JmrService {
  /**
   * generate job matches
   * @returns {Object}
   */
  async generateJobMatches(payload: JmrPayload) {
    const data = await apiClient.post(`/jobMatchingReports`, payload);
    return data;
  }

  /**
   * create accommodation
   * @returns {Object}
   */
  async createAccommodation(payload: AccommodationPayload) {
    const res = await apiClient.post(`/jobMatchingReports/save`, payload);
    if (res) {
      store.dispatch(
        createToast({
          type: "success",
          message: "Created accommodation successfully",
          timeout: TOAST_TIMEOUT,
        })
      );
    }
    return res;
  }

  /**
   * update restrictions
   * @returns {Object}
   */
  async updateAccommodationRestrictions(payload: UpdateAccommodationPayload) {
    const res = await apiClient.put(`/jobMatchingReports/updateNewRestriction`, payload);
    if (res) {
      store.dispatch(
        createToast({
          type: "success",
          message: "Created accommodation with updated restrictions successfully",
          timeout: TOAST_TIMEOUT,
        })
      );
    }
    return res;
  }

  /**
   * recommend alternative accommodations
   * @returns {Object}
   */
  async recommendAlternativePlacement(payload: UpdateAccommodationPayload) {
    const res = await apiClient.put(`/jobMatchingReports/updatePlacement`, payload);
    if (res) {
      store.dispatch(
        createToast({
          type: "success",
          message: "Placement job selection(s) updated successfully",
          timeout: TOAST_TIMEOUT,
        })
      );
    }
    return res;
  }

  /**
   * complete Transfer Placement
   * @returns {Object}
   */
  async completeTransferPlacement(payload: UpdateAccommodationPayload) {
    const res = await apiClient.put(`/jobMatchingReports/completeTransferPlacement`, payload);
    if (res) {
      store.dispatch(
        createToast({
          type: "success",
          message: "Placement has been transferred successfully",
          timeout: TOAST_TIMEOUT,
        })
      );
    }
    return res;
  }

  async updateRestrictionDates(payload: UpdateAccommodationPayload) {
    const res = await apiClient.put(`/jobMatchingReports/updateRestrictionDates`, payload);
    if (res) {
      store.dispatch(
        createToast({
          type: "success",
          message: "Placement restrictions dates updated successfully",
          timeout: TOAST_TIMEOUT,
        })
      );
    }
    return res;
  }

  async providerPlacedOffWork(payload: UpdateAccommodationPayload) {
    const res = await apiClient.post(`/jobMatchingReports/providerPlacedOffWork`, payload);
    if (res) {
      store.dispatch(
        createToast({
          type: "success",
          message: "Provider Placed Off Work Successful",
          timeout: TOAST_TIMEOUT,
        })
      );
    }
    return res;
  }

  async updateAssociateInfo(payload: UpdateAssociateInfoPayload) {
    const res = await apiClient.put(`/jobMatchingReports/associateInfo`, payload);

    if (res) {
      store.dispatch(
        createToast({
          type: "success",
          message: "Associate information updated successfully",
          timeout: TOAST_TIMEOUT,
        })
      );
    }
    return res;
  }

  /**
   * get accommodation detail
   * @returns {Object}
   */
  async getAccommodationDetail(accommodationId: number) {
    const res = await apiClient.get(`/accommodation/detail/${accommodationId}`);
    return res;
  }

  /**
   * get accommodation detail base on austin case and user login
   * @returns {Object}
   */
  async getAccommodationIdByCaseIdAndLogin(type: string, caseId: string, login: string) {
    const res = await apiClient.get(`/accommodation/type/${type}/caseId/${caseId}/login/${login}`);
    return res;
  }

  /**
   * get accommodation
   * @returns {Object}
   */
  async getAccommodation(accommodationId: number) {
    const res = await apiClient.get(`/accommodation/${accommodationId}`);
    return res;
  }

  /**
   * download TWPA
   * @returns {Object}
   */
  async downloadTWPA(payload: DownloadTWPAPayload) {
    const res = await apiClient.post(`/accommodation/downloadTWPA`, payload, {
      responseType: "blob",
    });
    if (res) {
      store.dispatch(
        createToast({
          type: "success",
          message: "Downloaded TWPA successfully",
          timeout: TOAST_TIMEOUT,
        })
      );
    }
    return res;
  }

  /**
   * download OOMD
   * @returns {Object}
   */
  async downloadOOMD(payload: DownloadOomdPayload) {
    const res = await apiClient.post(`/accommodation/downloadOOMD`, payload, {
      responseType: "blob",
    });
    if (res) {
      store.dispatch(
        createToast({
          type: "success",
          message: "Downloaded OOMD successfully",
          timeout: TOAST_TIMEOUT,
        })
      );
    }
    return res;
  }

  /**
   * download JMR
   * @returns {Object}
   */
  async downloadJMR(key: string) {
    const res = await apiClient.get(`/accommodation/downloadJMR/${key}`, {
      responseType: "blob",
    });
    if (res) {
      store.dispatch(
        createToast({
          type: "success",
          message: "Downloaded JMR successfully",
          timeout: TOAST_TIMEOUT,
        })
      );
    }
    return res;
  }

  /**
   * delete accommodation
   * @returns {Object}
   */
  async deleteAccommodation(accommodationId: number) {
    const res = await apiClient.delete(`/accommodation/${accommodationId}`);
    if (res) {
      store.dispatch(
        createToast({
          type: "success",
          message: "Deleted accommodation successfully",
          timeout: TOAST_TIMEOUT,
        })
      );
    }
    return res;
  }

  async getUpdateRestrictionAllowed(accommodationId: number) {
    const res = await apiClient.get(`/accommodation/${accommodationId}/updateRestrictionAllowed`);
    return res;
  }

  async getBccEmailById(placementId: number, emailType: string) {
    const res = await apiClient.get(`accommodation/${placementId}/emailBccRecipients/emailType/${emailType}`);
    return res;
  }
}

export default new JmrService();
