import Column from "@amzn/meridian/column";
import DatePicker from "@amzn/meridian/date-picker";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import React, { useEffect, useState } from "react";
import {
  SelectedEmailTypeEnum,
  SendEmailFormData,
} from "src/store/placementDetailsPageSlice/types";
import { useAppSelector } from "src/store/store";

interface DateFieldsData {
  label: string;
  formDataKey: keyof SendEmailFormData;
}
interface dateFieldsProps {
  handleChange: (key: keyof SendEmailFormData, value: any) => void;
}
const dateFields = ({ handleChange }: dateFieldsProps) => {
  const { sendEmailFormData, sendEmailFormErrors } = useAppSelector(
    (state) => state.placementDetailsPage
  );
  const [dateFieldsData, setDateFieldsData] = useState<DateFieldsData[]>([]);

  useEffect(() => {
    if (sendEmailFormData.emailType === SelectedEmailTypeEnum.DLSReferral) {
      setDateFieldsData([
        {
          label: "Last Day Worked",
          formDataKey: "lastDayWorked",
        },
        {
          label: "First Day Absent",
          formDataKey: "firstDayAbsent",
        },
        {
          label: "Date of Permanent Restrictions (if applicable)",
          formDataKey: "dateOfPermanentRestrictions",
        },
      ]);
    } else if (
      sendEmailFormData.emailType === SelectedEmailTypeEnum.ReturnedtoFullDuty
    ) {
      setDateFieldsData([
        {
          label: "First day of full duty work",
          formDataKey: "firstDayReturn",
        },
      ]);
    } else {
      setDateFieldsData([]);
    }
  }, [sendEmailFormData.emailType]);

  return (
    <div>
      {dateFieldsData.length > 0 &&
        dateFieldsData.map((row, index) => (
          <Row widths={["fill"]} spacingInset={"200 none"} key={index}>
            <Column spacing="200">
              <Text tag="label" type="b200" color="primary">
                {row.label}
              </Text>
              <DatePicker
                placeholder="MM/DD/YYY"
                value={sendEmailFormData[row.formDataKey] as string}
                onChange={(value) => handleChange(row.formDataKey, value)}
                error={sendEmailFormErrors[row.formDataKey]?.error}
                errorMessage={
                  sendEmailFormErrors[row.formDataKey]?.errorMessage
                }
                data-cy={`send-email__dateFields--${index}`}
              />
            </Column>
          </Row>
        ))}
    </div>
  );
};

export default dateFields;
