import React from "react";
import { useAppSelector } from "src/store/store";
import BaliOrDaliButton from "./baliOrDaliButton";

const dlsUpdateOption = () => {
  const { backgroundFormData } = useAppSelector((state) => state.newJmrPage);

  return (
    <BaliOrDaliButton
      caseSource={backgroundFormData.caseSource}
    ></BaliOrDaliButton>
  );
};

export default dlsUpdateOption;
