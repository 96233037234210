import React from "react";
import Button from "@amzn/meridian/button";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setIsCancelModalOpen,
  setPageProgress,
} from "src/store/newJmrPageSlice";
import Box from "@amzn/meridian/box";
import BackgroundReview from "./backgroundReview/backgroundReview";
import RestrictionsReview from "./restrictionsReview/restrictionsReview";
import PlacementSelectionsReview from "./placementSelectionsReview/placementSelectionsReview";
import { useAppSelector } from "src/store/store";

const reviewPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isRestrictionsModalOpen } = useAppSelector(
    (state) => state.newJmrPage
  );

  return (
    <Box width={"80%"}>
      <Text type="d100" className="new-jmr__title" data-cy="new-jmr__title">
        Review Job Match
      </Text>

      <BackgroundReview />
      <RestrictionsReview />
      <PlacementSelectionsReview />

      <Row widths={["fill", "70px", "90px", "194px"]}>
        <div></div>
        <Button
          type="link"
          onClick={() => dispatch(setIsCancelModalOpen(true))}
        >
          Cancel
        </Button>
        <Button type="tertiary" onClick={() => dispatch(setPageProgress(2))}>
          Previous
        </Button>
        <Button
          data-cy="new-jmr__review__next"
          onClick={() => dispatch(setPageProgress(4))}
        >
          Review and Send
        </Button>
      </Row>
    </Box>
  );
};

export default reviewPage;
