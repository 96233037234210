import React from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { useAppSelector } from "src/store/store";
import { useDispatch } from "react-redux";
import { changeTasksOrder, Task } from "src/store/regionTasksPageSlice";
import Row from "@amzn/meridian/row";
import Icon from "@amzn/meridian/icon";
import Text from "@amzn/meridian/text";
import menuTokens from "@amzn/meridian-tokens/base/icon/menu";

const ROW_LAYOUT = ["grid-1", "grid-1", "grid-3", "grid-2", "grid-2", "grid-3"];

const FormTasksTable = () => {
  const { selectedRegionTasks } = useAppSelector(
    (state) => state.regionTasksConfigPage
  );
  const dispatch = useDispatch();
  const SortableItem = SortableElement(
    (value: { task: Task; index: number }) => (
      <Row widths={ROW_LAYOUT} width="100%">
        <Text>
          <Icon tokens={menuTokens} />
        </Text>
        <Text>{value.task.rank == null ? "Not Ranked" : value.task.rank}</Text>
        <Text>{value.task.label}</Text>
        <Text>{value.task.identifyBodyLocation ? "Yes" : "No"}</Text>
        <Text>{value.task.measureUnit || ""}</Text>
        <Text>{value.task.description || ""}</Text>
      </Row>
    )
  );

  const SortableList = SortableContainer(() => (
    <div>
      {selectedRegionTasks.map((selectedRegionTask, index) => (
        <SortableItem
          key={`item-${selectedRegionTask.taskId}`}
          index={index}
          task={selectedRegionTask}
        />
      ))}
    </div>
  ));
  return (
    <div>
      <Row widths={ROW_LAYOUT} width="100%">
        <Text>Move</Text>
        <Text>Rank</Text>
        <Text>Task Name</Text>
        <Text>Specify Body Location?</Text>
        <Text>Measure Unit</Text>
        <Text>Description</Text>
      </Row>
      <SortableList
        onSortEnd={({ oldIndex, newIndex }) => {
          dispatch(changeTasksOrder({ old: oldIndex, new: newIndex }));
        }}
      />
    </div>
  );
};

export default FormTasksTable;
