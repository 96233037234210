import React from "react";
import Button from "@amzn/meridian/button";

interface baliOrDaliButtonProp {
  caseSource: string;
}

const baliOrDaliButton = ({ caseSource }: baliOrDaliButtonProp) => {
  return (
    <Button
      type={"secondary"}
      disabled={true}
      data-cy={`new-jmr__background__caseSource-${caseSource}-btn`}
    >
      {caseSource}
    </Button>
  );
};

export default baliOrDaliButton;
