import React, { useState, useCallback, useEffect } from "react";
import Table, { TableRow, TableCell } from "@amzn/meridian/table";
import { TableSortDirection } from "@amzn/meridian/table/table";
import Box from "@amzn/meridian/box";
import editTokens from "@amzn/meridian-tokens/base/icon/edit";
import { useAppSelector } from "src/store/store";
import Icon from "@amzn/meridian/icon";
import { useDispatch } from "react-redux";
import UncontrolledExpandableRow from "src/components/shared/uncontrolledExpandableRow/uncontrolledExpandableRow";
import {
  getRegions,
  setCurrentPage,
  setSearchField,
  setTotalPages,
  setSelectedRegion,
  FormDataRow,
} from "src/store/regionTasksPageSlice";
import { setSelectedTab } from "src/store/adminConfigPageSlice";
import { getUser } from "src/store/userSlice";
import { filterSortAndPaginateTableData } from "src/utils/tableUtils";

const restrictionFormTable = () => {
  const dispatch = useDispatch();
  const [sortColumn, setSortColumn] = useState("label");
  const [sortDirection, setSortDirection] =
    useState<TableSortDirection>("ascending");
  const onSort = useCallback(({ sortColumn, sortDirection }) => {
    setSortDirection(sortDirection);
    setSortColumn(sortColumn);
  }, []);
  const { tableData, searchField, currentPage, perPage } = useAppSelector(
    (state) => state.regionTasksConfigPage
  );
  const { userLoading } = useAppSelector((state) => state.user);
  const [sortedTableData, setSortedTableData] = useState(tableData);

  useEffect(() => {
    // filter, sorting, and pagination
    const [paginatedData, totalPages] = filterSortAndPaginateTableData(
      tableData,
      searchField,
      sortColumn,
      sortDirection,
      currentPage,
      ["id"],
      perPage
    );
    setSortedTableData(paginatedData as FormDataRow[]);
    dispatch(setTotalPages(totalPages));
  }, [sortColumn, sortDirection, tableData, searchField, currentPage, perPage]);

  useEffect(() => {
    // go back to first page when filter/sort
    dispatch(setCurrentPage(1));
  }, [searchField, sortColumn, sortDirection]);

  useEffect(() => {
    if (userLoading === "fulfilled") {
      dispatch(getRegions());
    }
    return () => {
      dispatch(setSearchField(""));
    };
  }, [userLoading]);

  return (
    <Box
      overflowY="scroll"
      overflowX="scroll"
      type="outline"
      className="view-and-edit-tasks__table"
    >
      <Table
        headerRows={1}
        sortColumn={sortColumn}
        sortDirection={sortDirection}
        onSort={onSort}
        showDividers={true}
        fixHeaderRows={true}
        rowComponents={[UncontrolledExpandableRow, TableRow]}
      >
        <TableRow>
          <TableCell sortColumn="name">State/Region/Province</TableCell>
          <TableCell sortColumn="country">Applicable Country</TableCell>
          <TableCell sortColumn="formName">Form Name</TableCell>
          <TableCell sortColumn="isActive">Is Active?</TableCell>
          <TableCell sortColumn="description">Description</TableCell>
          <TableCell>Edit</TableCell>
        </TableRow>
        {sortedTableData.map((row) => (
          <UncontrolledExpandableRow key={row.id}>
            <TableCell>{row.name}</TableCell>
            <TableCell>{row.country}</TableCell>
            <TableCell>{row.formName}</TableCell>
            <TableCell>{row.isActive ? "Yes" : "No"}</TableCell>
            <TableCell>{row.description}</TableCell>
            <TableCell>
              <div
                className="view-and-edit-tasks__edit-icon"
                onClick={() => {
                  dispatch(setSelectedRegion(row));
                  dispatch(setSelectedTab("form-tasks-config"));
                }}
              >
                <Icon tokens={editTokens}></Icon>
              </div>
            </TableCell>
          </UncontrolledExpandableRow>
        ))}
      </Table>
    </Box>
  );
};

export default restrictionFormTable;
