import Button from "@amzn/meridian/button";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setPageProgress } from "src/store/newJmrPageSlice";
import { useAppSelector } from "src/store/store";
import ConfirmModal from "src/components/shared/confirmModal/confirmModal";

const backgroundReview = () => {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { backgroundFormData, jmrResults } = useAppSelector(
    (state) => state.newJmrPage
  );
  const goToThirdPage = () => {
    dispatch(setPageProgress(2));
  };

  return (
    <Column spacingInset={"400 none 600 none"}>
      <Row widths={["215px", "fill", "61px"]} alignmentVertical="center">
        <Text type="h300">Placement Selection(s)</Text>
        <div />
        <Button type="secondary" onClick={() => setIsModalOpen(true)}>
          Edit
        </Button>
      </Row>
      {jmrResults.map(
        (jmrResult) =>
          jmrResult.isChecked && (
            <Column spacing={"200"}>
              <Column spacing={"none"}>
                <Text type="b300">
                  {jmrResult.name} | {jmrResult.jobType}
                </Text>
                {jmrResult.allocatedTasks.map((task) => (
                  <Text type="b300" color="secondary">
                    {task.taskName}: {task.assignedValue} hours
                  </Text>
                ))}
                <Text type="b300" color="secondary">
                  Job Hours Assigned: {jmrResult.totalHours}
                </Text>
              </Column>
            </Column>
          )
      )}
      <Column spacing={"200"}>
        <Column spacing={"none"}>
          <Text type="b300">Total Shift Hours</Text>
          <Text type="b300" color="secondary">
            {backgroundFormData.cannotWorkNormalShiftHrs
              ? backgroundFormData.limitedHoursPerDay
              : backgroundFormData.shiftLength}
          </Text>
        </Column>
      </Column>

      <ConfirmModal
        modalTitle="Leave page"
        modalDescription="Leaving this page will restart your Job Match Results process."
        open={isModalOpen}
        setOpen={() => setIsModalOpen(false)}
        onConfirm={() => goToThirdPage()}
        width="419px"
      />
    </Column>
  );
};

export default backgroundReview;
