import { DownloadTWPAPayload } from "src/services/jmr/types";

// enums
export enum TemporaryWorkPlacementEnum {
  MATCH_ROLE_NO_MODIFICATIONS,
  MATCH_ROLE_WITH_MODIFICATIONS,
  MATCH_JOB,
  NO_MATCH,
}

export enum DownloadTWPAPayloadDecisionEnum {
  AGREE,
  NOT_AGREE,
}

export enum SelectedEmailTypeEnum {
  AssociateRejectedPlacement = "Associate Declined Placement",
  ApprovedOnsitePlacement = "Approved Onsite Placement",
  NoOnsitePlacementLOA = "Associate LOA",
  ReturnedtoFullDuty = "Associate Returned to Full Duty",
  DLSReferral = "DLS Referral",
}

export enum ReferralAndClaimsStatusEnum {
  HasReachedLimit,
  HasPermanentRestrictions,
  HasHadWorkRelatedClaimDenied,
}

export enum AgreeWithHealthCareRecommendationsEnum {
  DoesNotAgree,
  IsUncertain,
}

// interfaces
export interface AccommodationStatus {
  status: string;
  id: number;
}

export interface AccommodationStatusWithDecisionByLoginNeeded extends AccommodationStatus {
  decisionByLoginNeeded: boolean;
}

export interface SelectedPlacementFormData {
  status: string;
  decisionByLoginNeeded: boolean;
  id: number;
  placementId: number;
  approver: string;
}

export interface DownloadTWPAForm extends Omit<DownloadTWPAPayload, "associateDecision" | "workPlacementProvide"> {
  placementShifts: string;
  workPlacementProvide: TemporaryWorkPlacementEnum;
  associateDecision: DownloadTWPAPayloadDecisionEnum;
}

export interface PlacementDetailsRevision {
  austinUploadFailed: boolean;
  id: number;
  jmrFileName: string;
  statusId: number;
  revisedBy: string;
  revisionDate: number;
  status: {
    id: number;
    status: string;
  };
  endAt: string;
  previousFc: string | null;
  decisionNotes: string | null;
}

export interface PlacementDetailsWorkRestriction {
  taskId: number;
  taskName: string;
  restrictedValue: string;
  measureUnit: string;
  yesNoTypeTask: boolean;
  hoursTypeTask: boolean;
  bodyLocation?: string | null;
}

export interface PlacementDetailsAllocatedJobShift {
  accommodationId: number;
  allocatedHours: number;
  jobId: number;
  jobName: string;
  shiftId: number;
  shiftCode: string;
  jobType: string;
}

export interface PlacementDetails {
  accommodationStatus: {
    id: number;
    status: string;
  };
  accommodationStatusId: number;
  accommodationType: string;
  allocatedJobShifts: PlacementDetailsAllocatedJobShift[];
  associateFullName: string;
  decisionAt: string;
  employeeId: number;
  personId: string;
  endAtDate: string;
  externalCaseId: string;
  fc: string;
  job: string;
  id: number;
  login: string;
  managerAlias: string;
  managerFullName: string;
  modifiedShiftLength: number;
  newestJmrFileName: string;
  normalShiftLength: number;
  requesterDepartment: string;
  requesterLogin: string;
  restrictedDays: string;
  shiftCode: string;
  startAtDate: string;
  transferredAtDate: string;
  status: string;
  decisionByLogin: string;
  timeInPending: number;
  timeInTransferPending: number;
  revisions: PlacementDetailsRevision[];
  workRestrictions: PlacementDetailsWorkRestriction[];
  placementTime: number;
  totalAccommodatedDaysByAssociate: number;
  countryId: number;
  siteId: number;
  caseIdValid: boolean;
  jobsDisplayed: boolean;
  jobSuffix: string;
}

export interface SendEmailFormData {
  emailType: SelectedEmailTypeEnum;
  emailRecipients: string;
  placementDetails: string;
  lastDayWorked: string;
  firstDayAbsent: string;
  dateOfPermanentRestrictions: string;
  agreeWithHealthCareRecommendations: AgreeWithHealthCareRecommendationsEnum;
  referralAndClaimsStatus: ReferralAndClaimsStatusEnum;
  actionsForHr: string;
  areaManagerActionsNeeded: string;
  firstDayReturn: string;
}

export interface UpdateEndDateForm {
  endDate: string;
}

export interface OomdForm {
  injury: string;
  dateOfInjury: string;
  dateOfInjuryReportedToAmcare: string;
  additionalNotes: string;
}

export interface EditAssociateInfoModalFormData {
  login: string;
  job: string;
  employeeId: string;
  homeShift: string;
  caseId: string;
  caseSource: string;
  fullName: string;
}

export interface getPlacementIdByCaseIdAndLoginPayload {
  type: string;
  caseId: string;
  login: string;
}
