import { FilterOptions } from "src/services/placementOverview/types/filterOptions";
import { FilterTag } from "src/services/placementOverview/types/filterTag";

class FilterTagsAndOptionsService {
  createFilterTags(appliedFilterOptions: FilterOptions): FilterTag[] {
    let tags: FilterTag[] = [];
    const jobTags = this.createJobTags(appliedFilterOptions);
    const siteTags = this.createSiteTags(appliedFilterOptions);
    const placementTypeTags = this.createPlacementTypeTags(appliedFilterOptions);
    const jobTypeTags = this.createJobTypeTags(appliedFilterOptions);
    const daysInCategoryTags = this.createDaysInCategoryTags(appliedFilterOptions);
    const shiftCodeTags = this.createShiftCodeTags(appliedFilterOptions);
    const dateTags = this.createDateTags(appliedFilterOptions);

    tags = tags
      .concat(jobTags)
      .concat(siteTags)
      .concat(placementTypeTags)
      .concat(jobTypeTags)
      .concat(daysInCategoryTags)
      .concat(shiftCodeTags)
      .concat(dateTags);
    return tags;
  }

  removeFilterTag(filterTags: FilterTag[], filterTagToRemove: FilterTag): FilterTag[] {
    return filterTags.filter(
      (filterTag) =>
        filterTag.value !== filterTagToRemove.value || filterTag.optionName !== filterTagToRemove.optionName
    );
  }

  getUpdatedAppliedFilterOptionsOnTagRemoval(
    appliedFilterOptions: FilterOptions,
    optionName: keyof FilterOptions,
    valueToBeRemoved: string | number
  ): FilterOptions {
    let updatedFilterOptions = { ...appliedFilterOptions };
    updatedFilterOptions = this.getUpdatedFilterOptionsWhenToBeClearedIsTypeString(updatedFilterOptions, optionName);
    updatedFilterOptions = this.getUpdatedFilterOptionsWhenToBeClearedIsTypeArray(
      updatedFilterOptions,
      optionName,
      valueToBeRemoved
    );
    return updatedFilterOptions;
  }

  private getUpdatedFilterOptionsWhenToBeClearedIsTypeString(
    filterOptions: FilterOptions,
    optionName: keyof FilterOptions
  ) {
    if (typeof filterOptions[optionName] === "string") {
      return {
        ...filterOptions,
        [optionName]: "",
      };
    }
    return filterOptions;
  }

  private getUpdatedFilterOptionsWhenToBeClearedIsTypeArray(
    filterOptions: FilterOptions,
    optionName: keyof FilterOptions,
    valueToBeRemoved: string | number
  ) {
    if (Array.isArray(filterOptions[optionName])) {
      const filteredArray = (filterOptions[optionName] as (string | number)[]).filter(
        (tagValue: string | number) => tagValue !== valueToBeRemoved
      );
      return {
        ...filterOptions,
        [optionName]: filteredArray,
      };
    }
    return filterOptions;
  }

  private createDateTags(appliedFilterOptions: FilterOptions): FilterTag[] {
    const dateTags = [];
    if (appliedFilterOptions.placementStartDate !== "") {
      dateTags.push({
        prefix: "Placement Start Date: ",
        value: appliedFilterOptions.placementStartDate,
        optionName: "placementStartDate",
      });
    }

    if (appliedFilterOptions.placementEndDate !== "") {
      dateTags.push({
        prefix: "Placement End Date: ",
        value: appliedFilterOptions.placementEndDate,
        optionName: "placementEndDate",
      });
    }
    return dateTags;
  }

  private createShiftCodeTags(appliedFilterOptions: FilterOptions): FilterTag[] {
    return appliedFilterOptions.shiftCodes.map((shiftCode: string) => {
      return {
        prefix: "Shift Code: ",
        value: shiftCode,
        optionName: "shiftCodes",
      };
    });
  }

  private createDaysInCategoryTags(appliedFilterOptions: FilterOptions): FilterTag[] {
    return appliedFilterOptions.daysInCategory.map((days: number) => {
      return {
        prefix: "Dwell Time / Time Expired / Days in Status / Closed Time: ",
        value: days,
        optionName: "daysInCategory",
      };
    });
  }

  private createJobTypeTags(appliedFilterOptions: FilterOptions): FilterTag[] {
    return appliedFilterOptions.jobTypes.map((jobType: string) => {
      return {
        prefix: "Job Type: ",
        value: jobType,
        optionName: "jobTypes",
      };
    });
  }

  private createPlacementTypeTags(appliedFilterOptions: FilterOptions): FilterTag[] {
    return appliedFilterOptions.placementTypes.map((placementType: string) => {
      return {
        prefix: "Placement Type: ",
        value: placementType,
        optionName: "placementTypes",
      };
    });
  }

  private createSiteTags(appliedFilterOptions: FilterOptions): FilterTag[] {
    return appliedFilterOptions.sites.map((site: string) => {
      return {
        prefix: "Site: ",
        value: site,
        optionName: "sites",
      };
    });
  }

  private createJobTags(appliedFilterOptions: FilterOptions): FilterTag[] {
    return appliedFilterOptions.jobs.map((job: string) => {
      return {
        prefix: "Job: ",
        value: job,
        optionName: "jobs",
      };
    });
  }
}

export default new FilterTagsAndOptionsService();
