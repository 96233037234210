import React from "react";
import ProgressTracker, {
  ProgressTrackerStep,
} from "@amzn/meridian/progress-tracker";
import { useAppSelector } from "src/store/store";

function progressTracker() {
  const { pageProgress } = useAppSelector((state) => state.newJmrPage);

  const getProgressTrackerType = (step: number) => {
    return pageProgress === step
      ? "present"
      : pageProgress < step
      ? "future"
      : "past";
  };

  const progressTrackerSteps = [
    { label: "Background", progressTrackerValue: 0 },
    { label: "Restrictions", progressTrackerValue: 1 },
    { label: "Results", progressTrackerValue: 2 },
    { label: "Review", progressTrackerValue: 3 },
    { label: "Send for Approval", progressTrackerValue: 4 },
  ];

  return (
    <ProgressTracker compact={true}>
      {progressTrackerSteps.map((step, index) => (
        <ProgressTrackerStep
          key={index}
          type={getProgressTrackerType(step.progressTrackerValue)}
          label={step.label}
          showLabel={true}
        />
      ))}
    </ProgressTracker>
  );
}

export default progressTracker;
