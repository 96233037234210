import apiClient from "src/utils/apiClient";
import { PlacementData } from "./types/placementData";

class PlacementOverviewService {
  /**
   * Get placements in open status by requester login
   */
  async getOpenPlacementsByRequesterLogin(requesterLogin: string): Promise<{ data: PlacementData[] }> {
    return await apiClient.get(`/accommodation/open/requesterLogin/${requesterLogin}`);
  }

  /**
   * Get placements in open status by site id
   */
  async getOpenPlacementsBySiteId(siteId: number): Promise<{ data: PlacementData[] }> {
    return await apiClient.get(`/accommodation/open/site/${siteId}`);
  }

  async getClosedPlacementsBySiteId(siteId: number): Promise<{ data: PlacementData[] }> {
    return await apiClient.get(`/accommodation/closed/site/${siteId}`);
  }
}

export default new PlacementOverviewService();
