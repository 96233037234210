import React from "react";
import { useDispatch } from "react-redux";
import {
  setIsCreateModalOpen,
  setSearchField,
} from "src/store/updateSiteCapacitySlice";
import Row from "@amzn/meridian/row";
import { useAppSelector } from "src/store/store";
import SearchField from "@amzn/meridian/search-field";

const topOptions = () => {
  const dispatch = useDispatch();
  const { searchField } = useAppSelector(
    (state) => state.updateSiteCapacityPage
  );

  return (
    <Row
      widths={["85%", "15%"]}
      spacingInset="400 none"
      alignmentHorizontal="justify"
    >
      <Row width={"grid-4"}>
        <SearchField
          value={searchField}
          onChange={(newSearchField) =>
            dispatch(setSearchField(newSearchField))
          }
          placeholder="Search for..."
          onSubmit={() => {}}
        />
      </Row>
    </Row>
  );
};

export default topOptions;
