import { createSlice, createAsyncThunk, miniSerializeError, SerializedError } from "@reduxjs/toolkit";
import { LOADING_STATE } from "../types/constants";
import authService from "src/services/authService";

export type UserState = {
  token: string;
  alias: string;
  departmentCode: string;
  lastName: string;
  firstName: string;
  email: string;
  ldap: string[];
  siteLocation: string;
  preferredSite: number;
  userLoading: LOADING_STATE;
  authError: SerializedError | null;
  jobLevel: string;
  jobDescription: string;
  hasPermission: boolean;
  featureFlags: { [key: string]: boolean };
  pageLastLoadTimestamp: number;
  isLoading: boolean;
};

const initialState: UserState = {
  token: "",
  alias: "",
  departmentCode: "",
  lastName: "",
  firstName: "",
  email: "",
  ldap: [],
  siteLocation: "",
  preferredSite: 0,
  userLoading: "pending",
  authError: null,
  jobLevel: "",
  jobDescription: "",
  hasPermission: true,
  featureFlags: {},
  pageLastLoadTimestamp: 0,
  isLoading: false,
};

interface FeatureFlagsPayload {
  "placementTransfer": boolean;
  "dp.integration.p0": boolean;
}

/**
 * get the authenticated user
 * @returns {Object}
 */
export const getUser = createAsyncThunk("user/getUser", async () => {
  return authService.login();
});

export const getFeatureFlags = createAsyncThunk<FeatureFlagsPayload>("user/getFeatureFlags", async () => {
  const response = await authService.getFeatureFlags();
  return response.data;
});

/** User Slice */
const { reducer, actions } = createSlice({
  name: "user",
  initialState,
  reducers: {
    getUserFailure: (state) => {
      state.userLoading = "rejected";
    },
    setPreferredSite: (state, action) => {
      state.preferredSite = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getFeatureFlags.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getFeatureFlags.fulfilled, (state, { payload }: { payload: FeatureFlagsPayload }) => {
      state.featureFlags["PLACEMENT_TRANSFER"] = payload["placementTransfer"];
      state.featureFlags["DP_INTEGRATION_P0"] = payload["dp.integration.p0"];
    });
    builder.addCase(getFeatureFlags.rejected, (state, { error }) => {
      state.isLoading = false;
    });
    builder.addCase(getUser.pending, (state) => {
      state.userLoading = "pending";
    });
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getUser.fulfilled, (state, { payload }) => {
      const { alias, token, email, firstName, lastName, siteLocation, ldap, departmentCode, jobLevel, jobDescription } =
        payload;
      state.token = token;
      state.alias = alias;
      state.email = email;
      state.firstName = firstName;
      state.lastName = lastName;
      state.siteLocation = siteLocation;
      state.ldap = ldap;
      state.userLoading = "fulfilled";
      state.departmentCode = departmentCode;
      state.jobLevel = jobLevel;
      state.jobDescription = jobDescription;
      state.pageLastLoadTimestamp = Date.now();
      if (state.ldap.length === 0) {
        state.hasPermission = false;
      }
      // TODO: add JWT token to api header
    });
    builder.addCase(getUser.rejected, (state, { error }) => {
      state.authError = miniSerializeError(error);
      state.userLoading = "rejected";
      state.hasPermission = false;
    });
  },
});

export const { setPreferredSite } = actions;

export default reducer;
