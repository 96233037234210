import React from "react";
import Row from "@amzn/meridian/row";
import Toggle from "@amzn/meridian/toggle";
import Text from "@amzn/meridian/text";
import { useAppSelector } from "src/store/store";
import { useDispatch } from "react-redux";
import { setAreMultipleSitesSelected } from "src/store/defaultSettingsPageSlice";
import "./multipleSiteToggle.scss";

const multipleSiteToggle = () => {
  const dispatch = useDispatch();
  const { pageDisabled, areMultipleSitesSelected } = useAppSelector(
    (state) => state.defaultSettingsPage
  );

  return (
    <Row
      widths={["grid-4", "grid-2"]}
      className="default-settings__content__toggle"
    >
      <Text>Multiple Site Selection?</Text>
      <Toggle
        size="large"
        disabled={pageDisabled}
        checked={areMultipleSitesSelected}
        onChange={(newState) => dispatch(setAreMultipleSitesSelected(newState))}
      />
    </Row>
  );
};
export default multipleSiteToggle;
