import { DEFAULT_TABLE_PAGE_SIZE } from "src/constants/Pagination";
import { createSearchRegExp } from "./searchUtils";
import { SortStrategy, sortBasedOnStrategy } from "./sortStrategies";

/**
 * sort table rows given sort column and direction
 */
export const sortTableRows = (
  tableRows: any[],
  sortColumn: string,
  sortDirection: string
) => {
  return tableRows.sort((a: any, b: any) => {
    let aValue =
      typeof a[sortColumn] !== "number"
        ? JSON.stringify(a[sortColumn])?.toLowerCase()
        : a[sortColumn];
    let bValue =
      typeof b[sortColumn] !== "number"
        ? JSON.stringify(b[sortColumn])?.toLowerCase()
        : b[sortColumn];

    if (aValue < bValue) {
      return sortDirection === "ascending" ? -1 : 1;
    } else if (aValue > bValue) {
      return sortDirection === "ascending" ? 1 : -1;
    }

    return 0;
  });
};

/**
 * serialize value for filter
 */
const serializeValue = (value: any) => {
  if (typeof value === "boolean") {
    return value === true ? "Yes" : "No";
  }
  return value;
};

/**
 * perform universal filter on all table columns given search field
 */
export const filterTableRows = (
  tableRows: any[],
  searchField: string,
  columnsToSkip = ["id"],
  columnsToConditionallySkip?: [string, ((x: any) => boolean)][]
) => {
  const searchRegExp = createSearchRegExp(searchField);
  return tableRows.filter((row) =>
    Object.entries(row)
      .map(([key, value]) => {
        return (
          !columnsToSkip.includes(key) &&
          !columnsToConditionallySkip?.some(([columnName, skipFunction]) => columnName === key && skipFunction(row))
          &&
          value !== undefined &&
          searchRegExp.test(serializeValue(value))
        );
      })
      .reduce((prevValue: any, currValue: any) => prevValue || currValue)
  );
};

/**
 * paginate table rows given current page
 */
export const paginateTableRows = (
  tableRows: any[],
  currentPage: number,
  pageSize = DEFAULT_TABLE_PAGE_SIZE
) => {
  const totalPages = Math.ceil(tableRows.length / pageSize);
  const startIndex = pageSize * (currentPage - 1);
  return [tableRows.slice(startIndex, startIndex + pageSize), totalPages];
};

/**
 * combine sort and pagination into one function
 */
export const sortAndPaginateTableData = (
  tableRows: any[],
  sortColumn: string,
  sortDirection: string,
  currentPage: number,
  pageSize = DEFAULT_TABLE_PAGE_SIZE,
  sortStrategy = SortStrategy.DEFAULT
) => {
  const sortedData = sortBasedOnStrategy(
    sortStrategy,
    tableRows,
    sortColumn,
    sortDirection
  );
  return paginateTableRows(sortedData, currentPage, pageSize);
};

/**
 * combine filter, sort and pagination into one function
 */
export const filterSortAndPaginateTableData = (
  tableRows: any[],
  searchField: string,
  sortColumn: string,
  sortDirection: string,
  currentPage: number,
  columnsToSkip = ["id"],
  pageSize = DEFAULT_TABLE_PAGE_SIZE,
  sortStrategy = SortStrategy.DEFAULT,
  columnsToConditionallySkip?: [string, ((x: any) => boolean)][]
) => {
  const filteredData = filterTableRows(
    [...tableRows],
    searchField,
    columnsToSkip,
    columnsToConditionallySkip
  );
  const sortedData = sortBasedOnStrategy(
    sortStrategy,
    filteredData,
    sortColumn,
    sortDirection
  );
  return paginateTableRows(sortedData, currentPage, pageSize);
};

/**
 * get longest array out of an array of arrays
 */
export const getLongestArray = (arrays: any[]) => {
  const lengths = arrays.map((arr) => arr.length);
  return arrays[lengths.indexOf(Math.max.apply(null, lengths))];
};
