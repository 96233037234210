import React from "react";
import Pagination from "@amzn/meridian/pagination";
import Row from "@amzn/meridian/row";
import Select, { SelectOption } from "@amzn/meridian/select";
import Text from "@amzn/meridian/text";
import { PAGINATION_OPTIONS } from "src/constants/Pagination";
import "./paginationWithDropdown.scss";

interface paginationWithDropdownProps {
  totalPages: number;
  currentPage: number;
  perPage: number;
  setPerPage: (newPerPage: number) => void;
  setCurrentPage: (newPage: number) => void;
}
const paginationWithDropdown = ({
  totalPages,
  currentPage,
  perPage,
  setPerPage,
  setCurrentPage,
}: paginationWithDropdownProps) => {
  return (
    <Row
      className="pagination"
      spacingInset={"500 none none none"}
      alignmentHorizontal={"center"}
    >
      <Pagination
        numberOfPages={totalPages}
        onChange={(newPage) => setCurrentPage(newPage)}
        currentPage={currentPage}
        showSkipArrows={true}
      />
      <Row
        spacing={"400"}
        widths={["fill", "none"]}
        className="pagination__page-size"
      >
        <Text alignment="right">Page size:</Text>
        <Select
          value={perPage}
          onChange={(perPage) => {
            setPerPage(perPage);
          }}
          id="pagination__select"
        >
          {PAGINATION_OPTIONS.map((option) => (
            <SelectOption
              key={option}
              label={option}
              value={option}
            ></SelectOption>
          ))}
        </Select>
      </Row>
    </Row>
  );
};

export default paginationWithDropdown;
