import React, { useState } from "react";
import Column from "@amzn/meridian/column";
import SelectedSiteList from "../selectedSiteList/selectedSiteList";
import MultipleSiteDropdown from "../multipleSiteDropdown/multipleSiteDropdown";
import "./multipleSiteSelection.scss";
import { useAppSelector } from "src/store/store";

const multipleSiteSelection = () => {
  const { areMultipleSitesSelected } = useAppSelector(
    (state) => state.defaultSettingsPage
  );

  return (
    <Column className="default-settings__content__multiple-selection">
      {areMultipleSitesSelected && (
        <>
          <MultipleSiteDropdown />
          <SelectedSiteList />
        </>
      )}
    </Column>
  );
};

export default multipleSiteSelection;
