import Table, { TableCell, TableRow } from "@amzn/meridian/table";
import React, { useEffect, useState } from "react";
import UncontrolledExpandableRow from "src/components/shared/uncontrolledExpandableRow/uncontrolledExpandableRow";
import { useAppSelector } from "src/store/store";
import {
  setIsEditAssociateInfoModalOpen,
  setAssociateInfoFormData,
  setIsEditAssociateInfoSaving,
} from "src/store/placementDetailsPageSlice/placementDetailsPageSlice";
import { useDispatch } from "react-redux";
import EditAssociateInfoModalAUSTIN from "src/components/pages/placementDetails/subpages/employeeInformation/editAssociateInfoModal/editAssociateInfoModalAUSTIN";
import Icon from "@amzn/meridian/icon";
import editTokens from "@amzn/meridian-tokens/base/icon/edit";
import "./employeeInformation.scss";
import * as LDAP from "src/config/LDAP";
import { constructIncidentLink } from "src/utils/constructRTWLinks";
import { stripOutLeadingHash } from "src/utils/helpers";
import Link from "@amzn/meridian/link";

interface TableItem {
  label: string;
  value: string | number;
  dataCy: string;
}
const EmployeeInformation = () => {
  const [employeeInformationTableData, setEmployeeInformationTableData] = useState<TableItem[]>([]);
  const { ldap } = useAppSelector((state) => state.user);

  const { placementDetails } = useAppSelector((state) => state.placementDetailsPage);
  const { accommodationType, caseIdValid, externalCaseId } = placementDetails;
  const isAdmin = ldap.includes(LDAP.ADMIN);

  const dispatch = useDispatch();

  useEffect(() => {
    if (placementDetails) {
      setEmployeeInformationTableData([
        {
          label: "Login",
          value: placementDetails.login,
          dataCy: "login",
        },

        {
          label: "Employee ID",
          value: placementDetails.employeeId,
          dataCy: "employeeID",
        },
        {
          label: "Job",
          value: placementDetails.job,
          dataCy: "job",
        },
        {
          label: "Home Location",
          value: placementDetails.fc,
          dataCy: "siteLocation",
        },
        {
          label: "Home Shift",
          value: placementDetails.shiftCode,
          dataCy: "shift",
        },
        {
          label: "Case Information",
          value: `${placementDetails.accommodationType} | ${placementDetails.externalCaseId}`,
          dataCy: "caseInformation",
        },
        {
          label: "Requestor",
          value: placementDetails.requesterLogin,
          dataCy: "requestor",
        },
        {
          label: "Decision Maker",
          value: placementDetails.decisionByLogin,
          dataCy: "approver",
        },
      ]);
    }
  }, [placementDetails]);

  const initializeAndOpenEditAssociateModal = () => {
    dispatch(setIsEditAssociateInfoSaving(false));
    dispatch(
      setAssociateInfoFormData({
        login: placementDetails.login,
        employeeId: placementDetails.employeeId,
        job: placementDetails.job,
        homeLocation: placementDetails.fc,
        homeShift: placementDetails.shiftCode,
        caseId: placementDetails.externalCaseId,
        caseSource: placementDetails.accommodationType,
        fullName: placementDetails.associateFullName,
      })
    );
    dispatch(setIsEditAssociateInfoModalOpen(true));
  };

  const renderEmployeeInformationTableValue = (value: string | number, label: string) => {
    if (accommodationType === "AUSTIN" && label === "Case Information" && externalCaseId !== null) {
      const incidentLink = constructIncidentLink(stripOutLeadingHash(externalCaseId));
      return (
        <>
          {value}{" "}
          {caseIdValid && incidentLink && (
            <Link target="_blank" rel="noopener noreferrer" type="secondary" href={incidentLink}>
              {incidentLink}
            </Link>
          )}
        </>
      );
    }
    return value;
  };

  return (
    <>
      <EditAssociateInfoModalAUSTIN />
      <Table
        headerRows={1}
        headerColumns={1}
        rowComponents={[UncontrolledExpandableRow, TableRow]}
        showStripes={true}
        showDividers={true}
      >
        <TableRow>
          <TableCell></TableCell>
          <TableCell>Employee Information</TableCell>
          <TableCell>{isAdmin && <span>Edit</span>}</TableCell>
        </TableRow>
        {employeeInformationTableData.map(({ value, label, dataCy }, index) => (
          <TableRow key={index}>
            <TableCell>{label}</TableCell>
            <TableCell data-cy={`placement-details__home-process__${dataCy}`}>
              {renderEmployeeInformationTableValue(value, label)}
            </TableCell>
            <TableCell>
              {label === "Login" && isAdmin && (
                <span onClick={initializeAndOpenEditAssociateModal} className="employee-info__edit-icon">
                  <Icon tokens={editTokens}></Icon>
                </span>
              )}
            </TableCell>
          </TableRow>
        ))}
      </Table>
    </>
  );
};
export default EmployeeInformation;
