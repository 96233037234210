class CoachmarkService {

    isCoachmarkDismissed(coachmarkName: string) {
        return localStorage.getItem(coachmarkName) === "true";
    }

    dismissCoachmark(coachmarkName: string) {
        localStorage.setItem(coachmarkName, "true");
    }

}
export default new CoachmarkService();