import React from "react";
import Box from "@amzn/meridian/box";
import RadioButton from "@amzn/meridian/radio-button";
import Link from "@amzn/meridian/link";
import exportSmallTokens from "@amzn/meridian-tokens/base/icon/export-small";
import Row from "@amzn/meridian/row";
import Column from "@amzn/meridian/column";
import Text from "@amzn/meridian/text";
import Icon from "@amzn/meridian/icon";
import { NO_RFI } from "./constants";
import { CaseSelectionProps } from "./caseSelectionProps";
import blueLightTokens from "@amzn/meridian-tokens/theme/blue-light";
import Theme from "@amzn/meridian/theme";
import { CustomColorTokens } from "../customColorTokens";
import { constructIncidentLink } from "src/utils/constructRTWLinks";
import { stripOutLeadingHash } from "src/utils/helpers";

const UnselectableCase = ({ austinCase, selectedCaseId }: CaseSelectionProps) => {
  const unselectableTokens = {
    ...blueLightTokens,
    boxBorderColorFill: CustomColorTokens.BRAND_GRAY,
    boxBorderWidth: "1px",
  };
  const { injuryDate, principalBodyPart, caseId, accommodationId, reasons, updatedAt } = austinCase;
  return (
    <Theme tokens={unselectableTokens}>
      <Box type="fill" spacingInset="400">
        <Row widths={["grid-1", "grid-5", "fit", "grid-6"]} alignmentVertical="top">
          <Column>
            <Row>
              <RadioButton checked={caseId === selectedCaseId} value={caseId} disabled={true}></RadioButton>
            </Row>
          </Column>
          <Column spacing="200">
            <Row>
              <Text type="h100">Case ID: {caseId}</Text>
            </Row>
            <Row>
              <Text>{principalBodyPart} Injury</Text>
            </Row>
            {
              <Column spacing="300">
                <Row></Row>
                {reasons.map((reason) =>
                  reason.includes(NO_RFI) ? (
                    <Row>
                      <Column spacing="100">
                        <Text>Unable to select due to missing RFI or WSR in AUSTIN.</Text>
                        <Link
                          type="secondary"
                          target="_blank"
                          rel="noopener noreferrer"
                          href={constructIncidentLink(stripOutLeadingHash(caseId)) + "/medical_attachments"}
                        >
                          Upload RFI or WSR in AUSTIN <Icon tokens={exportSmallTokens} />
                        </Link>
                      </Column>
                    </Row>
                  ) : (
                    <Row>
                      <Text>
                        Unable to select due to AUSTIN Case ID already linked to existing{" "}
                        <Link
                          type="secondary"
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`/placementDetails/${accommodationId}`}
                        >
                          RTW placement <Icon tokens={exportSmallTokens} />
                        </Link>
                        .
                      </Text>
                    </Row>
                  )
                )}
              </Column>
            }
          </Column>

          <Column spacing="200">
            <Row>
              <Text color="secondary" type="b300">
                Last updated: {new Date(updatedAt).toLocaleDateString()}
              </Text>
            </Row>
            {!!injuryDate && (
              <Row>
                <Text color="secondary" type="b300">
                  Date of incident: {new Date(injuryDate).toLocaleDateString()}
                </Text>
              </Row>
            )}
            <Row>
              <Link
                type="secondary"
                target="_blank"
                rel="noopener noreferrer"
                href={constructIncidentLink(stripOutLeadingHash(caseId))}
              >
                View Case Details
              </Link>
            </Row>
          </Column>
        </Row>
      </Box>
    </Theme>
  );
};
export default UnselectableCase;
